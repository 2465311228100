import { map, Observable, Subject } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { LoadingService } from './loading.service';
import { UserWidget } from '../domain/user-widget';
import { WidgetIndexEntry } from '../domain/widget-index-entry';
import { Widget } from '../domain/widget';
import { AppointmentTimeIndex } from '../domain/appointment-time-index';
import { TeamAppointmentTimeIndex } from '@bigdune/itb-domain/src/widget/team-appointment-time-index.model';
import { TeamAppointmentTimeIndexDate } from '@bigdune/itb-domain';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private widgetIndex: WidgetIndexEntry[];
  private widgetIndexSubject = new Subject<WidgetIndexEntry[]>();
  private widget: Widget;
  private widgetSubject = new Subject<Widget>();
  private widgetLocalitySubject = new Subject<boolean>();

  private inspectionType: string;
  private userId: string;
  public userTier = 'Free';
  public timezoneOffset: number = new Date().getTimezoneOffset();

  public useTeamAppointmentIndex: boolean = false;

  constructor(
    private angularFireStore: AngularFirestore,
    private angularFireFunctions: AngularFireFunctions,
    private loadingService: LoadingService,
  ) {}

  public setWidgetIndex(index: WidgetIndexEntry[]): void {
    this.widgetIndex = index;
    this.widgetIndexSubject.next(index);
  }

  public getWidgetIndexSubject(): Subject<WidgetIndexEntry[]> {
    return this.widgetIndexSubject;
  }

  public setInspectionType(inspectionType: string) {
    this.inspectionType = inspectionType;
  }

  public loadWidget(): void {
    if (this.inspectionType !== null && this.inspectionType !== undefined) {
      const possibleWidgets: WidgetIndexEntry[] = this.widgetIndex.filter(
        (widget) => widget.inspectionType === this.inspectionType,
      );

      if (possibleWidgets.length > 0) {
        const widgetEntry: WidgetIndexEntry = possibleWidgets[0];
        this.loadWidgetById(widgetEntry.widgetId);
      }
    }
  }

  public loadWidgetById(widgetId: string): void {
    const userWidgetPath = `widget/${this.userId}`;
    const widgetPath = `${userWidgetPath}/widgets/${widgetId}`;

    this.initializeWidget(widgetPath);
  }

  private initializeUserData() {
    this.angularFireStore
      .doc<UserWidget>(`widget/${this.userId}`)
      .ref.get()
      .then((data) => {
        if (data.exists) {
          const widgetData = data.data();
          if (widgetData) {
            if (widgetData.tier) {
              this.userTier = widgetData.tier;
            }

            if (widgetData.timezoneOffset) {
              this.timezoneOffset = widgetData.timezoneOffset;
            }
          }
        }
      })
      .catch((e) => console.error(e));
  }

  private initializeWidget(widgetPath: string) {
    const widgetObservable = this.angularFireStore
      .doc<Widget>(widgetPath)
      .snapshotChanges()
      .pipe(
        map((widgetDoc) => {
          const data = widgetDoc.payload.data() as Widget;
          const id = widgetDoc.payload.id;
          return { id, ...data };
        }),
      );

    widgetObservable.subscribe((widgetDoc) => {
      this.widget = widgetDoc;
      this.inspectionType = widgetDoc.inspectionType;
      this.widgetSubject.next(widgetDoc);
      this.loadingService.stopLoading();
    });
  }

  public getWidget(): Widget {
    return this.widget;
  }

  public getWidgetSubject(): Subject<Widget> {
    return this.widgetSubject;
  }

  public getInspectionType(): string {
    return this.inspectionType;
  }

  public getWidgetLocalitySubject(): Subject<boolean> {
    return this.widgetLocalitySubject;
  }

  public setWidgetLocality(isLocalWidget: boolean): void {
    this.widgetLocalitySubject.next(isLocalWidget);
  }

  public setUserId(userId: string): void {
    this.userId = userId;

    this.initializeUserData();
    this.googleCalendarSyncCheck();
  }

  public getUserId() {
    return this.userId;
  }

  public getTimeSlotsForMonthByDate(
    date: Date,
  ): Observable<AppointmentTimeIndex[]> {
    const month = (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();

    const appTimeIndexColPath = `widget/${this.userId}/appointment-time-index`;
    const appTimeMonthIndex = this.angularFireStore.collection(
      `${appTimeIndexColPath}/${year}/${month}`,
    );

    return appTimeMonthIndex.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const times: string[] = a.payload.doc.get('times');
          const date = parseInt(a.payload.doc.id);

          const res: AppointmentTimeIndex = { date, times };

          return res;
        });
      }),
    );
  }

  public getTeamTimeSlotsForMonthByDate(
    date: Date,
  ): Observable<AppointmentTimeIndex[]> {
    const month = (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();

    const appTimeIndexColPath = `widget/${this.userId}/team-appointment-time-index`;
    const appTimeMonthIndex =
      this.angularFireStore.doc<TeamAppointmentTimeIndex>(
        `${appTimeIndexColPath}/${year}-${month}`,
      );

    return appTimeMonthIndex.snapshotChanges().pipe(
      map((doc) => {
        if (!doc.payload.exists) {
          return [];
        }

        const dates: TeamAppointmentTimeIndexDate[] | undefined =
          doc.payload.data()?.dates;

        if (dates === undefined) {
          return [];
        }

        const appointmentTimeIndices: AppointmentTimeIndex[] = [];

        for (let i = 0; i < dates.length; i++) {
          const date1 = dates[i];

          let times: string[] = Object.keys(date1);
          let finalTimes: string[] = [];

          for (const time of times) {
            if (
              date1[time].availableInspectors &&
              date1[time].availableInspectors.length > 0
            ) {
              finalTimes.push(time);
            }
          }

          if (finalTimes.length > 0) {
            appointmentTimeIndices.push({ date: i + 1, times: finalTimes });
          }
        }

        return appointmentTimeIndices;
      }),
    );
  }

  public userWidgetDoc(userId = this.userId) {
    return this.angularFireStore.collection('widget').doc(userId);
  }

  private googleCalendarSyncCheck() {
    const calFunction = this.angularFireFunctions.httpsCallable(
      'apiGoogleCalSyncCheck',
    );
    calFunction({ userId: this.userId }).subscribe({
      error: (e) => {
        console.error(e);
      },
    });
  }
}
