import {Component} from '@angular/core';
import {LoadingService} from "./core/services/loading.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'itb-widget';

  isLoading: boolean = true;

  constructor(public loadingService: LoadingService) {
    loadingService.getLoader().subscribe(value => {
      this.isLoading = value;
    });
  }
}
