import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialDateTimeComponent} from './material-date-time/material-date-time.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatRadioModule} from "@angular/material/radio";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    MaterialDateTimeComponent
  ],
  exports: [
    MaterialDateTimeComponent
  ]
})
export class MaterialDateTimeModule {
}
