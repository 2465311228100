<form [formGroup]="formGroup" class="container-fluid calendar-wrapper">
  <div class="posRel">
    <div class="row">

      <div class="col-xs-12 col-sm-6 col-md-8">
        <div class="calendar-control">
          <button (click)="previousRange()" mat-icon-button>
            <mat-icon>chevron_left</mat-icon>
          </button>
          <span class="month-label">{{selectedDate | date:'MMMM'}}</span>
          <button (click)="nextRange()" mat-icon-button>
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>

        <!-- Day Calendar -->
        <table class="calendar-table mat-calendar-table" *ngIf="currentView === 'DAY'">
          <thead class="mat-calendar-table-header">
          <tr>
            <th *ngFor="let day of daysOfTheWeek" class="text-dark">{{day}}</th>
          </tr>
          </thead>
          <tbody class="mat-calendar-body" *ngIf="month">
          <tr *ngFor="let week of month">
            <td class="mat-calendar-body-cell" *ngFor="let day of week" (click)="selectDay(day)"
                [class.mat-calendar-body-disabled]="!isActiveDate(day)"
                [class.mat-calendar-body-available]="isActiveDate(day)"
                [class.mat-calendar-body-active]="isChosenDay(day)">
              <div class="mat-calendar-body-cell-content" *ngIf="day !== 0">{{day}}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 time-calendar">
        <!-- Time Calendar -->
        <div class="mat-calendar-table-header">
          <p *ngIf="availableTimes" colspan="7">Available Times for
            <strong>{{ (displayDate | date:'MMMM') + '&nbsp;' + (displayDate | date:'d') }}</strong>
            {{ this.timezoneLabel }}</p>
        </div>

        <div class="field-group pl-3" *ngIf="appointmentDate != null">
          <div *ngIf="availableTimes.length == 0">
            <p>There are no appointments available {{ (isToday) ? 'today' : 'on this day' }}</p>
            <p>Please select another day</p>
          </div>

          <mat-radio-group class="example-radio-group" (change)="timeChange($event)" [required]="true"
                           formControlName="appointmentTime">
            <mat-radio-button class="example-radio-button" *ngFor="let time of availableTimes" [value]="time">
              {{ time | date: 'shortTime'}}
            </mat-radio-button>
          </mat-radio-group>

          <mat-error *ngIf="fieldIsRequired('appointmentTime') &&  availableTimes && availableTimes.length > 0">
            Please select a time slot
          </mat-error>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-md-8 py-2">
        <button mat-button color="primary" (click)="cancelModal()">
          Cancel
        </button>
      </div>
      <div class="col-6 col-md-4 py-2">
        <button class="col-12" mat-raised-button color="primary" [disabled]="fieldIsRequired('appointmentTime', false)"
                (click)="closeModal()">
          Done
        </button>
      </div>
    </div>
    <mat-spinner *ngIf="monthLoader" diameter='80'></mat-spinner>
  </div>
</form>
