import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private isLoading: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  public stopLoading(): void {
    this.isLoading.next(false);
  }

  public startLoading(): void {
    this.isLoading.next(true);
  }

  public getLoader(): Subject<boolean> {
    return this.isLoading;
  }
}
