const codes = {
  T0A: {
    zip: 'T0A',
    latitude: '53.9225',
    longitude: '-111.0585',
    city: 'Eastern Alberta',
    state: 'AB',
    country: 'Canada',
  },
  T0B: {
    zip: 'T0B',
    latitude: '53.0635',
    longitude: '-112.3067',
    city: 'Wainwright Region',
    state: 'AB',
    country: 'Canada',
  },
  T0C: {
    zip: 'T0C',
    latitude: '51.9565',
    longitude: '-110.0761',
    city: 'Central Alberta',
    state: 'AB',
    country: 'Canada',
  },
  T0E: {
    zip: 'T0E',
    latitude: '53.8486',
    longitude: '-114.4361',
    city: 'Western Alberta',
    state: 'AB',
    country: 'Canada',
  },
  T0G: {
    zip: 'T0G',
    latitude: '54.2653',
    longitude: '-115.3827',
    city: 'North Central Alberta',
    state: 'AB',
    country: 'Canada',
  },
  T0H: {
    zip: 'T0H',
    latitude: '56.6598',
    longitude: '-117.2896',
    city: 'Northwestern Alberta',
    state: 'AB',
    country: 'Canada',
  },
  T0J: {
    zip: 'T0J',
    latitude: '49.8442',
    longitude: '-110.78',
    city: 'Southeastern Alberta',
    state: 'AB',
    country: 'Canada',
  },
  T0K: {
    zip: 'T0K',
    latitude: '49.7318',
    longitude: '-112.6171',
    city: 'International Border Region',
    state: 'AB',
    country: 'Canada',
  },
  T0L: {
    zip: 'T0L',
    latitude: '49.8736',
    longitude: '-113.5074',
    city: 'Kananaskis Country',
    state: 'AB',
    country: 'Canada',
  },
  T0M: {
    zip: 'T0M',
    latitude: '52.0306',
    longitude: '-113.9565',
    city: 'Central Foothills',
    state: 'AB',
    country: 'Canada',
  },
  T0P: {
    zip: 'T0P',
    latitude: '58.759',
    longitude: '-111.0874',
    city: 'Northeastern Alberta',
    state: 'AB',
    country: 'Canada',
  },
  T0V: {
    zip: 'T0V',
    latitude: '59.8685',
    longitude: '-111.6329',
    city: 'Remote Northeast',
    state: 'AB',
    country: 'Canada',
  },
  T1A: {
    zip: 'T1A',
    latitude: '50.0365',
    longitude: '-110.661',
    city: 'Medicine Hat Central',
    state: 'AB',
    country: 'Canada',
  },
  T1B: {
    zip: 'T1B',
    latitude: '50.0172',
    longitude: '-110.651',
    city: 'Medicine Hat South',
    state: 'AB',
    country: 'Canada',
  },
  T1C: {
    zip: 'T1C',
    latitude: '50.0556',
    longitude: '-110.6822',
    city: 'Medicine Hat North',
    state: 'AB',
    country: 'Canada',
  },
  T1G: {
    zip: 'T1G',
    latitude: '49.7773',
    longitude: '-112.158',
    city: 'Taber',
    state: 'AB',
    country: 'Canada',
  },
  T1H: {
    zip: 'T1H',
    latitude: '49.7118',
    longitude: '-112.8196',
    city: 'Lethbridge North',
    state: 'AB',
    country: 'Canada',
  },
  T1J: {
    zip: 'T1J',
    latitude: '49.6915',
    longitude: '-112.8294',
    city: 'Lethbridge West And Central',
    state: 'AB',
    country: 'Canada',
  },
  T1K: {
    zip: 'T1K',
    latitude: '49.6765',
    longitude: '-112.8035',
    city: 'Lethbridge Southeast',
    state: 'AB',
    country: 'Canada',
  },
  T1L: {
    zip: 'T1L',
    latitude: '51.1791',
    longitude: '-115.5697',
    city: 'Banff',
    state: 'AB',
    country: 'Canada',
  },
  T1M: {
    zip: 'T1M',
    latitude: '49.7285',
    longitude: '-112.6146',
    city: 'Coaldale',
    state: 'AB',
    country: 'Canada',
  },
  T1P: {
    zip: 'T1P',
    latitude: '51.0459',
    longitude: '-113.3967',
    city: 'Strathmore',
    state: 'AB',
    country: 'Canada',
  },
  T1R: {
    zip: 'T1R',
    latitude: '50.5659',
    longitude: '-111.8896',
    city: 'Brooks',
    state: 'AB',
    country: 'Canada',
  },
  T1S: {
    zip: 'T1S',
    latitude: '50.7064',
    longitude: '-113.9554',
    city: 'Okotoks',
    state: 'AB',
    country: 'Canada',
  },
  T1V: {
    zip: 'T1V',
    latitude: '50.5775',
    longitude: '-113.8747',
    city: 'High River',
    state: 'AB',
    country: 'Canada',
  },
  T1W: {
    zip: 'T1W',
    latitude: '51.0868',
    longitude: '-115.3384',
    city: 'Canmore',
    state: 'AB',
    country: 'Canada',
  },
  T1X: {
    zip: 'T1X',
    latitude: '51.0512',
    longitude: '-113.8155',
    city: 'Chestermere',
    state: 'AB',
    country: 'Canada',
  },
  T1Y: {
    zip: 'T1Y',
    latitude: '51.0759',
    longitude: '-114.0015',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2A: {
    zip: 'T2A',
    latitude: '51.0402',
    longitude: '-113.9844',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2B: {
    zip: 'T2B',
    latitude: '51.0318',
    longitude: '-113.9786',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2C: {
    zip: 'T2C',
    latitude: '50.9878',
    longitude: '-114.0001',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2E: {
    zip: 'T2E',
    latitude: '51.0632',
    longitude: '-114.0614',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2G: {
    zip: 'T2G',
    latitude: '51.0415',
    longitude: '-114.0599',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2H: {
    zip: 'T2H',
    latitude: '50.9857',
    longitude: '-114.0631',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2J: {
    zip: 'T2J',
    latitude: '50.9693',
    longitude: '-114.0514',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2K: {
    zip: 'T2K',
    latitude: '51.0857',
    longitude: '-114.0714',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2L: {
    zip: 'T2L',
    latitude: '51.0917',
    longitude: '-114.1127',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2M: {
    zip: 'T2M',
    latitude: '51.0696',
    longitude: '-114.0862',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2N: {
    zip: 'T2N',
    latitude: '51.0591',
    longitude: '-114.1146',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2P: {
    zip: 'T2P',
    latitude: '51.0472',
    longitude: '-114.0802',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2R: {
    zip: 'T2R',
    latitude: '51.0426',
    longitude: '-114.0791',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2S: {
    zip: 'T2S',
    latitude: '51.0171',
    longitude: '-114.0812',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2T: {
    zip: 'T2T',
    latitude: '51.0316',
    longitude: '-114.0994',
    city: 'Calgary South',
    state: 'AB',
    country: 'Canada',
  },
  T2V: {
    zip: 'T2V',
    latitude: '50.9909',
    longitude: '-114.074',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2W: {
    zip: 'T2W',
    latitude: '50.9604',
    longitude: '-114.1001',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2X: {
    zip: 'T2X',
    latitude: '50.9204',
    longitude: '-114.0674',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2Y: {
    zip: 'T2Y',
    latitude: '50.9093',
    longitude: '-114.0721',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T2Z: {
    zip: 'T2Z',
    latitude: '50.9023',
    longitude: '-113.9873',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T3A: {
    zip: 'T3A',
    latitude: '51.0922',
    longitude: '-114.1479',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T3B: {
    zip: 'T3B',
    latitude: '51.0809',
    longitude: '-114.1616',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T3C: {
    zip: 'T3C',
    latitude: '51.0388',
    longitude: '-114.098',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T3E: {
    zip: 'T3E',
    latitude: '51.0227',
    longitude: '-114.1342',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T3G: {
    zip: 'T3G',
    latitude: '51.1147',
    longitude: '-114.1796',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T3H: {
    zip: 'T3H',
    latitude: '51.0566',
    longitude: '-114.1815',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T3J: {
    zip: 'T3J',
    latitude: '51.0999',
    longitude: '-113.9422',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T3K: {
    zip: 'T3K',
    latitude: '51.127',
    longitude: '-114.0787',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T3L: {
    zip: 'T3L',
    latitude: '51.1162',
    longitude: '-114.2089',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T3M: {
    zip: 'T3M',
    latitude: '50.8902',
    longitude: '-113.9892',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T3N: {
    zip: 'T3N',
    latitude: '51.1494',
    longitude: '-114.0019',
    city: 'Calgary Northeast',
    state: 'AB',
    country: 'Canada',
  },
  T3P: {
    zip: 'T3P',
    latitude: '51.1793',
    longitude: '-114.1333',
    city: 'Calgary',
    state: 'AB',
    country: 'Canada',
  },
  T3R: {
    zip: 'T3R',
    latitude: '51.1497',
    longitude: '-114.2695',
    city: 'Calgary Northwest',
    state: 'AB',
    country: 'Canada',
  },
  T3Z: {
    zip: 'T3Z',
    latitude: '50.9821',
    longitude: '-114.5178',
    city: 'Redwood Meadows',
    state: 'AB',
    country: 'Canada',
  },
  T4A: {
    zip: 'T4A',
    latitude: '51.2733',
    longitude: '-113.9909',
    city: 'Airdrie East',
    state: 'AB',
    country: 'Canada',
  },
  T4B: {
    zip: 'T4B',
    latitude: '51.2816',
    longitude: '-114.0153',
    city: 'Airdrie West',
    state: 'AB',
    country: 'Canada',
  },
  T4C: {
    zip: 'T4C',
    latitude: '51.1896',
    longitude: '-114.4774',
    city: 'Cochrane',
    state: 'AB',
    country: 'Canada',
  },
  T4E: {
    zip: 'T4E',
    latitude: '52.2911',
    longitude: '-113.7027',
    city: 'Red Deer County',
    state: 'AB',
    country: 'Canada',
  },
  T4G: {
    zip: 'T4G',
    latitude: '52.029',
    longitude: '-113.9474',
    city: 'Innisfail',
    state: 'AB',
    country: 'Canada',
  },
  T4H: {
    zip: 'T4H',
    latitude: '51.7956',
    longitude: '-114.0944',
    city: 'Olds',
    state: 'AB',
    country: 'Canada',
  },
  T4J: {
    zip: 'T4J',
    latitude: '52.6649',
    longitude: '-113.5823',
    city: 'Ponoka',
    state: 'AB',
    country: 'Canada',
  },
  T4L: {
    zip: 'T4L',
    latitude: '52.36',
    longitude: '-114.3736',
    city: 'Lacombe',
    state: 'AB',
    country: 'Canada',
  },
  T4N: {
    zip: 'T4N',
    latitude: '52.2592',
    longitude: '-113.8237',
    city: 'Red Deer Central',
    state: 'AB',
    country: 'Canada',
  },
  T4P: {
    zip: 'T4P',
    latitude: '52.2887',
    longitude: '-113.8394',
    city: 'Red Deer North',
    state: 'AB',
    country: 'Canada',
  },
  T4R: {
    zip: 'T4R',
    latitude: '52.2451',
    longitude: '-113.7855',
    city: 'Red Deer South',
    state: 'AB',
    country: 'Canada',
  },
  T4S: {
    zip: 'T4S',
    latitude: '52.3083',
    longitude: '-114.0949',
    city: 'Sylvan Lake',
    state: 'AB',
    country: 'Canada',
  },
  T4T: {
    zip: 'T4T',
    latitude: '52.378',
    longitude: '-114.9307',
    city: 'Rocky Mountain House',
    state: 'AB',
    country: 'Canada',
  },
  T4V: {
    zip: 'T4V',
    latitude: '53.0204',
    longitude: '-112.8129',
    city: 'Camrose',
    state: 'AB',
    country: 'Canada',
  },
  T4X: {
    zip: 'T4X',
    latitude: '53.3571',
    longitude: '-113.4129',
    city: 'Beaumont',
    state: 'AB',
    country: 'Canada',
  },
  T5A: {
    zip: 'T5A',
    latitude: '53.5899',
    longitude: '-113.4413',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5B: {
    zip: 'T5B',
    latitude: '53.5766',
    longitude: '-113.4608',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5C: {
    zip: 'T5C',
    latitude: '53.6129',
    longitude: '-113.4572',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5E: {
    zip: 'T5E',
    latitude: '53.5923',
    longitude: '-113.5168',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5G: {
    zip: 'T5G',
    latitude: '53.5682',
    longitude: '-113.4822',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5H: {
    zip: 'T5H',
    latitude: '53.555',
    longitude: '-113.4822',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5J: {
    zip: 'T5J',
    latitude: '53.5421',
    longitude: '-113.4989',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5K: {
    zip: 'T5K',
    latitude: '53.535',
    longitude: '-113.501',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5L: {
    zip: 'T5L',
    latitude: '53.5801',
    longitude: '-113.541',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5M: {
    zip: 'T5M',
    latitude: '53.5614',
    longitude: '-113.5461',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5N: {
    zip: 'T5N',
    latitude: '53.5495',
    longitude: '-113.5453',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5P: {
    zip: 'T5P',
    latitude: '53.5529',
    longitude: '-113.584',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5R: {
    zip: 'T5R',
    latitude: '53.5224',
    longitude: '-113.5763',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5S: {
    zip: 'T5S',
    latitude: '53.5416',
    longitude: '-113.6249',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5T: {
    zip: 'T5T',
    latitude: '53.5157',
    longitude: '-113.6339',
    city: 'Edmonton West',
    state: 'AB',
    country: 'Canada',
  },
  T5V: {
    zip: 'T5V',
    latitude: '53.58',
    longitude: '-113.5873',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5W: {
    zip: 'T5W',
    latitude: '53.5705',
    longitude: '-113.4036',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5X: {
    zip: 'T5X',
    latitude: '53.6072',
    longitude: '-113.5183',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5Y: {
    zip: 'T5Y',
    latitude: '53.6026',
    longitude: '-113.3837',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T5Z: {
    zip: 'T5Z',
    latitude: '53.5966',
    longitude: '-113.4882',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6A: {
    zip: 'T6A',
    latitude: '53.5483',
    longitude: '-113.408',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6B: {
    zip: 'T6B',
    latitude: '53.5322',
    longitude: '-113.4404',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6C: {
    zip: 'T6C',
    latitude: '53.5182',
    longitude: '-113.4769',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6E: {
    zip: 'T6E',
    latitude: '53.5087',
    longitude: '-113.5078',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6G: {
    zip: 'T6G',
    latitude: '53.5248',
    longitude: '-113.5334',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6H: {
    zip: 'T6H',
    latitude: '53.4839',
    longitude: '-113.5227',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6J: {
    zip: 'T6J',
    latitude: '53.4822',
    longitude: '-113.5269',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6K: {
    zip: 'T6K',
    latitude: '53.4816',
    longitude: '-113.4623',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6L: {
    zip: 'T6L',
    latitude: '53.4681',
    longitude: '-113.4339',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6M: {
    zip: 'T6M',
    latitude: '53.4967',
    longitude: '-113.6162',
    city: 'Edmonton Southwest',
    state: 'AB',
    country: 'Canada',
  },
  T6N: {
    zip: 'T6N',
    latitude: '53.458',
    longitude: '-113.4826',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6P: {
    zip: 'T6P',
    latitude: '53.4996',
    longitude: '-113.3678',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6R: {
    zip: 'T6R',
    latitude: '53.4782',
    longitude: '-113.5873',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6S: {
    zip: 'T6S',
    latitude: '53.5729',
    longitude: '-113.3518',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6T: {
    zip: 'T6T',
    latitude: '53.4768',
    longitude: '-113.3662',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6V: {
    zip: 'T6V',
    latitude: '53.6202',
    longitude: '-113.543',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6W: {
    zip: 'T6W',
    latitude: '53.4129',
    longitude: '-113.4957',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T6X: {
    zip: 'T6X',
    latitude: '53.4154',
    longitude: '-113.4917',
    city: 'Edmonton',
    state: 'AB',
    country: 'Canada',
  },
  T7A: {
    zip: 'T7A',
    latitude: '53.2165',
    longitude: '-114.9893',
    city: 'Drayton Valley',
    state: 'AB',
    country: 'Canada',
  },
  T7E: {
    zip: 'T7E',
    latitude: '53.5908',
    longitude: '-116.4104',
    city: 'Edson',
    state: 'AB',
    country: 'Canada',
  },
  T7N: {
    zip: 'T7N',
    latitude: '54.1136',
    longitude: '-114.3932',
    city: 'Barrhead',
    state: 'AB',
    country: 'Canada',
  },
  T7P: {
    zip: 'T7P',
    latitude: '54.166',
    longitude: '-113.8452',
    city: 'Westlock',
    state: 'AB',
    country: 'Canada',
  },
  T7S: {
    zip: 'T7S',
    latitude: '54.1407',
    longitude: '-115.6873',
    city: 'Whitecourt',
    state: 'AB',
    country: 'Canada',
  },
  T7V: {
    zip: 'T7V',
    latitude: '53.3981',
    longitude: '-117.5552',
    city: 'Hinton',
    state: 'AB',
    country: 'Canada',
  },
  T7X: {
    zip: 'T7X',
    latitude: '53.549',
    longitude: '-113.8995',
    city: 'Spruce Grove North',
    state: 'AB',
    country: 'Canada',
  },
  T7Y: {
    zip: 'T7Y',
    latitude: '53.4495',
    longitude: '-113.7135',
    city: 'Spruce Grove South',
    state: 'AB',
    country: 'Canada',
  },
  T7Z: {
    zip: 'T7Z',
    latitude: '53.5202',
    longitude: '-114.0135',
    city: 'Stony Plain',
    state: 'AB',
    country: 'Canada',
  },
  T8A: {
    zip: 'T8A',
    latitude: '53.519',
    longitude: '-113.3216',
    city: 'Sherwood Park West',
    state: 'AB',
    country: 'Canada',
  },
  T8B: {
    zip: 'T8B',
    latitude: '53.4482',
    longitude: '-113.2706',
    city: 'Sherwood Park Outer Southwest',
    state: 'AB',
    country: 'Canada',
  },
  T8C: {
    zip: 'T8C',
    latitude: '53.4162',
    longitude: '-113.148',
    city: 'Sherwood Park Inner Southwest',
    state: 'AB',
    country: 'Canada',
  },
  T8E: {
    zip: 'T8E',
    latitude: '53.4548',
    longitude: '-113.0498',
    city: 'Sherwood Park Central',
    state: 'AB',
    country: 'Canada',
  },
  T8G: {
    zip: 'T8G',
    latitude: '53.4749',
    longitude: '-112.9512',
    city: 'Sherwood Park East',
    state: 'AB',
    country: 'Canada',
  },
  T8H: {
    zip: 'T8H',
    latitude: '53.5462',
    longitude: '-113.2562',
    city: 'Sherwood Park Northwest',
    state: 'AB',
    country: 'Canada',
  },
  T8L: {
    zip: 'T8L',
    latitude: '53.6916',
    longitude: '-113.2286',
    city: 'Fort Saskatchewan',
    state: 'AB',
    country: 'Canada',
  },
  T8N: {
    zip: 'T8N',
    latitude: '53.6199',
    longitude: '-113.6377',
    city: 'St. Albert',
    state: 'AB',
    country: 'Canada',
  },
  T8R: {
    zip: 'T8R',
    latitude: '53.7903',
    longitude: '-113.646',
    city: 'Morinville',
    state: 'AB',
    country: 'Canada',
  },
  T8S: {
    zip: 'T8S',
    latitude: '56.2539',
    longitude: '-117.2849',
    city: 'Peace River',
    state: 'AB',
    country: 'Canada',
  },
  T8V: {
    zip: 'T8V',
    latitude: '55.1726',
    longitude: '-118.7997',
    city: 'Grande Prairie Central',
    state: 'AB',
    country: 'Canada',
  },
  T8W: {
    zip: 'T8W',
    latitude: '55.1389',
    longitude: '-118.773',
    city: 'Grande Prairie South',
    state: 'AB',
    country: 'Canada',
  },
  T8X: {
    zip: 'T8X',
    latitude: '55.1749',
    longitude: '-118.7633',
    city: 'Grande Prairie East',
    state: 'AB',
    country: 'Canada',
  },
  T9A: {
    zip: 'T9A',
    latitude: '52.9741',
    longitude: '-113.3646',
    city: 'Wetaskiwin',
    state: 'AB',
    country: 'Canada',
  },
  T9C: {
    zip: 'T9C',
    latitude: '53.4874',
    longitude: '-112.0636',
    city: 'Vegreville',
    state: 'AB',
    country: 'Canada',
  },
  T9E: {
    zip: 'T9E',
    latitude: '53.2524',
    longitude: '-113.5388',
    city: 'Leduc',
    state: 'AB',
    country: 'Canada',
  },
  T9G: {
    zip: 'T9G',
    latitude: '53.3632',
    longitude: '-113.7286',
    city: 'Devon',
    state: 'AB',
    country: 'Canada',
  },
  T9H: {
    zip: 'T9H',
    latitude: '56.6977',
    longitude: '-111.3389',
    city: 'Fort Mcmurray Outer Central',
    state: 'AB',
    country: 'Canada',
  },
  T9J: {
    zip: 'T9J',
    latitude: '56.7057',
    longitude: '-111.3723',
    city: 'Fort Mcmurray Inner Central',
    state: 'AB',
    country: 'Canada',
  },
  T9K: {
    zip: 'T9K',
    latitude: '56.7273',
    longitude: '-111.4361',
    city: 'Fort Mcmurray Northwest',
    state: 'AB',
    country: 'Canada',
  },
  T9M: {
    zip: 'T9M',
    latitude: '54.4127',
    longitude: '-110.2162',
    city: 'Cold Lake',
    state: 'AB',
    country: 'Canada',
  },
  T9N: {
    zip: 'T9N',
    latitude: '54.2678',
    longitude: '-110.7324',
    city: 'Bonnyville',
    state: 'AB',
    country: 'Canada',
  },
  T9S: {
    zip: 'T9S',
    latitude: '54.7139',
    longitude: '-113.2942',
    city: 'Athabasca',
    state: 'AB',
    country: 'Canada',
  },
  T9V: {
    zip: 'T9V',
    latitude: '53.2786',
    longitude: '-110.0233',
    city: 'Lloydminster',
    state: 'AB',
    country: 'Canada',
  },
  T9W: {
    zip: 'T9W',
    latitude: '52.8403',
    longitude: '-110.8704',
    city: 'Wainwright',
    state: 'AB',
    country: 'Canada',
  },
  T9X: {
    zip: 'T9X',
    latitude: '53.3515',
    longitude: '-110.8451',
    city: 'Vermilion',
    state: 'AB',
    country: 'Canada',
  },
  V0A: {
    zip: 'V0A',
    latitude: '50.5402',
    longitude: '-116.0019',
    city: 'Upper Columbia Region',
    state: 'BC',
    country: 'Canada',
  },
  V0B: {
    zip: 'V0B',
    latitude: '49.4539',
    longitude: '-116.763',
    city: 'East Kootenays',
    state: 'BC',
    country: 'Canada',
  },
  V0C: {
    zip: 'V0C',
    latitude: '56.2478',
    longitude: '-120.8491',
    city: 'Northern British Columbia',
    state: 'BC',
    country: 'Canada',
  },
  V0E: {
    zip: 'V0E',
    latitude: '50.9647',
    longitude: '-119.1638',
    city: 'Central Okanagan And High Country',
    state: 'BC',
    country: 'Canada',
  },
  V0G: {
    zip: 'V0G',
    latitude: '49.7332',
    longitude: '-116.913',
    city: 'West Kootenays',
    state: 'BC',
    country: 'Canada',
  },
  V0H: {
    zip: 'V0H',
    latitude: '49.2357',
    longitude: '-119.0117',
    city: 'South Okanagan',
    state: 'BC',
    country: 'Canada',
  },
  V0J: {
    zip: 'V0J',
    latitude: '55.2046',
    longitude: '-129.0828',
    city: 'Omineca And Yellowhead',
    state: 'BC',
    country: 'Canada',
  },
  V0K: {
    zip: 'V0K',
    latitude: '50.7372',
    longitude: '-121.2713',
    city: 'Cariboo And West Okanagan',
    state: 'BC',
    country: 'Canada',
  },
  V0L: {
    zip: 'V0L',
    latitude: '52.4018',
    longitude: '-124.0226',
    city: 'Chilcotin',
    state: 'BC',
    country: 'Canada',
  },
  V0M: {
    zip: 'V0M',
    latitude: '49.2341',
    longitude: '-121.7705',
    city: 'Harrison Lake Region',
    state: 'BC',
    country: 'Canada',
  },
  V0N: {
    zip: 'V0N',
    latitude: 'null',
    longitude: 'null',
    city: 'North Island',
    state: 'Sunshine Coast',
    country: 'Canada',
  },
  V0P: {
    zip: 'V0P',
    latitude: '50.898',
    longitude: '-124.8633',
    city: 'North Central Island And Bute Inlet Region',
    state: 'BC',
    country: 'Canada',
  },
  V0R: {
    zip: 'V0R',
    latitude: '49.2818',
    longitude: '-126.0627',
    city: 'Central Island',
    state: 'BC',
    country: 'Canada',
  },
  V0S: {
    zip: 'V0S',
    latitude: '48.5788',
    longitude: '-123.4637',
    city: 'Juan De Fuca Shore',
    state: 'BC',
    country: 'Canada',
  },
  V0T: {
    zip: 'V0T',
    latitude: '54.7992',
    longitude: '-130.0782',
    city: 'Inside Passage And The Queen Charlottes',
    state: 'BC',
    country: 'Canada',
  },
  V0V: {
    zip: 'V0V',
    latitude: '53.4242',
    longitude: '-129.263',
    city: 'Lower Skeena',
    state: 'BC',
    country: 'Canada',
  },
  V0W: {
    zip: 'V0W',
    latitude: '59.4808',
    longitude: '-133.6312',
    city: 'Atlin Region',
    state: 'BC',
    country: 'Canada',
  },
  V0X: {
    zip: 'V0X',
    latitude: '49.0538',
    longitude: '-122.476',
    city: 'Similkameen',
    state: 'BC',
    country: 'Canada',
  },
  V1A: {
    zip: 'V1A',
    latitude: '49.6626',
    longitude: '-115.9667',
    city: 'Kimberley',
    state: 'BC',
    country: 'Canada',
  },
  V1B: {
    zip: 'V1B',
    latitude: '50.2158',
    longitude: '-119.2709',
    city: 'Vernon East',
    state: 'BC',
    country: 'Canada',
  },
  V1C: {
    zip: 'V1C',
    latitude: '49.512',
    longitude: '-115.7703',
    city: 'Cranbrook',
    state: 'BC',
    country: 'Canada',
  },
  V1E: {
    zip: 'V1E',
    latitude: '50.6947',
    longitude: '-119.2915',
    city: 'Salmon Arm',
    state: 'BC',
    country: 'Canada',
  },
  V1G: {
    zip: 'V1G',
    latitude: '55.7741',
    longitude: '-120.2533',
    city: 'Dawson Creek',
    state: 'BC',
    country: 'Canada',
  },
  V1H: {
    zip: 'V1H',
    latitude: '50.2629',
    longitude: '-119.3037',
    city: 'Vernon West',
    state: 'BC',
    country: 'Canada',
  },
  V1J: {
    zip: 'V1J',
    latitude: '56.2306',
    longitude: '-120.8277',
    city: 'Fort St. John',
    state: 'BC',
    country: 'Canada',
  },
  V1K: {
    zip: 'V1K',
    latitude: '50.1076',
    longitude: '-120.7755',
    city: 'Merritt',
    state: 'BC',
    country: 'Canada',
  },
  V1L: {
    zip: 'V1L',
    latitude: '49.4832',
    longitude: '-117.3031',
    city: 'Nelson',
    state: 'BC',
    country: 'Canada',
  },
  V1M: {
    zip: 'V1M',
    latitude: '49.164',
    longitude: '-122.656',
    city: 'Langley Township North',
    state: 'BC',
    country: 'Canada',
  },
  V1N: {
    zip: 'V1N',
    latitude: '49.3298',
    longitude: '-117.6607',
    city: 'Castlegar',
    state: 'BC',
    country: 'Canada',
  },
  V1P: {
    zip: 'V1P',
    latitude: '49.8808',
    longitude: '-119.3647',
    city: 'Kelowna East',
    state: 'BC',
    country: 'Canada',
  },
  V1R: {
    zip: 'V1R',
    latitude: '49.1135',
    longitude: '-117.716',
    city: 'Trail',
    state: 'BC',
    country: 'Canada',
  },
  V1S: {
    zip: 'V1S',
    latitude: '50.6553',
    longitude: '-120.3811',
    city: 'Kamloops Southwest',
    state: 'BC',
    country: 'Canada',
  },
  V1T: {
    zip: 'V1T',
    latitude: '50.2533',
    longitude: '-119.2798',
    city: 'Vernon Central',
    state: 'BC',
    country: 'Canada',
  },
  V1V: {
    zip: 'V1V',
    latitude: '49.929',
    longitude: '-119.4676',
    city: 'Kelowna North',
    state: 'BC',
    country: 'Canada',
  },
  V1W: {
    zip: 'V1W',
    latitude: '49.842',
    longitude: '-119.4903',
    city: 'Kelowna Southwest',
    state: 'BC',
    country: 'Canada',
  },
  V1X: {
    zip: 'V1X',
    latitude: '49.8754',
    longitude: '-119.3958',
    city: 'Kelowna East Central',
    state: 'BC',
    country: 'Canada',
  },
  V1Y: {
    zip: 'V1Y',
    latitude: '49.8803',
    longitude: '-119.5004',
    city: 'Kelowna Central',
    state: 'BC',
    country: 'Canada',
  },
  V1Z: {
    zip: 'V1Z',
    latitude: '49.88',
    longitude: '-119.5355',
    city: 'Kelowna West',
    state: 'BC',
    country: 'Canada',
  },
  V2A: {
    zip: 'V2A',
    latitude: '49.5031',
    longitude: '-119.5905',
    city: 'Penticton',
    state: 'BC',
    country: 'Canada',
  },
  V2B: {
    zip: 'V2B',
    latitude: '50.6903',
    longitude: '-120.3634',
    city: 'Kamloops Northwest',
    state: 'BC',
    country: 'Canada',
  },
  V2C: {
    zip: 'V2C',
    latitude: '50.6764',
    longitude: '-120.3399',
    city: 'Kamloops Central And Southeast',
    state: 'BC',
    country: 'Canada',
  },
  V2E: {
    zip: 'V2E',
    latitude: '50.6598',
    longitude: '-120.3837',
    city: 'Kamloops South And West',
    state: 'BC',
    country: 'Canada',
  },
  V2G: {
    zip: 'V2G',
    latitude: '52.1276',
    longitude: '-122.1271',
    city: 'Williams Lake',
    state: 'BC',
    country: 'Canada',
  },
  V2H: {
    zip: 'V2H',
    latitude: '50.6902',
    longitude: '-120.0461',
    city: 'Kamloops North',
    state: 'BC',
    country: 'Canada',
  },
  V2J: {
    zip: 'V2J',
    latitude: '52.9692',
    longitude: '-122.5057',
    city: 'Quesnel',
    state: 'BC',
    country: 'Canada',
  },
  V2K: {
    zip: 'V2K',
    latitude: '53.9313',
    longitude: '-122.7823',
    city: 'Prince George North',
    state: 'BC',
    country: 'Canada',
  },
  V2L: {
    zip: 'V2L',
    latitude: '53.9112',
    longitude: '-122.728',
    city: 'Prince George East Central',
    state: 'BC',
    country: 'Canada',
  },
  V2M: {
    zip: 'V2M',
    latitude: '53.928',
    longitude: '-122.7878',
    city: 'Prince George West Central',
    state: 'BC',
    country: 'Canada',
  },
  V2N: {
    zip: 'V2N',
    latitude: '53.9103',
    longitude: '-122.7835',
    city: 'Prince George South',
    state: 'BC',
    country: 'Canada',
  },
  V2P: {
    zip: 'V2P',
    latitude: '49.1551',
    longitude: '-121.9459',
    city: 'Chilliwack Central',
    state: 'BC',
    country: 'Canada',
  },
  V2R: {
    zip: 'V2R',
    latitude: '49.1409',
    longitude: '-121.962',
    city: 'Chilliwack West',
    state: 'BC',
    country: 'Canada',
  },
  V2S: {
    zip: 'V2S',
    latitude: '49.0312',
    longitude: '-122.3012',
    city: 'Abbotsford Southeast',
    state: 'BC',
    country: 'Canada',
  },
  V2T: {
    zip: 'V2T',
    latitude: '49.0382',
    longitude: '-122.335',
    city: 'Abbotsford Southwest',
    state: 'BC',
    country: 'Canada',
  },
  V2V: {
    zip: 'V2V',
    latitude: '49.1337',
    longitude: '-122.3434',
    city: 'Mission East',
    state: 'BC',
    country: 'Canada',
  },
  V2W: {
    zip: 'V2W',
    latitude: '49.2201',
    longitude: '-122.4985',
    city: 'Maple Ridge East',
    state: 'BC',
    country: 'Canada',
  },
  V2X: {
    zip: 'V2X',
    latitude: '49.2007',
    longitude: '-122.6641',
    city: 'Maple Ridge West',
    state: 'BC',
    country: 'Canada',
  },
  V2Y: {
    zip: 'V2Y',
    latitude: '49.1175',
    longitude: '-122.6684',
    city: 'Langley Township Northwest',
    state: 'BC',
    country: 'Canada',
  },
  V2Z: {
    zip: 'V2Z',
    latitude: '49.0501',
    longitude: '-122.6745',
    city: 'Langley Township Southwest',
    state: 'BC',
    country: 'Canada',
  },
  V3A: {
    zip: 'V3A',
    latitude: '49.0764',
    longitude: '-122.6797',
    city: 'Langley City',
    state: 'BC',
    country: 'Canada',
  },
  V3B: {
    zip: 'V3B',
    latitude: '49.2733',
    longitude: '-122.7965',
    city: 'Port Coquitlam Central',
    state: 'BC',
    country: 'Canada',
  },
  V3C: {
    zip: 'V3C',
    latitude: '49.2334',
    longitude: '-122.77',
    city: 'Port Coquitlam South',
    state: 'BC',
    country: 'Canada',
  },
  V3E: {
    zip: 'V3E',
    latitude: '49.2796',
    longitude: '-122.8105',
    city: 'Port Coquitlam North',
    state: 'BC',
    country: 'Canada',
  },
  V3G: {
    zip: 'V3G',
    latitude: '49.0625',
    longitude: '-122.2457',
    city: 'Abbotsford East',
    state: 'BC',
    country: 'Canada',
  },
  V3H: {
    zip: 'V3H',
    latitude: '49.2707',
    longitude: '-122.883',
    city: 'Port Moody',
    state: 'BC',
    country: 'Canada',
  },
  V3J: {
    zip: 'V3J',
    latitude: '49.2536',
    longitude: '-122.9085',
    city: 'Coquitlam North',
    state: 'BC',
    country: 'Canada',
  },
  V3K: {
    zip: 'V3K',
    latitude: '49.2358',
    longitude: '-122.8693',
    city: 'Coquitlam South',
    state: 'BC',
    country: 'Canada',
  },
  V3L: {
    zip: 'V3L',
    latitude: '49.2136',
    longitude: '-122.8949',
    city: 'New Westminster Northeast',
    state: 'BC',
    country: 'Canada',
  },
  V3M: {
    zip: 'V3M',
    latitude: '49.2007',
    longitude: '-122.9074',
    city: 'New Westminster Southwest',
    state: 'BC',
    country: 'Canada',
  },
  V3N: {
    zip: 'V3N',
    latitude: '49.2201',
    longitude: '-122.9478',
    city: 'Burnaby',
    state: 'BC',
    country: 'Canada',
  },
  V3R: {
    zip: 'V3R',
    latitude: '49.1641',
    longitude: '-122.8193',
    city: 'Surrey North',
    state: 'BC',
    country: 'Canada',
  },
  V3S: {
    zip: 'V3S',
    latitude: '49.1011',
    longitude: '-122.8141',
    city: 'Surrey East',
    state: 'BC',
    country: 'Canada',
  },
  V3T: {
    zip: 'V3T',
    latitude: '49.1783',
    longitude: '-122.8665',
    city: 'Surrey Inner Northwest',
    state: 'BC',
    country: 'Canada',
  },
  V3V: {
    zip: 'V3V',
    latitude: '49.1647',
    longitude: '-122.8487',
    city: 'Surrey Outer Northwest',
    state: 'BC',
    country: 'Canada',
  },
  V3W: {
    zip: 'V3W',
    latitude: '49.0992',
    longitude: '-122.8691',
    city: 'Surrey Upper West',
    state: 'BC',
    country: 'Canada',
  },
  V3X: {
    zip: 'V3X',
    latitude: '49.1173',
    longitude: '-122.8234',
    city: 'Surrey Lower West',
    state: 'BC',
    country: 'Canada',
  },
  V3Y: {
    zip: 'V3Y',
    latitude: '49.2273',
    longitude: '-122.6883',
    city: 'Pitt Meadows',
    state: 'BC',
    country: 'Canada',
  },
  V4A: {
    zip: 'V4A',
    latitude: '49.0168',
    longitude: '-122.7738',
    city: 'Surrey Southwest',
    state: 'BC',
    country: 'Canada',
  },
  V4B: {
    zip: 'V4B',
    latitude: '49.0268',
    longitude: '-122.8369',
    city: 'White Rock',
    state: 'BC',
    country: 'Canada',
  },
  V4C: {
    zip: 'V4C',
    latitude: '49.1348',
    longitude: '-122.9131',
    city: 'Delta Northeast',
    state: 'BC',
    country: 'Canada',
  },
  V4E: {
    zip: 'V4E',
    latitude: '49.0482',
    longitude: '-122.9587',
    city: 'Delta East',
    state: 'BC',
    country: 'Canada',
  },
  V4G: {
    zip: 'V4G',
    latitude: '49.1367',
    longitude: '-123.0115',
    city: 'Delta East Central',
    state: 'BC',
    country: 'Canada',
  },
  V4K: {
    zip: 'V4K',
    latitude: '49.0798',
    longitude: '-123.0882',
    city: 'Delta Central',
    state: 'BC',
    country: 'Canada',
  },
  V4L: {
    zip: 'V4L',
    latitude: '49.0023',
    longitude: '-123.0368',
    city: 'Delta Southeast',
    state: 'BC',
    country: 'Canada',
  },
  V4M: {
    zip: 'V4M',
    latitude: '49.0025',
    longitude: '-123.0746',
    city: 'Delta Southwest',
    state: 'BC',
    country: 'Canada',
  },
  V4N: {
    zip: 'V4N',
    latitude: '49.1636',
    longitude: '-122.7677',
    city: 'Surrey Northeast',
    state: 'BC',
    country: 'Canada',
  },
  V4P: {
    zip: 'V4P',
    latitude: '49.0499',
    longitude: '-122.804',
    city: 'Surrey South',
    state: 'BC',
    country: 'Canada',
  },
  V4R: {
    zip: 'V4R',
    latitude: '49.2225',
    longitude: '-122.4984',
    city: 'Maple Ridge Northwest',
    state: 'BC',
    country: 'Canada',
  },
  V4S: {
    zip: 'V4S',
    latitude: '49.1589',
    longitude: '-122.3089',
    city: 'Mission West',
    state: 'BC',
    country: 'Canada',
  },
  V4T: {
    zip: 'V4T',
    latitude: '49.838',
    longitude: '-119.6667',
    city: 'Westbank',
    state: 'BC',
    country: 'Canada',
  },
  V4V: {
    zip: 'V4V',
    latitude: '50.0734',
    longitude: '-119.4444',
    city: 'Winfield',
    state: 'BC',
    country: 'Canada',
  },
  V4W: {
    zip: 'V4W',
    latitude: '49.1307',
    longitude: '-122.5369',
    city: 'Langley Township East',
    state: 'BC',
    country: 'Canada',
  },
  V4X: {
    zip: 'V4X',
    latitude: '49.0024',
    longitude: '-122.4419',
    city: 'Abbotsford West',
    state: 'BC',
    country: 'Canada',
  },
  V4Z: {
    zip: 'V4Z',
    latitude: '49.146',
    longitude: '-121.9435',
    city: 'Chilliwack East',
    state: 'BC',
    country: 'Canada',
  },
  V5A: {
    zip: 'V5A',
    latitude: '49.2869',
    longitude: '-122.958',
    city: 'Burnaby',
    state: 'BC',
    country: 'Canada',
  },
  V5B: {
    zip: 'V5B',
    latitude: '49.2846',
    longitude: '-122.9914',
    city: 'Burnaby',
    state: 'BC',
    country: 'Canada',
  },
  V5C: {
    zip: 'V5C',
    latitude: '49.2848',
    longitude: '-123.0222',
    city: 'Burnaby',
    state: 'BC',
    country: 'Canada',
  },
  V5E: {
    zip: 'V5E',
    latitude: '49.2124',
    longitude: '-122.9696',
    city: 'Burnaby',
    state: 'BC',
    country: 'Canada',
  },
  V5G: {
    zip: 'V5G',
    latitude: '49.2591',
    longitude: '-123.0226',
    city: 'Burnaby',
    state: 'BC',
    country: 'Canada',
  },
  V5H: {
    zip: 'V5H',
    latitude: '49.2371',
    longitude: '-123.0229',
    city: 'Burnaby',
    state: 'BC',
    country: 'Canada',
  },
  V5J: {
    zip: 'V5J',
    latitude: '49.2218',
    longitude: '-123.022',
    city: 'Burnaby',
    state: 'BC',
    country: 'Canada',
  },
  V5K: {
    zip: 'V5K',
    latitude: '49.293',
    longitude: '-123.0489',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V5L: {
    zip: 'V5L',
    latitude: '49.2835',
    longitude: '-123.0786',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V5M: {
    zip: 'V5M',
    latitude: '49.2695',
    longitude: '-123.0556',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V5N: {
    zip: 'V5N',
    latitude: '49.2699',
    longitude: '-123.0765',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V5P: {
    zip: 'V5P',
    latitude: '49.2393',
    longitude: '-123.0729',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V5R: {
    zip: 'V5R',
    latitude: '49.2499',
    longitude: '-123.0556',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V5S: {
    zip: 'V5S',
    latitude: '49.2286',
    longitude: '-123.057',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V5T: {
    zip: 'V5T',
    latitude: '49.2701',
    longitude: '-123.1038',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V5V: {
    zip: 'V5V',
    latitude: '49.2558',
    longitude: '-123.1037',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V5W: {
    zip: 'V5W',
    latitude: '49.2396',
    longitude: '-123.0984',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V5X: {
    zip: 'V5X',
    latitude: '49.2249',
    longitude: '-123.1052',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V5Y: {
    zip: 'V5Y',
    latitude: '49.2702',
    longitude: '-123.1017',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V5Z: {
    zip: 'V5Z',
    latitude: '49.2658',
    longitude: '-123.1151',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6A: {
    zip: 'V6A',
    latitude: '49.2862',
    longitude: '-123.0925',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6B: {
    zip: 'V6B',
    latitude: '49.2836',
    longitude: '-123.1041',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6C: {
    zip: 'V6C',
    latitude: '49.2857',
    longitude: '-123.1142',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6E: {
    zip: 'V6E',
    latitude: '49.2848',
    longitude: '-123.1228',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6G: {
    zip: 'V6G',
    latitude: '49.289',
    longitude: '-123.1294',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6H: {
    zip: 'V6H',
    latitude: '49.2661',
    longitude: '-123.1276',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6J: {
    zip: 'V6J',
    latitude: '49.2768',
    longitude: '-123.1469',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6K: {
    zip: 'V6K',
    latitude: '49.2738',
    longitude: '-123.161',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6L: {
    zip: 'V6L',
    latitude: '49.2571',
    longitude: '-123.1662',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6M: {
    zip: 'V6M',
    latitude: '49.2417',
    longitude: '-123.1293',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6N: {
    zip: 'V6N',
    latitude: '49.2376',
    longitude: '-123.1639',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6P: {
    zip: 'V6P',
    latitude: '49.2254',
    longitude: '-123.1176',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6R: {
    zip: 'V6R',
    latitude: '49.273',
    longitude: '-123.185',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6S: {
    zip: 'V6S',
    latitude: '49.2574',
    longitude: '-123.1836',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6T: {
    zip: 'V6T',
    latitude: '49.2765',
    longitude: '-123.2177',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V6V: {
    zip: 'V6V',
    latitude: '49.1699',
    longitude: '-123.0912',
    city: 'Richmond Northeast',
    state: 'BC',
    country: 'Canada',
  },
  V6W: {
    zip: 'V6W',
    latitude: '49.1261',
    longitude: '-123.0897',
    city: 'Richmond Southeast',
    state: 'BC',
    country: 'Canada',
  },
  V6X: {
    zip: 'V6X',
    latitude: '49.1701',
    longitude: '-123.1438',
    city: 'Richmond North',
    state: 'BC',
    country: 'Canada',
  },
  V6Y: {
    zip: 'V6Y',
    latitude: '49.1483',
    longitude: '-123.1469',
    city: 'Richmond Central',
    state: 'BC',
    country: 'Canada',
  },
  V6Z: {
    zip: 'V6Z',
    latitude: '49.2814',
    longitude: '-123.12',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V7A: {
    zip: 'V7A',
    latitude: '49.1467',
    longitude: '-123.1463',
    city: 'Richmond South',
    state: 'BC',
    country: 'Canada',
  },
  V7B: {
    zip: 'V7B',
    latitude: '49.178',
    longitude: '-123.1701',
    city: 'Richmond',
    state: 'BC',
    country: 'Canada',
  },
  V7C: {
    zip: 'V7C',
    latitude: '49.1745',
    longitude: '-123.1978',
    city: 'Richmond West',
    state: 'BC',
    country: 'Canada',
  },
  V7E: {
    zip: 'V7E',
    latitude: '49.1476',
    longitude: '-123.1897',
    city: 'Richmond Southwest',
    state: 'BC',
    country: 'Canada',
  },
  V7G: {
    zip: 'V7G',
    latitude: '49.304',
    longitude: '-122.9689',
    city: 'North Vancouver Outer East',
    state: 'BC',
    country: 'Canada',
  },
  V7H: {
    zip: 'V7H',
    latitude: '49.3011',
    longitude: '-123.0205',
    city: 'North Vancouver Inner East',
    state: 'BC',
    country: 'Canada',
  },
  V7J: {
    zip: 'V7J',
    latitude: '49.3016',
    longitude: '-123.0309',
    city: 'North Vancouver East Central',
    state: 'BC',
    country: 'Canada',
  },
  V7K: {
    zip: 'V7K',
    latitude: '49.3322',
    longitude: '-123.0518',
    city: 'North Vancouver North Central',
    state: 'BC',
    country: 'Canada',
  },
  V7L: {
    zip: 'V7L',
    latitude: '49.3042',
    longitude: '-123.0651',
    city: 'North Vancouver South Central',
    state: 'BC',
    country: 'Canada',
  },
  V7M: {
    zip: 'V7M',
    latitude: '49.3111',
    longitude: '-123.0798',
    city: 'North Vancouver Southwest Central',
    state: 'BC',
    country: 'Canada',
  },
  V7N: {
    zip: 'V7N',
    latitude: '49.3325',
    longitude: '-123.0674',
    city: 'North Vancouver Northwest Central',
    state: 'BC',
    country: 'Canada',
  },
  V7P: {
    zip: 'V7P',
    latitude: '49.3181',
    longitude: '-123.096',
    city: 'North Vancouver Southwest',
    state: 'BC',
    country: 'Canada',
  },
  V7R: {
    zip: 'V7R',
    latitude: '49.3328',
    longitude: '-123.1043',
    city: 'North Vancouver Northwest',
    state: 'BC',
    country: 'Canada',
  },
  V7S: {
    zip: 'V7S',
    latitude: '49.3585',
    longitude: '-123.1186',
    city: 'West Vancouver North',
    state: 'BC',
    country: 'Canada',
  },
  V7T: {
    zip: 'V7T',
    latitude: '49.324',
    longitude: '-123.1036',
    city: 'West Vancouver Southeast',
    state: 'BC',
    country: 'Canada',
  },
  V7V: {
    zip: 'V7V',
    latitude: '49.3271',
    longitude: '-123.1578',
    city: 'West Vancouver South',
    state: 'BC',
    country: 'Canada',
  },
  V7W: {
    zip: 'V7W',
    latitude: '49.3465',
    longitude: '-123.238',
    city: 'West Vancouver West',
    state: 'BC',
    country: 'Canada',
  },
  V7X: {
    zip: 'V7X',
    latitude: '49.2935',
    longitude: '-123.1162',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V7Y: {
    zip: 'V7Y',
    latitude: '49.2816',
    longitude: '-123.1247',
    city: 'Vancouver',
    state: 'BC',
    country: 'Canada',
  },
  V8A: {
    zip: 'V8A',
    latitude: '49.8021',
    longitude: '-124.5124',
    city: 'Powell River',
    state: 'BC',
    country: 'Canada',
  },
  V8B: {
    zip: 'V8B',
    latitude: '49.7497',
    longitude: '-123.136',
    city: 'Squamish',
    state: 'BC',
    country: 'Canada',
  },
  V8C: {
    zip: 'V8C',
    latitude: '54.0662',
    longitude: '-128.6508',
    city: 'Kitimat',
    state: 'BC',
    country: 'Canada',
  },
  V8G: {
    zip: 'V8G',
    latitude: '54.5058',
    longitude: '-128.5823',
    city: 'Terrace',
    state: 'BC',
    country: 'Canada',
  },
  V8J: {
    zip: 'V8J',
    latitude: '54.3146',
    longitude: '-130.3413',
    city: 'Prince Rupert',
    state: 'BC',
    country: 'Canada',
  },
  V8K: {
    zip: 'V8K',
    latitude: '48.9145',
    longitude: '-123.5657',
    city: 'Saltspring Island',
    state: 'BC',
    country: 'Canada',
  },
  V8L: {
    zip: 'V8L',
    latitude: '48.6128',
    longitude: '-123.4198',
    city: 'Sidney',
    state: 'BC',
    country: 'Canada',
  },
  V8M: {
    zip: 'V8M',
    latitude: '48.566',
    longitude: '-123.4579',
    city: 'Central Saanich',
    state: 'BC',
    country: 'Canada',
  },
  V8N: {
    zip: 'V8N',
    latitude: '48.471',
    longitude: '-123.3438',
    city: 'Saanich East',
    state: 'BC',
    country: 'Canada',
  },
  V8P: {
    zip: 'V8P',
    latitude: '48.4458',
    longitude: '-123.3328',
    city: 'Saanich Southeast',
    state: 'BC',
    country: 'Canada',
  },
  V8R: {
    zip: 'V8R',
    latitude: '48.4266',
    longitude: '-123.3444',
    city: 'Oak Bay North',
    state: 'BC',
    country: 'Canada',
  },
  V8S: {
    zip: 'V8S',
    latitude: '48.4061',
    longitude: '-123.3504',
    city: 'Oak Bay South',
    state: 'BC',
    country: 'Canada',
  },
  V8T: {
    zip: 'V8T',
    latitude: '48.4278',
    longitude: '-123.3574',
    city: 'Victoria North',
    state: 'BC',
    country: 'Canada',
  },
  V8V: {
    zip: 'V8V',
    latitude: '48.4192',
    longitude: '-123.3856',
    city: 'Victoria South',
    state: 'BC',
    country: 'Canada',
  },
  V8W: {
    zip: 'V8W',
    latitude: '48.4202',
    longitude: '-123.3671',
    city: 'Victoria Central British Columbia Provincial Government',
    state: 'BC',
    country: 'Canada',
  },
  V8X: {
    zip: 'V8X',
    latitude: '48.4488',
    longitude: '-123.3501',
    city: 'Saanich South',
    state: 'BC',
    country: 'Canada',
  },
  V8Y: {
    zip: 'V8Y',
    latitude: '48.501',
    longitude: '-123.3804',
    city: 'Saanich North',
    state: 'BC',
    country: 'Canada',
  },
  V8Z: {
    zip: 'V8Z',
    latitude: '48.4449',
    longitude: '-123.3745',
    city: 'Saanich Central',
    state: 'BC',
    country: 'Canada',
  },
  V9A: {
    zip: 'V9A',
    latitude: '48.449',
    longitude: '-123.3842',
    city: 'Esquimalt',
    state: 'BC',
    country: 'Canada',
  },
  V9B: {
    zip: 'V9B',
    latitude: '48.4519',
    longitude: '-123.4417',
    city: 'Highlands',
    state: 'BC',
    country: 'Canada',
  },
  V9C: {
    zip: 'V9C',
    latitude: '48.4544',
    longitude: '-123.458',
    city: 'Metchosin',
    state: 'BC',
    country: 'Canada',
  },
  V9E: {
    zip: 'V9E',
    latitude: '48.4633',
    longitude: '-123.4538',
    city: 'Saanich West',
    state: 'BC',
    country: 'Canada',
  },
  V9G: {
    zip: 'V9G',
    latitude: '50.089',
    longitude: '-125.3444',
    city: 'Ladysmith',
    state: 'BC',
    country: 'Canada',
  },
  V9H: {
    zip: 'V9H',
    latitude: '49.9164',
    longitude: '-125.1875',
    city: 'Campbell River Outskirts',
    state: 'BC',
    country: 'Canada',
  },
  V9J: {
    zip: 'V9J',
    latitude: '49.8684',
    longitude: '-125.1252',
    city: 'Courtenay Northern Outskirts',
    state: 'BC',
    country: 'Canada',
  },
  V9K: {
    zip: 'V9K',
    latitude: '49.3506',
    longitude: '-124.409',
    city: 'Qualicum Beach',
    state: 'BC',
    country: 'Canada',
  },
  V9L: {
    zip: 'V9L',
    latitude: '48.7768',
    longitude: '-123.7077',
    city: 'Duncan',
    state: 'BC',
    country: 'Canada',
  },
  V9M: {
    zip: 'V9M',
    latitude: '49.6728',
    longitude: '-124.947',
    city: 'Comox',
    state: 'BC',
    country: 'Canada',
  },
  V9N: {
    zip: 'V9N',
    latitude: '49.686',
    longitude: '-125.0191',
    city: 'Courtenay Central',
    state: 'BC',
    country: 'Canada',
  },
  V9P: {
    zip: 'V9P',
    latitude: '49.3233',
    longitude: '-124.3227',
    city: 'Parksville',
    state: 'BC',
    country: 'Canada',
  },
  V9R: {
    zip: 'V9R',
    latitude: '49.136',
    longitude: '-123.9483',
    city: 'Nanaimo South',
    state: 'BC',
    country: 'Canada',
  },
  V9S: {
    zip: 'V9S',
    latitude: '49.174',
    longitude: '-123.9422',
    city: 'Nanaimo Central',
    state: 'BC',
    country: 'Canada',
  },
  V9T: {
    zip: 'V9T',
    latitude: '49.2079',
    longitude: '-123.979',
    city: 'Nanaimo North',
    state: 'BC',
    country: 'Canada',
  },
  V9V: {
    zip: 'V9V',
    latitude: '49.2477',
    longitude: '-124.0501',
    city: 'Nanaimo Northwest',
    state: 'BC',
    country: 'Canada',
  },
  V9W: {
    zip: 'V9W',
    latitude: '50.0059',
    longitude: '-125.2343',
    city: 'Campbell River Central',
    state: 'BC',
    country: 'Canada',
  },
  V9X: {
    zip: 'V9X',
    latitude: '49.1207',
    longitude: '-123.9284',
    city: 'Cedar',
    state: 'BC',
    country: 'Canada',
  },
  V9Y: {
    zip: 'V9Y',
    latitude: '49.2197',
    longitude: '-124.8101',
    city: 'Port Alberni',
    state: 'BC',
    country: 'Canada',
  },
  R0A: {
    zip: 'R0A',
    latitude: '49.0563',
    longitude: '-96.1126',
    city: 'Southeastern Manitoba',
    state: 'MB',
    country: 'Canada',
  },
  R0B: {
    zip: 'R0B',
    latitude: '55.8244',
    longitude: '-98.8348',
    city: 'Northern Manitoba',
    state: 'MB',
    country: 'Canada',
  },
  R0C: {
    zip: 'R0C',
    latitude: '50.7011',
    longitude: '-97.1462',
    city: 'North Interlake',
    state: 'MB',
    country: 'Canada',
  },
  R0E: {
    zip: 'R0E',
    latitude: '50.4275',
    longitude: '-95.3439',
    city: 'Eastern Manitoba',
    state: 'MB',
    country: 'Canada',
  },
  R0G: {
    zip: 'R0G',
    latitude: '49.0698',
    longitude: '-98.7619',
    city: 'South Central Manitoba',
    state: 'MB',
    country: 'Canada',
  },
  R0H: {
    zip: 'R0H',
    latitude: '49.7223',
    longitude: '-99.0009',
    city: 'South Interlake',
    state: 'MB',
    country: 'Canada',
  },
  R0J: {
    zip: 'R0J',
    latitude: '50.7774',
    longitude: '-99.5546',
    city: 'Riding Mountain',
    state: 'MB',
    country: 'Canada',
  },
  R0K: {
    zip: 'R0K',
    latitude: '49.0694',
    longitude: '-99.527',
    city: 'Brandon Region',
    state: 'MB',
    country: 'Canada',
  },
  R0L: {
    zip: 'R0L',
    latitude: '52.4175',
    longitude: '-100.9577',
    city: 'Western Manitoba',
    state: 'MB',
    country: 'Canada',
  },
  R0M: {
    zip: 'R0M',
    latitude: '50.0226',
    longitude: '-101.3637',
    city: 'Southwestern Manitoba',
    state: 'MB',
    country: 'Canada',
  },
  R1A: {
    zip: 'R1A',
    latitude: '50.1483',
    longitude: '-96.8756',
    city: 'Selkirk',
    state: 'MB',
    country: 'Canada',
  },
  R1B: {
    zip: 'R1B',
    latitude: '50.0958',
    longitude: '-96.9329',
    city: 'Lockport',
    state: 'MB',
    country: 'Canada',
  },
  R1N: {
    zip: 'R1N',
    latitude: '49.9694',
    longitude: '-98.3131',
    city: 'Portage La Prairie',
    state: 'MB',
    country: 'Canada',
  },
  R2C: {
    zip: 'R2C',
    latitude: '49.9069',
    longitude: '-97.0011',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R2E: {
    zip: 'R2E',
    latitude: '49.9611',
    longitude: '-97.0212',
    city: 'East St. Paul',
    state: 'MB',
    country: 'Canada',
  },
  R2G: {
    zip: 'R2G',
    latitude: '49.9465',
    longitude: '-97.0585',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R2H: {
    zip: 'R2H',
    latitude: '49.8792',
    longitude: '-97.1062',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R2J: {
    zip: 'R2J',
    latitude: '49.8717',
    longitude: '-97.0765',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R2K: {
    zip: 'R2K',
    latitude: '49.9225',
    longitude: '-97.0947',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R2L: {
    zip: 'R2L',
    latitude: '49.9069',
    longitude: '-97.0845',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R2M: {
    zip: 'R2M',
    latitude: '49.853',
    longitude: '-97.0998',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R2N: {
    zip: 'R2N',
    latitude: '49.819',
    longitude: '-97.0926',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R2P: {
    zip: 'R2P',
    latitude: '49.9585',
    longitude: '-97.1796',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R2R: {
    zip: 'R2R',
    latitude: '49.9324',
    longitude: '-97.1988',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R2V: {
    zip: 'R2V',
    latitude: '49.9378',
    longitude: '-97.1183',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R2W: {
    zip: 'R2W',
    latitude: '49.9241',
    longitude: '-97.1292',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R2X: {
    zip: 'R2X',
    latitude: '49.928',
    longitude: '-97.1618',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R2Y: {
    zip: 'R2Y',
    latitude: '49.8963',
    longitude: '-97.297',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3A: {
    zip: 'R3A',
    latitude: '49.9004',
    longitude: '-97.1457',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3B: {
    zip: 'R3B',
    latitude: '49.8972',
    longitude: '-97.1366',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3C: {
    zip: 'R3C',
    latitude: '49.8788',
    longitude: '-97.159',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3E: {
    zip: 'R3E',
    latitude: '49.9139',
    longitude: '-97.1847',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3G: {
    zip: 'R3G',
    latitude: '49.8826',
    longitude: '-97.1623',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3H: {
    zip: 'R3H',
    latitude: '49.8971',
    longitude: '-97.2163',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3J: {
    zip: 'R3J',
    latitude: '49.8858',
    longitude: '-97.2601',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3K: {
    zip: 'R3K',
    latitude: '49.8811',
    longitude: '-97.3194',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3L: {
    zip: 'R3L',
    latitude: '49.8671',
    longitude: '-97.1225',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3M: {
    zip: 'R3M',
    latitude: '49.8663',
    longitude: '-97.1639',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3N: {
    zip: 'R3N',
    latitude: '49.8722',
    longitude: '-97.1888',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3P: {
    zip: 'R3P',
    latitude: '49.834',
    longitude: '-97.1865',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3R: {
    zip: 'R3R',
    latitude: '49.854',
    longitude: '-97.2712',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3S: {
    zip: 'R3S',
    latitude: '49.842',
    longitude: '-97.3083',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3T: {
    zip: 'R3T',
    latitude: '49.849',
    longitude: '-97.1497',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3V: {
    zip: 'R3V',
    latitude: '49.7732',
    longitude: '-97.1561',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3W: {
    zip: 'R3W',
    latitude: '49.8968',
    longitude: '-97.0279',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3X: {
    zip: 'R3X',
    latitude: '49.8378',
    longitude: '-97.0675',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R3Y: {
    zip: 'R3Y',
    latitude: '49.8275',
    longitude: '-97.183',
    city: 'Winnipeg',
    state: 'MB',
    country: 'Canada',
  },
  R4A: {
    zip: 'R4A',
    latitude: '49.977',
    longitude: '-97.0633',
    city: 'West St. Paul',
    state: 'MB',
    country: 'Canada',
  },
  R4H: {
    zip: 'R4H',
    latitude: '49.8628',
    longitude: '-97.3348',
    city: 'Headingley East',
    state: 'MB',
    country: 'Canada',
  },
  R4J: {
    zip: 'R4J',
    latitude: '49.8987',
    longitude: '-97.3843',
    city: 'Headingley West',
    state: 'MB',
    country: 'Canada',
  },
  R4K: {
    zip: 'R4K',
    latitude: '49.8298',
    longitude: '-97.7549',
    city: 'Cartier',
    state: 'MB',
    country: 'Canada',
  },
  R4L: {
    zip: 'R4L',
    latitude: '49.8943',
    longitude: '-97.5178',
    city: 'St. Francois Xavier',
    state: 'MB',
    country: 'Canada',
  },
  R5A: {
    zip: 'R5A',
    latitude: '49.7082',
    longitude: '-96.9867',
    city: 'St. Adolphe',
    state: 'MB',
    country: 'Canada',
  },
  R5G: {
    zip: 'R5G',
    latitude: '49.5264',
    longitude: '-96.6867',
    city: 'Steinbach',
    state: 'MB',
    country: 'Canada',
  },
  R5H: {
    zip: 'R5H',
    latitude: '49.6667',
    longitude: '-96.648',
    city: 'Ste. Anne',
    state: 'MB',
    country: 'Canada',
  },
  R6M: {
    zip: 'R6M',
    latitude: '49.1861',
    longitude: '-98.1204',
    city: 'Morden',
    state: 'MB',
    country: 'Canada',
  },
  R6W: {
    zip: 'R6W',
    latitude: '49.1859',
    longitude: '-97.9396',
    city: 'Winkler',
    state: 'MB',
    country: 'Canada',
  },
  R7A: {
    zip: 'R7A',
    latitude: '49.8431',
    longitude: '-99.9452',
    city: 'Brandon Southeast',
    state: 'MB',
    country: 'Canada',
  },
  R7B: {
    zip: 'R7B',
    latitude: '49.8373',
    longitude: '-99.9747',
    city: 'Brandon Southwest',
    state: 'MB',
    country: 'Canada',
  },
  R7C: {
    zip: 'R7C',
    latitude: '49.8688',
    longitude: '-99.9684',
    city: 'Brandon North',
    state: 'MB',
    country: 'Canada',
  },
  R7N: {
    zip: 'R7N',
    latitude: '51.1465',
    longitude: '-100.0421',
    city: 'Dauphin',
    state: 'MB',
    country: 'Canada',
  },
  R8A: {
    zip: 'R8A',
    latitude: '54.76',
    longitude: '-101.8704',
    city: 'Flin Flon',
    state: 'MB',
    country: 'Canada',
  },
  R8N: {
    zip: 'R8N',
    latitude: '55.7428',
    longitude: '-97.8779',
    city: 'Thompson',
    state: 'MB',
    country: 'Canada',
  },
  R9A: {
    zip: 'R9A',
    latitude: '53.8228',
    longitude: '-101.2356',
    city: 'The Pas',
    state: 'MB',
    country: 'Canada',
  },
  E1A: {
    zip: 'E1A',
    latitude: '46.0625',
    longitude: '-64.7105',
    city: 'Dieppe Moncton East',
    state: 'NB',
    country: 'Canada',
  },
  E1B: {
    zip: 'E1B',
    latitude: '46.0738',
    longitude: '-64.755',
    city: 'Riverview',
    state: 'NB',
    country: 'Canada',
  },
  E1C: {
    zip: 'E1C',
    latitude: '46.0888',
    longitude: '-64.7723',
    city: 'Moncton Central',
    state: 'NB',
    country: 'Canada',
  },
  E1E: {
    zip: 'E1E',
    latitude: '46.0599',
    longitude: '-64.844',
    city: 'Moncton West',
    state: 'NB',
    country: 'Canada',
  },
  E1G: {
    zip: 'E1G',
    latitude: '46.1117',
    longitude: '-64.834',
    city: 'Moncton Northwest',
    state: 'NB',
    country: 'Canada',
  },
  E1H: {
    zip: 'E1H',
    latitude: 'null',
    longitude: '46.1506',
    city: 'Lakeville',
    state: 'Shediac Bridge',
    country: 'Canada',
  },
  E1J: {
    zip: 'E1J',
    latitude: '45.9829',
    longitude: '-64.8634',
    city: 'Coverdale',
    state: 'NB',
    country: 'Canada',
  },
  E1N: {
    zip: 'E1N',
    latitude: '47.0155',
    longitude: '-65.5071',
    city: 'Miramichi South',
    state: 'NB',
    country: 'Canada',
  },
  E1V: {
    zip: 'E1V',
    latitude: '47.0085',
    longitude: '-65.5833',
    city: 'Miramichi North',
    state: 'NB',
    country: 'Canada',
  },
  E1W: {
    zip: 'E1W',
    latitude: '47.7624',
    longitude: '-65.0324',
    city: 'Caraquet',
    state: 'NB',
    country: 'Canada',
  },
  E1X: {
    zip: 'E1X',
    latitude: '47.4883',
    longitude: '-64.9189',
    city: 'Tracadie-sheila',
    state: 'NB',
    country: 'Canada',
  },
  E2A: {
    zip: 'E2A',
    latitude: '47.6605',
    longitude: '-65.6414',
    city: 'Bathurst',
    state: 'NB',
    country: 'Canada',
  },
  E2E: {
    zip: 'E2E',
    latitude: 'null',
    longitude: '45.4165',
    city: 'Rothesay',
    state: 'Quispamsis',
    country: 'Canada',
  },
  E2G: {
    zip: 'E2G',
    latitude: '45.4397',
    longitude: '-65.9392',
    city: 'Quispamsis',
    state: 'NB',
    country: 'Canada',
  },
  E2H: {
    zip: 'E2H',
    latitude: 'null',
    longitude: '45.3481',
    city: 'Saint John Northeast',
    state: 'Renforth',
    country: 'Canada',
  },
  E2J: {
    zip: 'E2J',
    latitude: '45.286',
    longitude: '-66.0421',
    city: 'Saint John East',
    state: 'NB',
    country: 'Canada',
  },
  E2K: {
    zip: 'E2K',
    latitude: '45.2746',
    longitude: '-66.0871',
    city: 'Saint John North',
    state: 'NB',
    country: 'Canada',
  },
  E2L: {
    zip: 'E2L',
    latitude: '45.2742',
    longitude: '-66.0645',
    city: 'Saint John Central',
    state: 'NB',
    country: 'Canada',
  },
  E2M: {
    zip: 'E2M',
    latitude: '45.2758',
    longitude: '-66.0845',
    city: 'Saint John West',
    state: 'NB',
    country: 'Canada',
  },
  E2N: {
    zip: 'E2N',
    latitude: '45.3151',
    longitude: '-65.9615',
    city: 'Saint John Lakewood',
    state: 'NB',
    country: 'Canada',
  },
  E2P: {
    zip: 'E2P',
    latitude: '45.2488',
    longitude: '-66.0025',
    city: 'Saint John Red Head',
    state: 'NB',
    country: 'Canada',
  },
  E2R: {
    zip: 'E2R',
    latitude: '45.2735',
    longitude: '-66.0099',
    city: 'Saint John Grandview',
    state: 'NB',
    country: 'Canada',
  },
  E2S: {
    zip: 'E2S',
    latitude: '45.3679',
    longitude: '-65.9564',
    city: 'Saint John Loch Lomond',
    state: 'NB',
    country: 'Canada',
  },
  E2V: {
    zip: 'E2V',
    latitude: '45.8509',
    longitude: '-66.467',
    city: 'Oromocto',
    state: 'NB',
    country: 'Canada',
  },
  E3A: {
    zip: 'E3A',
    latitude: '45.9784',
    longitude: '-66.6905',
    city: 'Fredericton North',
    state: 'NB',
    country: 'Canada',
  },
  E3B: {
    zip: 'E3B',
    latitude: '45.9535',
    longitude: '-66.6704',
    city: 'Fredericton South New Brunswick Provincial Government',
    state: 'NB',
    country: 'Canada',
  },
  E3C: {
    zip: 'E3C',
    latitude: 'null',
    longitude: '45.9356',
    city: 'Fredericton Southwest',
    state: 'New Maryland',
    country: 'Canada',
  },
  E3E: {
    zip: 'E3E',
    latitude: '45.8134',
    longitude: '-66.932',
    city: 'Kingsclear',
    state: 'NB',
    country: 'Canada',
  },
  E3L: {
    zip: 'E3L',
    latitude: '45.1728',
    longitude: '-67.2946',
    city: 'St. Stephen',
    state: 'NB',
    country: 'Canada',
  },
  E3N: {
    zip: 'E3N',
    latitude: '48.0091',
    longitude: '-66.6707',
    city: 'Campbellton',
    state: 'NB',
    country: 'Canada',
  },
  E3V: {
    zip: 'E3V',
    latitude: '47.3614',
    longitude: '-68.3218',
    city: 'Edmundston',
    state: 'NB',
    country: 'Canada',
  },
  E3Y: {
    zip: 'E3Y',
    latitude: '47.052',
    longitude: '-67.7368',
    city: 'Grand Falls Northeast',
    state: 'NB',
    country: 'Canada',
  },
  E3Z: {
    zip: 'E3Z',
    latitude: '47.0471',
    longitude: '-67.7527',
    city: 'Grand Falls Central',
    state: 'NB',
    country: 'Canada',
  },
  E4A: {
    zip: 'E4A',
    latitude: '46.1655',
    longitude: '-65.872',
    city: 'Bathurst',
    state: 'NB',
    country: 'Canada',
  },
  E4B: {
    zip: 'E4B',
    latitude: '45.9393',
    longitude: '-66.09',
    city: 'Minto',
    state: 'NB',
    country: 'Canada',
  },
  E4C: {
    zip: 'E4C',
    latitude: '45.808',
    longitude: '-65.9652',
    city: 'Youngs Cove',
    state: 'NB',
    country: 'Canada',
  },
  E4E: {
    zip: 'E4E',
    latitude: '45.7223',
    longitude: '-65.5108',
    city: 'Sussex',
    state: 'NB',
    country: 'Canada',
  },
  E4G: {
    zip: 'E4G',
    latitude: '45.9078',
    longitude: '-65.5334',
    city: 'Smiths Creek',
    state: 'NB',
    country: 'Canada',
  },
  E4H: {
    zip: 'E4H',
    latitude: '45.9078',
    longitude: '-64.8245',
    city: 'Hillsborough',
    state: 'NB',
    country: 'Canada',
  },
  E4J: {
    zip: 'E4J',
    latitude: '45.9787',
    longitude: '-64.9898',
    city: 'Salisbury',
    state: 'NB',
    country: 'Canada',
  },
  E4K: {
    zip: 'E4K',
    latitude: '46.0477',
    longitude: '-64.6202',
    city: 'Dorchester',
    state: 'NB',
    country: 'Canada',
  },
  E4L: {
    zip: 'E4L',
    latitude: '45.8919',
    longitude: '-64.3699',
    city: 'Sackville',
    state: 'NB',
    country: 'Canada',
  },
  E4M: {
    zip: 'E4M',
    latitude: '46.0957',
    longitude: '-63.9068',
    city: 'Bayfield',
    state: 'NB',
    country: 'Canada',
  },
  E4N: {
    zip: 'E4N',
    latitude: '46.2313',
    longitude: '-64.2615',
    city: 'Cap-pelé',
    state: 'NB',
    country: 'Canada',
  },
  E4P: {
    zip: 'E4P',
    latitude: '46.2165',
    longitude: '-64.5128',
    city: 'Shediac',
    state: 'NB',
    country: 'Canada',
  },
  E4R: {
    zip: 'E4R',
    latitude: '46.2324',
    longitude: '-64.785',
    city: 'Cocagne',
    state: 'NB',
    country: 'Canada',
  },
  E4S: {
    zip: 'E4S',
    latitude: '46.4171',
    longitude: '-64.9241',
    city: 'Bouctouche',
    state: 'NB',
    country: 'Canada',
  },
  E4T: {
    zip: 'E4T',
    latitude: '46.3026',
    longitude: '-64.9648',
    city: 'Bass River',
    state: 'NB',
    country: 'Canada',
  },
  E4V: {
    zip: 'E4V',
    latitude: '46.3131',
    longitude: '-64.5853',
    city: 'Saint-antoine',
    state: 'NB',
    country: 'Canada',
  },
  E4W: {
    zip: 'E4W',
    latitude: '46.6493',
    longitude: '-64.8842',
    city: 'Richibucto',
    state: 'NB',
    country: 'Canada',
  },
  E4X: {
    zip: 'E4X',
    latitude: '46.735',
    longitude: '-64.9744',
    city: 'St-louis-de-kent',
    state: 'NB',
    country: 'Canada',
  },
  E4Y: {
    zip: 'E4Y',
    latitude: '46.7333',
    longitude: '-65.4489',
    city: 'Rogersville',
    state: 'NB',
    country: 'Canada',
  },
  E4Z: {
    zip: 'E4Z',
    latitude: '45.751',
    longitude: '-65.048',
    city: 'Petitcodiac',
    state: 'NB',
    country: 'Canada',
  },
  E5A: {
    zip: 'E5A',
    latitude: '45.2441',
    longitude: '-66.9929',
    city: 'Moores Mills',
    state: 'NB',
    country: 'Canada',
  },
  E5B: {
    zip: 'E5B',
    latitude: '45.0732',
    longitude: '-67.0428',
    city: 'St. Andrews',
    state: 'NB',
    country: 'Canada',
  },
  E5C: {
    zip: 'E5C',
    latitude: '45.2441',
    longitude: '-66.9929',
    city: 'St. George',
    state: 'NB',
    country: 'Canada',
  },
  E5E: {
    zip: 'E5E',
    latitude: '44.887',
    longitude: '-66.95',
    city: 'Campobello Island',
    state: 'NB',
    country: 'Canada',
  },
  E5G: {
    zip: 'E5G',
    latitude: '44.6586',
    longitude: '-66.8625',
    city: 'Grand Manan Island',
    state: 'NB',
    country: 'Canada',
  },
  E5H: {
    zip: 'E5H',
    latitude: '45.0766',
    longitude: '-66.77',
    city: 'Pennfield',
    state: 'NB',
    country: 'Canada',
  },
  E5J: {
    zip: 'E5J',
    latitude: '45.2116',
    longitude: '-66.3491',
    city: 'Lepreau',
    state: 'NB',
    country: 'Canada',
  },
  E5K: {
    zip: 'E5K',
    latitude: '45.331',
    longitude: '-66.2095',
    city: 'Grand Bay-westfield',
    state: 'NB',
    country: 'Canada',
  },
  E5L: {
    zip: 'E5L',
    latitude: '45.5281',
    longitude: '-66.511',
    city: 'Fredericton Junction',
    state: 'NB',
    country: 'Canada',
  },
  E5M: {
    zip: 'E5M',
    latitude: '45.6287',
    longitude: '-66.1751',
    city: 'Gagetown',
    state: 'NB',
    country: 'Canada',
  },
  E5N: {
    zip: 'E5N',
    latitude: '45.5263',
    longitude: '-65.8155',
    city: 'Hampton',
    state: 'NB',
    country: 'Canada',
  },
  E5P: {
    zip: 'E5P',
    latitude: '45.8489',
    longitude: '-65.788',
    city: 'Apohaqui',
    state: 'NB',
    country: 'Canada',
  },
  E5R: {
    zip: 'E5R',
    latitude: '45.3849',
    longitude: '-65.6331',
    city: 'St. Martins',
    state: 'NB',
    country: 'Canada',
  },
  E5S: {
    zip: 'E5S',
    latitude: '45.3571',
    longitude: '-66.0858',
    city: 'Kingston',
    state: 'NB',
    country: 'Canada',
  },
  E5T: {
    zip: 'E5T',
    latitude: '45.6769',
    longitude: '-65.884',
    city: 'Norton',
    state: 'NB',
    country: 'Canada',
  },
  E5V: {
    zip: 'E5V',
    latitude: '45.0481',
    longitude: '-66.9556',
    city: 'Deer Island',
    state: 'NB',
    country: 'Canada',
  },
  E6A: {
    zip: 'E6A',
    latitude: '46.2767',
    longitude: '-66.7384',
    city: 'Boiestown',
    state: 'NB',
    country: 'Canada',
  },
  E6B: {
    zip: 'E6B',
    latitude: '46.2324',
    longitude: '-66.6683',
    city: 'Stanley',
    state: 'NB',
    country: 'Canada',
  },
  E6C: {
    zip: 'E6C',
    latitude: '45.9523',
    longitude: '-66.6717',
    city: 'Durham Bridge',
    state: 'NB',
    country: 'Canada',
  },
  E6E: {
    zip: 'E6E',
    latitude: '46.1296',
    longitude: '-67.1953',
    city: 'Millville',
    state: 'NB',
    country: 'Canada',
  },
  E6G: {
    zip: 'E6G',
    latitude: '45.9942',
    longitude: '-67.2397',
    city: 'Nackawic',
    state: 'NB',
    country: 'Canada',
  },
  E6H: {
    zip: 'E6H',
    latitude: '45.7207',
    longitude: '-67.6516',
    city: 'Canterbury',
    state: 'NB',
    country: 'Canada',
  },
  E6J: {
    zip: 'E6J',
    latitude: '45.5927',
    longitude: '-67.2973',
    city: 'Mcadam',
    state: 'NB',
    country: 'Canada',
  },
  E6K: {
    zip: 'E6K',
    latitude: '45.6975',
    longitude: '-66.9557',
    city: 'Harvey',
    state: 'NB',
    country: 'Canada',
  },
  E6L: {
    zip: 'E6L',
    latitude: '46.12',
    longitude: '-66.9477',
    city: 'Burtts Corner',
    state: 'NB',
    country: 'Canada',
  },
  E7A: {
    zip: 'E7A',
    latitude: '47.2542',
    longitude: '-68.7211',
    city: 'Baker Brook',
    state: 'NB',
    country: 'Canada',
  },
  E7B: {
    zip: 'E7B',
    latitude: '47.4785',
    longitude: '-68.415',
    city: 'Saint-jacques',
    state: 'NB',
    country: 'Canada',
  },
  E7C: {
    zip: 'E7C',
    latitude: '47.3516',
    longitude: '-68.2208',
    city: 'Saint-basile',
    state: 'NB',
    country: 'Canada',
  },
  E7E: {
    zip: 'E7E',
    latitude: '47.1717',
    longitude: '-67.925',
    city: 'Saint-leonard',
    state: 'NB',
    country: 'Canada',
  },
  E7G: {
    zip: 'E7G',
    latitude: '46.9097',
    longitude: '-67.3971',
    city: 'Plaster Rock',
    state: 'NB',
    country: 'Canada',
  },
  E7H: {
    zip: 'E7H',
    latitude: '46.7284',
    longitude: '-67.7057',
    city: 'Perth-andover',
    state: 'NB',
    country: 'Canada',
  },
  E7J: {
    zip: 'E7J',
    latitude: '46.5082',
    longitude: '-67.5871',
    city: 'Bath',
    state: 'NB',
    country: 'Canada',
  },
  E7K: {
    zip: 'E7K',
    latitude: '46.4328',
    longitude: '-67.7105',
    city: 'Centreville',
    state: 'NB',
    country: 'Canada',
  },
  E7L: {
    zip: 'E7L',
    latitude: '46.4418',
    longitude: '-67.63',
    city: 'Florenceville',
    state: 'NB',
    country: 'Canada',
  },
  E7M: {
    zip: 'E7M',
    latitude: '46.1368',
    longitude: '-67.5817',
    city: 'Woodstock',
    state: 'NB',
    country: 'Canada',
  },
  E7N: {
    zip: 'E7N',
    latitude: '46.0089',
    longitude: '-67.7236',
    city: 'Debec',
    state: 'NB',
    country: 'Canada',
  },
  E7P: {
    zip: 'E7P',
    latitude: '46.3709',
    longitude: '-67.445',
    city: 'Hartland',
    state: 'NB',
    country: 'Canada',
  },
  E8A: {
    zip: 'E8A',
    latitude: '47.5021',
    longitude: '-67.3897',
    city: 'Saint-quentin',
    state: 'NB',
    country: 'Canada',
  },
  E8B: {
    zip: 'E8B',
    latitude: '47.6454',
    longitude: '-67.3437',
    city: 'Kedgwick',
    state: 'NB',
    country: 'Canada',
  },
  E8C: {
    zip: 'E8C',
    latitude: '48.0477',
    longitude: '-66.4004',
    city: 'Dalhousie',
    state: 'NB',
    country: 'Canada',
  },
  E8E: {
    zip: 'E8E',
    latitude: '47.9879',
    longitude: '-66.5145',
    city: 'Balmoral',
    state: 'NB',
    country: 'Canada',
  },
  E8G: {
    zip: 'E8G',
    latitude: '47.8741',
    longitude: '-65.9102',
    city: 'Belledune',
    state: 'NB',
    country: 'Canada',
  },
  E8J: {
    zip: 'E8J',
    latitude: '47.7634',
    longitude: '-65.8276',
    city: 'Petit-rocher',
    state: 'NB',
    country: 'Canada',
  },
  E8K: {
    zip: 'E8K',
    latitude: '47.6736',
    longitude: '-65.6795',
    city: 'Beresford',
    state: 'NB',
    country: 'Canada',
  },
  E8L: {
    zip: 'E8L',
    latitude: '47.5887',
    longitude: '-65.0979',
    city: 'Allardville',
    state: 'NB',
    country: 'Canada',
  },
  E8M: {
    zip: 'E8M',
    latitude: '47.8022',
    longitude: '-65.1862',
    city: 'Saint-isidore',
    state: 'NB',
    country: 'Canada',
  },
  E8N: {
    zip: 'E8N',
    latitude: '47.8219',
    longitude: '-65.0917',
    city: 'Grande-anse',
    state: 'NB',
    country: 'Canada',
  },
  E8P: {
    zip: 'E8P',
    latitude: '47.6656',
    longitude: '-64.9543',
    city: 'Inkerman',
    state: 'NB',
    country: 'Canada',
  },
  E8R: {
    zip: 'E8R',
    latitude: '47.7443',
    longitude: '-64.7222',
    city: 'Paquetville',
    state: 'NB',
    country: 'Canada',
  },
  E8S: {
    zip: 'E8S',
    latitude: '47.7456',
    longitude: '-64.7143',
    city: 'Shippagan',
    state: 'NB',
    country: 'Canada',
  },
  E8T: {
    zip: 'E8T',
    latitude: '47.792',
    longitude: '-64.652',
    city: 'Lamèque',
    state: 'NB',
    country: 'Canada',
  },
  E9A: {
    zip: 'E9A',
    latitude: '46.7385',
    longitude: '-65.8528',
    city: 'Baie-sainte-anne',
    state: 'NB',
    country: 'Canada',
  },
  E9B: {
    zip: 'E9B',
    latitude: '46.7772',
    longitude: '-65.8638',
    city: 'Blackville',
    state: 'NB',
    country: 'Canada',
  },
  E9C: {
    zip: 'E9C',
    latitude: '46.4477',
    longitude: '-66.2584',
    city: 'Doaktown',
    state: 'NB',
    country: 'Canada',
  },
  E9E: {
    zip: 'E9E',
    latitude: '46.9795',
    longitude: '-65.6715',
    city: 'Red Bank',
    state: 'NB',
    country: 'Canada',
  },
  E9G: {
    zip: 'E9G',
    latitude: '47.2316',
    longitude: '-65.1378',
    city: 'Neguac',
    state: 'NB',
    country: 'Canada',
  },
  E9H: {
    zip: 'E9H',
    latitude: '47.3272',
    longitude: '-65.011',
    city: 'Brantville',
    state: 'NB',
    country: 'Canada',
  },
  A0A: {
    zip: 'A0A',
    latitude: '47.0073',
    longitude: '-52.9589',
    city: 'Southeastern Avalon Peninsula',
    state: 'NL',
    country: 'Canada',
  },
  A0B: {
    zip: 'A0B',
    latitude: '47.7609',
    longitude: '-53.9834',
    city: 'Western Avalon Peninsula',
    state: 'NL',
    country: 'Canada',
  },
  A0C: {
    zip: 'A0C',
    latitude: '48.3464',
    longitude: '-53.9646',
    city: 'Bonavista Peninsula',
    state: 'NL',
    country: 'Canada',
  },
  A0E: {
    zip: 'A0E',
    latitude: '47.3597',
    longitude: '-54.8984',
    city: 'Burin Peninsula',
    state: 'NL',
    country: 'Canada',
  },
  A0G: {
    zip: 'A0G',
    latitude: '49.4536',
    longitude: '-54.1045',
    city: 'Northeast Newfoundland',
    state: 'NL',
    country: 'Canada',
  },
  A0H: {
    zip: 'A0H',
    latitude: '49.1301',
    longitude: '-56.0845',
    city: 'Central Newfoundland',
    state: 'NL',
    country: 'Canada',
  },
  A0J: {
    zip: 'A0J',
    latitude: '49.5959',
    longitude: '-55.6739',
    city: 'Northern Newfoundland',
    state: 'NL',
    country: 'Canada',
  },
  A0K: {
    zip: 'A0K',
    latitude: '51.2327',
    longitude: '-56.7969',
    city: "Northwest Newfoundland/eastern Labrador",
    state: 'NL',
    country: 'Canada',
  },
  A0L: {
    zip: 'A0L',
    latitude: '48.9934',
    longitude: '-58.1009',
    city: 'Western Newfoundland',
    state: 'NL',
    country: 'Canada',
  },
  A0M: {
    zip: 'A0M',
    latitude: '48.1816',
    longitude: '-58.858',
    city: 'Southwestern Newfoundland',
    state: 'NL',
    country: 'Canada',
  },
  A0N: {
    zip: 'A0N',
    latitude: '48.6113',
    longitude: '-58.8736',
    city: "Port Au Port Peninsula Region",
    state: 'NL',
    country: 'Canada',
  },
  A0P: {
    zip: 'A0P',
    latitude: '55.8889',
    longitude: '-60.8805',
    city: 'Central Labrador',
    state: 'NL',
    country: 'Canada',
  },
  A0R: {
    zip: 'A0R',
    latitude: '53.5329',
    longitude: '-64.0145',
    city: 'North/western Labrador',
    state: 'NL',
    country: 'Canada',
  },
  A1A: {
    zip: 'A1A',
    latitude: '47.571',
    longitude: '-52.6961',
    city: "St. John's North",
    state: 'NL',
    country: 'Canada',
  },
  A1B: {
    zip: 'A1B',
    latitude: '47.5736',
    longitude: '-52.7083',
    city: "St. John's Northwest Newfoundland & Labrador Provincial Government",
    state: 'NL',
    country: 'Canada',
  },
  A1C: {
    zip: 'A1C',
    latitude: '47.5677',
    longitude: '-52.7031',
    city: "St. John's North Central",
    state: 'NL',
    country: 'Canada',
  },
  A1E: {
    zip: 'A1E',
    latitude: '47.5507',
    longitude: '-52.7147',
    city: "St. John's Central",
    state: 'NL',
    country: 'Canada',
  },
  A1G: {
    zip: 'A1G',
    latitude: '47.5295',
    longitude: '-52.7417',
    city: "St. John's South",
    state: 'NL',
    country: 'Canada',
  },
  A1H: {
    zip: 'A1H',
    latitude: '47.4926',
    longitude: '-52.8123',
    city: "St. John's Southwest",
    state: 'NL',
    country: 'Canada',
  },
  A1K: {
    zip: 'A1K',
    latitude: '47.6542',
    longitude: '-52.7367',
    city: 'Torbay',
    state: 'NL',
    country: 'Canada',
  },
  A1L: {
    zip: 'A1L',
    latitude: '47.5363',
    longitude: '-52.8389',
    city: 'Paradise',
    state: 'NL',
    country: 'Canada',
  },
  A1M: {
    zip: 'A1M',
    latitude: '47.5982',
    longitude: '-52.8384',
    city: 'Portugal Cove-st. Philips',
    state: 'NL',
    country: 'Canada',
  },
  A1N: {
    zip: 'A1N',
    latitude: '47.5203',
    longitude: '-52.7789',
    city: 'Mount Pearl',
    state: 'NL',
    country: 'Canada',
  },
  A1S: {
    zip: 'A1S',
    latitude: '47.462',
    longitude: '-52.7895',
    city: 'Goulds',
    state: 'NL',
    country: 'Canada',
  },
  A1V: {
    zip: 'A1V',
    latitude: '48.9632',
    longitude: '-54.6169',
    city: 'Gander',
    state: 'NL',
    country: 'Canada',
  },
  A1W: {
    zip: 'A1W',
    latitude: '47.5329',
    longitude: '-52.9132',
    city: 'Manuels',
    state: 'NL',
    country: 'Canada',
  },
  A1X: {
    zip: 'A1X',
    latitude: '47.5238',
    longitude: '-52.9595',
    city: 'Conception Bay',
    state: 'NL',
    country: 'Canada',
  },
  A1Y: {
    zip: 'A1Y',
    latitude: '48.9268',
    longitude: '-55.6613',
    city: 'Carbonear',
    state: 'NL',
    country: 'Canada',
  },
  A2A: {
    zip: 'A2A',
    latitude: '48.9249',
    longitude: '-55.6493',
    city: 'Grand Falls',
    state: 'NL',
    country: 'Canada',
  },
  A2B: {
    zip: 'A2B',
    latitude: '48.949',
    longitude: '-55.6725',
    city: 'Windsor',
    state: 'NL',
    country: 'Canada',
  },
  A2H: {
    zip: 'A2H',
    latitude: '48.9654',
    longitude: '-57.9225',
    city: 'Corner Brook',
    state: 'NL',
    country: 'Canada',
  },
  A2N: {
    zip: 'A2N',
    latitude: '48.5656',
    longitude: '-58.6',
    city: 'Stephenville',
    state: 'NL',
    country: 'Canada',
  },
  A2V: {
    zip: 'A2V',
    latitude: '52.9348',
    longitude: '-66.9145',
    city: 'Labrador City',
    state: 'NL',
    country: 'Canada',
  },
  A5A: {
    zip: 'A5A',
    latitude: '48.1666',
    longitude: '-53.9628',
    city: 'Clarenville',
    state: 'NL',
    country: 'Canada',
  },
  A8A: {
    zip: 'A8A',
    latitude: '49.1778',
    longitude: '-57.413',
    city: 'Deer Lake',
    state: 'NL',
    country: 'Canada',
  },
  B0C: {
    zip: 'B0C',
    latitude: '46.2811',
    longitude: '-60.2825',
    city: 'North Victoria County',
    state: 'NS',
    country: 'Canada',
  },
  B0E: {
    zip: 'B0E',
    latitude: '45.5148',
    longitude: '-60.966',
    city: 'West Cape Breton Island',
    state: 'NS',
    country: 'Canada',
  },
  B0H: {
    zip: 'B0H',
    latitude: '45.6051',
    longitude: '-61.6975',
    city: 'Canso Region',
    state: 'NS',
    country: 'Canada',
  },
  B0J: {
    zip: 'B0J',
    latitude: '45.1458',
    longitude: '-61.8108',
    city: 'Mainland East Shore',
    state: 'NS',
    country: 'Canada',
  },
  B0K: {
    zip: 'B0K',
    latitude: '45.5808',
    longitude: '-62.1969',
    city: 'Southern Northumberland Strait',
    state: 'NS',
    country: 'Canada',
  },
  B0L: {
    zip: 'B0L',
    latitude: '45.5802',
    longitude: '-64.6646',
    city: 'Isthmus Of Chignecto',
    state: 'NS',
    country: 'Canada',
  },
  B0M: {
    zip: 'B0M',
    latitude: '45.3317',
    longitude: '-64.7596',
    city: 'Cobequid Bay North Shore',
    state: 'NS',
    country: 'Canada',
  },
  B0N: {
    zip: 'B0N',
    latitude: '44.8794',
    longitude: '-63.7254',
    city: 'Hants County',
    state: 'NS',
    country: 'Canada',
  },
  B0P: {
    zip: 'B0P',
    latitude: '45.0191',
    longitude: '-64.8882',
    city: 'Kings County',
    state: 'NS',
    country: 'Canada',
  },
  B0R: {
    zip: 'B0R',
    latitude: '44.7424',
    longitude: '-65.5111',
    city: 'West Lunenburg County',
    state: 'NS',
    country: 'Canada',
  },
  B0S: {
    zip: 'B0S',
    latitude: '44.6491',
    longitude: '-65.5472',
    city: 'West Annapolis County',
    state: 'NS',
    country: 'Canada',
  },
  B0T: {
    zip: 'B0T',
    latitude: '43.7029',
    longitude: '-65.1119',
    city: 'Queens County',
    state: 'NS',
    country: 'Canada',
  },
  B0V: {
    zip: 'B0V',
    latitude: '44.03',
    longitude: '-65.9445',
    city: 'Digby Neck',
    state: 'NS',
    country: 'Canada',
  },
  B0W: {
    zip: 'B0W',
    latitude: '43.8187',
    longitude: '-65.9517',
    city: 'Southwest Mainland',
    state: 'NS',
    country: 'Canada',
  },
  B1A: {
    zip: 'B1A',
    latitude: '46.1794',
    longitude: '-59.9477',
    city: 'Glace Bay',
    state: 'NS',
    country: 'Canada',
  },
  B1B: {
    zip: 'B1B',
    latitude: '46.1365',
    longitude: '-59.8717',
    city: 'Port Morien',
    state: 'NS',
    country: 'Canada',
  },
  B1C: {
    zip: 'B1C',
    latitude: '46.2152',
    longitude: '-60.2452',
    city: 'Louisbourg',
    state: 'NS',
    country: 'Canada',
  },
  B1E: {
    zip: 'B1E',
    latitude: '46.2003',
    longitude: '-60.0215',
    city: 'Reserve Mines',
    state: 'NS',
    country: 'Canada',
  },
  B1G: {
    zip: 'B1G',
    latitude: '46.2063',
    longitude: '-60.0255',
    city: 'Dominion',
    state: 'NS',
    country: 'Canada',
  },
  B1H: {
    zip: 'B1H',
    latitude: '46.2295',
    longitude: '-60.0941',
    city: 'New Waterford',
    state: 'NS',
    country: 'Canada',
  },
  B1J: {
    zip: 'B1J',
    latitude: '45.8365',
    longitude: '-60.4435',
    city: 'East Bay',
    state: 'NS',
    country: 'Canada',
  },
  B1K: {
    zip: 'B1K',
    latitude: '46.1309',
    longitude: '-60.1864',
    city: 'Marion Bridge',
    state: 'NS',
    country: 'Canada',
  },
  B1L: {
    zip: 'B1L',
    latitude: '46.0911',
    longitude: '-60.2462',
    city: 'Sydney Southwest',
    state: 'NS',
    country: 'Canada',
  },
  B1M: {
    zip: 'B1M',
    latitude: '46.169',
    longitude: '-60.1013',
    city: 'Sydney East',
    state: 'NS',
    country: 'Canada',
  },
  B1N: {
    zip: 'B1N',
    latitude: '46.167',
    longitude: '-60.1943',
    city: 'Sydney North',
    state: 'NS',
    country: 'Canada',
  },
  B1P: {
    zip: 'B1P',
    latitude: '46.1337',
    longitude: '-60.1939',
    city: 'Sydney North Central',
    state: 'NS',
    country: 'Canada',
  },
  B1R: {
    zip: 'B1R',
    latitude: '46.1224',
    longitude: '-60.2236',
    city: 'Sydney West',
    state: 'NS',
    country: 'Canada',
  },
  B1S: {
    zip: 'B1S',
    latitude: '46.1334',
    longitude: '-60.1947',
    city: 'Sydney Central',
    state: 'NS',
    country: 'Canada',
  },
  B1T: {
    zip: 'B1T',
    latitude: '46.1122',
    longitude: '-60.2372',
    city: 'Christmas Island',
    state: 'NS',
    country: 'Canada',
  },
  B1V: {
    zip: 'B1V',
    latitude: '46.2383',
    longitude: '-60.2165',
    city: 'North Sydney North',
    state: 'NS',
    country: 'Canada',
  },
  B1W: {
    zip: 'B1W',
    latitude: '45.9245',
    longitude: '-60.6449',
    city: 'Eskasoni',
    state: 'NS',
    country: 'Canada',
  },
  B1X: {
    zip: 'B1X',
    latitude: '46.2667',
    longitude: '-60.4333',
    city: "Big Bras D'or",
    state: 'NS',
    country: 'Canada',
  },
  B1Y: {
    zip: 'B1Y',
    latitude: '46.1811',
    longitude: '-60.5067',
    city: 'Alder Point',
    state: 'NS',
    country: 'Canada',
  },
  B2A: {
    zip: 'B2A',
    latitude: '46.2397',
    longitude: '-60.0998',
    city: 'North Sydney South Central',
    state: 'NS',
    country: 'Canada',
  },
  B2C: {
    zip: 'B2C',
    latitude: '45.6218',
    longitude: '-62.0004',
    city: 'Iona',
    state: 'NS',
    country: 'Canada',
  },
  B2E: {
    zip: 'B2E',
    latitude: '45.6272',
    longitude: '-61.9977',
    city: 'Loch Lomond',
    state: 'NS',
    country: 'Canada',
  },
  B2G: {
    zip: 'B2G',
    latitude: '45.6243',
    longitude: '-61.9996',
    city: 'Antigonish',
    state: 'NS',
    country: 'Canada',
  },
  B2H: {
    zip: 'B2H',
    latitude: '45.5937',
    longitude: '-62.6585',
    city: 'New Glasgow',
    state: 'NS',
    country: 'Canada',
  },
  B2J: {
    zip: 'B2J',
    latitude: '45.3747',
    longitude: '-63.2951',
    city: 'Fourchu',
    state: 'NS',
    country: 'Canada',
  },
  B2N: {
    zip: 'B2N',
    latitude: '45.3486',
    longitude: '-63.3029',
    city: 'Truro',
    state: 'NS',
    country: 'Canada',
  },
  B2R: {
    zip: 'B2R',
    latitude: '44.7431',
    longitude: '-63.5144',
    city: 'Waverley',
    state: 'NS',
    country: 'Canada',
  },
  B2S: {
    zip: 'B2S',
    latitude: '44.9775',
    longitude: '-63.4209',
    city: 'Lantz',
    state: 'NS',
    country: 'Canada',
  },
  B2T: {
    zip: 'B2T',
    latitude: '44.8488',
    longitude: '-63.5999',
    city: 'Enfield',
    state: 'NS',
    country: 'Canada',
  },
  B2V: {
    zip: 'B2V',
    latitude: '44.669',
    longitude: '-63.5019',
    city: 'Dartmouth Morris Lake',
    state: 'NS',
    country: 'Canada',
  },
  B2W: {
    zip: 'B2W',
    latitude: '44.6449',
    longitude: '-63.5433',
    city: 'Dartmouth East Central',
    state: 'NS',
    country: 'Canada',
  },
  B2X: {
    zip: 'B2X',
    latitude: '44.6829',
    longitude: '-63.5442',
    city: 'Dartmouth North Central',
    state: 'NS',
    country: 'Canada',
  },
  B2Y: {
    zip: 'B2Y',
    latitude: '44.7314',
    longitude: '-63.6482',
    city: 'Dartmouth South Central',
    state: 'NS',
    country: 'Canada',
  },
  B2Z: {
    zip: 'B2Z',
    latitude: '44.7104',
    longitude: '-63.4759',
    city: 'Dartmouth East',
    state: 'NS',
    country: 'Canada',
  },
  B3A: {
    zip: 'B3A',
    latitude: '44.6663',
    longitude: '-63.5763',
    city: 'Dartmouth Southwest',
    state: 'NS',
    country: 'Canada',
  },
  B3B: {
    zip: 'B3B',
    latitude: '44.6886',
    longitude: '-63.6076',
    city: 'Dartmouth Northwest',
    state: 'NS',
    country: 'Canada',
  },
  B3E: {
    zip: 'B3E',
    latitude: '44.7227',
    longitude: '-63.3973',
    city: 'Porters Lake',
    state: 'NS',
    country: 'Canada',
  },
  B3G: {
    zip: 'B3G',
    latitude: '44.6156',
    longitude: '-63.4929',
    city: 'Eastern Passage',
    state: 'NS',
    country: 'Canada',
  },
  B3H: {
    zip: 'B3H',
    latitude: '44.6224',
    longitude: '-63.5736',
    city: 'Halifax Lower Harbour',
    state: 'NS',
    country: 'Canada',
  },
  B3J: {
    zip: 'B3J',
    latitude: '44.641',
    longitude: '-63.5682',
    city: 'Halifax Mid-harbour Nova Scotia Provincial Government',
    state: 'NS',
    country: 'Canada',
  },
  B3K: {
    zip: 'B3K',
    latitude: '44.6514',
    longitude: '-63.5818',
    city: 'Halifax Upper Harbour',
    state: 'NS',
    country: 'Canada',
  },
  B3L: {
    zip: 'B3L',
    latitude: '44.6464',
    longitude: '-63.5929',
    city: 'Halifax Central',
    state: 'NS',
    country: 'Canada',
  },
  B3M: {
    zip: 'B3M',
    latitude: '44.6617',
    longitude: '-63.6291',
    city: 'Halifax Bedford Basin',
    state: 'NS',
    country: 'Canada',
  },
  B3N: {
    zip: 'B3N',
    latitude: '44.6327',
    longitude: '-63.6219',
    city: 'Halifax South Central',
    state: 'NS',
    country: 'Canada',
  },
  B3P: {
    zip: 'B3P',
    latitude: '44.6284',
    longitude: '-63.596',
    city: 'Halifax North West Arm',
    state: 'NS',
    country: 'Canada',
  },
  B3R: {
    zip: 'B3R',
    latitude: '44.5829',
    longitude: '-63.5671',
    city: 'Halifax South',
    state: 'NS',
    country: 'Canada',
  },
  B3S: {
    zip: 'B3S',
    latitude: '44.6408',
    longitude: '-63.6723',
    city: 'Halifax West',
    state: 'NS',
    country: 'Canada',
  },
  B3T: {
    zip: 'B3T',
    latitude: '44.6404',
    longitude: '-63.6888',
    city: 'Lakeside',
    state: 'NS',
    country: 'Canada',
  },
  B3V: {
    zip: 'B3V',
    latitude: '44.5682',
    longitude: '-63.6177',
    city: 'Harrietsfield',
    state: 'NS',
    country: 'Canada',
  },
  B3Z: {
    zip: 'B3Z',
    latitude: '44.5539',
    longitude: '-63.8307',
    city: 'Tantallon',
    state: 'NS',
    country: 'Canada',
  },
  B4A: {
    zip: 'B4A',
    latitude: '44.7089',
    longitude: '-63.6676',
    city: 'Bedford Southeast',
    state: 'NS',
    country: 'Canada',
  },
  B4B: {
    zip: 'B4B',
    latitude: '44.7235',
    longitude: '-63.6899',
    city: 'Bedford Northwest',
    state: 'NS',
    country: 'Canada',
  },
  B4C: {
    zip: 'B4C',
    latitude: '44.7765',
    longitude: '-63.6854',
    city: 'Lower Sackville South',
    state: 'NS',
    country: 'Canada',
  },
  B4E: {
    zip: 'B4E',
    latitude: '44.7803',
    longitude: '-63.6916',
    city: 'Lower Sackville West',
    state: 'NS',
    country: 'Canada',
  },
  B4G: {
    zip: 'B4G',
    latitude: '44.805',
    longitude: '-63.667',
    city: 'Lower Sackville North',
    state: 'NS',
    country: 'Canada',
  },
  B4H: {
    zip: 'B4H',
    latitude: '45.8353',
    longitude: '-64.2182',
    city: 'Amherst',
    state: 'NS',
    country: 'Canada',
  },
  B4N: {
    zip: 'B4N',
    latitude: '45.0899',
    longitude: '-64.4963',
    city: 'Kentville',
    state: 'NS',
    country: 'Canada',
  },
  B4P: {
    zip: 'B4P',
    latitude: '45.0917',
    longitude: '-64.3599',
    city: 'Wolfville',
    state: 'NS',
    country: 'Canada',
  },
  B4R: {
    zip: 'B4R',
    latitude: '44.3695',
    longitude: '-64.5197',
    city: 'Coldbrook',
    state: 'NS',
    country: 'Canada',
  },
  B4V: {
    zip: 'B4V',
    latitude: '44.3683',
    longitude: '-64.506',
    city: 'Bridgewater',
    state: 'NS',
    country: 'Canada',
  },
  B5A: {
    zip: 'B5A',
    latitude: '43.8245',
    longitude: '-66.1207',
    city: 'Yarmouth',
    state: 'NS',
    country: 'Canada',
  },
  B9A: {
    zip: 'B9A',
    latitude: '45.612',
    longitude: '-61.3486',
    city: 'Port Hawkesbury',
    state: 'NS',
    country: 'Canada',
  },
  X0E: {
    zip: 'X0E',
    latitude: '62.4043',
    longitude: '-110.7417',
    city: 'Central Northwest Territories',
    state: 'NT',
    country: 'Canada',
  },
  X0G: {
    zip: 'X0G',
    latitude: '60.25',
    longitude: '-123.41',
    city: 'Southwestern Northwest Territories',
    state: 'NT',
    country: 'Canada',
  },
  X1A: {
    zip: 'X1A',
    latitude: '62.4725',
    longitude: '-114.3417',
    city: 'Yellowknife',
    state: 'NT',
    country: 'Canada',
  },
  X0A: {
    zip: 'X0A',
    latitude: '70.4643',
    longitude: '-68.4789',
    city: 'Outer Nunavut',
    state: 'NU',
    country: 'Canada',
  },
  X0B: {
    zip: 'X0B',
    latitude: '67.6963',
    longitude: '-107.9068',
    city: 'Central Nunavut',
    state: 'NU',
    country: 'Canada',
  },
  X0C: {
    zip: 'X0C',
    latitude: '62.2237',
    longitude: '-92.5904',
    city: 'Inner Nunavut',
    state: 'NU',
    country: 'Canada',
  },
  K0A: {
    zip: 'K0A',
    latitude: '45.1953',
    longitude: '-76.1496',
    city: 'National Capital Region',
    state: 'ON',
    country: 'Canada',
  },
  K0B: {
    zip: 'K0B',
    latitude: '45.4131',
    longitude: '-74.9148',
    city: 'Prescott And Russell United Counties',
    state: 'ON',
    country: 'Canada',
  },
  K0C: {
    zip: 'K0C',
    latitude: 'null',
    longitude: '45.2228',
    city: 'Stormont',
    state: 'Dundas and Glengarry United Counties',
    country: 'Canada',
  },
  K0E: {
    zip: 'K0E',
    latitude: '44.6478',
    longitude: '-75.7656',
    city: 'South Leeds And Grenville United Counties',
    state: 'ON',
    country: 'Canada',
  },
  K0G: {
    zip: 'K0G',
    latitude: '45.0113',
    longitude: '-75.6459',
    city: 'Rideau Lakes Area',
    state: 'ON',
    country: 'Canada',
  },
  K0H: {
    zip: 'K0H',
    latitude: 'null',
    longitude: 'null',
    city: 'Frontenac County',
    state: 'Addington County',
    country: 'Canada',
  },
  K0J: {
    zip: 'K0J',
    latitude: '45.3985',
    longitude: '-78.0836',
    city: 'Renfrew County And Lanark Highlands Township',
    state: 'ON',
    country: 'Canada',
  },
  K0K: {
    zip: 'K0K',
    latitude: 'null',
    longitude: '44.0594',
    city: 'Quinte Shores',
    state: 'East Northumberland County & Prince Edward County',
    country: 'Canada',
  },
  K0L: {
    zip: 'K0L',
    latitude: '44.8324',
    longitude: '-77.9302',
    city: 'Peterborough County And North Hastings County',
    state: 'ON',
    country: 'Canada',
  },
  K0M: {
    zip: 'K0M',
    latitude: '44.438',
    longitude: '-78.6828',
    city: 'Kawartha Lakes And Haliburton County',
    state: 'ON',
    country: 'Canada',
  },
  K1A: {
    zip: 'K1A',
    latitude: '45.4207',
    longitude: '-75.7023',
    city: 'Government Of Canada Ottawa And Gatineau Offices',
    state: 'ON',
    country: 'Canada',
  },
  K1B: {
    zip: 'K1B',
    latitude: '45.4325',
    longitude: '-75.5624',
    city: 'Gloucester',
    state: 'ON',
    country: 'Canada',
  },
  K1C: {
    zip: 'K1C',
    latitude: '45.4805',
    longitude: '-75.5237',
    city: 'Gloucester',
    state: 'ON',
    country: 'Canada',
  },
  K1E: {
    zip: 'K1E',
    latitude: '45.4882',
    longitude: '-75.5199',
    city: 'Orleans',
    state: 'ON',
    country: 'Canada',
  },
  K1G: {
    zip: 'K1G',
    latitude: '45.4118',
    longitude: '-75.6304',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K1H: {
    zip: 'K1H',
    latitude: '45.3938',
    longitude: '-75.6639',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K1J: {
    zip: 'K1J',
    latitude: '45.422',
    longitude: '-75.6303',
    city: 'Gloucester',
    state: 'ON',
    country: 'Canada',
  },
  K1K: {
    zip: 'K1K',
    latitude: '45.4354',
    longitude: '-75.6475',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K1L: {
    zip: 'K1L',
    latitude: '45.44',
    longitude: '-75.6524',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K1M: {
    zip: 'K1M',
    latitude: '45.4461',
    longitude: '-75.6744',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K1N: {
    zip: 'K1N',
    latitude: '45.3176',
    longitude: '-75.895',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K1P: {
    zip: 'K1P',
    latitude: '45.423',
    longitude: '-75.702',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K1R: {
    zip: 'K1R',
    latitude: '45.4',
    longitude: '-75.7235',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K1S: {
    zip: 'K1S',
    latitude: '45.4127',
    longitude: '-75.6742',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K1T: {
    zip: 'K1T',
    latitude: '45.352',
    longitude: '-75.6421',
    city: 'Gloucester',
    state: 'ON',
    country: 'Canada',
  },
  K1V: {
    zip: 'K1V',
    latitude: '45.3523',
    longitude: '-75.6512',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K1W: {
    zip: 'K1W',
    latitude: '45.436',
    longitude: '-75.5471',
    city: 'Gloucester',
    state: 'ON',
    country: 'Canada',
  },
  K1X: {
    zip: 'K1X',
    latitude: '45.2884',
    longitude: '-75.5992',
    city: 'Gloucester South',
    state: 'ON',
    country: 'Canada',
  },
  K1Y: {
    zip: 'K1Y',
    latitude: '45.399',
    longitude: '-75.7304',
    city: 'Ottawa West',
    state: 'ON',
    country: 'Canada',
  },
  K1Z: {
    zip: 'K1Z',
    latitude: '45.3956',
    longitude: '-75.7462',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K2A: {
    zip: 'K2A',
    latitude: '45.3778',
    longitude: '-75.7632',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K2B: {
    zip: 'K2B',
    latitude: '45.3679',
    longitude: '-75.7888',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K2C: {
    zip: 'K2C',
    latitude: '45.3594',
    longitude: '-75.7523',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K2E: {
    zip: 'K2E',
    latitude: '45.3353',
    longitude: '-75.7209',
    city: 'Nepean East',
    state: 'ON',
    country: 'Canada',
  },
  K2G: {
    zip: 'K2G',
    latitude: '45.3286',
    longitude: '-75.7703',
    city: 'Nepean',
    state: 'ON',
    country: 'Canada',
  },
  K2H: {
    zip: 'K2H',
    latitude: '45.3155',
    longitude: '-75.837',
    city: 'Nepean',
    state: 'ON',
    country: 'Canada',
  },
  K2J: {
    zip: 'K2J',
    latitude: '45.2882',
    longitude: '-75.7566',
    city: 'Nepean',
    state: 'ON',
    country: 'Canada',
  },
  K2K: {
    zip: 'K2K',
    latitude: '45.3339',
    longitude: '-75.9098',
    city: 'Kanata',
    state: 'ON',
    country: 'Canada',
  },
  K2L: {
    zip: 'K2L',
    latitude: '45.3125',
    longitude: '-75.8838',
    city: 'Kanata',
    state: 'ON',
    country: 'Canada',
  },
  K2M: {
    zip: 'K2M',
    latitude: '45.2884',
    longitude: '-75.8648',
    city: 'Kanata',
    state: 'ON',
    country: 'Canada',
  },
  K2P: {
    zip: 'K2P',
    latitude: '45.4129',
    longitude: '-75.6901',
    city: 'Ottawa',
    state: 'ON',
    country: 'Canada',
  },
  K2R: {
    zip: 'K2R',
    latitude: '45.2776',
    longitude: '-75.7902',
    city: 'Nepean',
    state: 'ON',
    country: 'Canada',
  },
  K2S: {
    zip: 'K2S',
    latitude: '45.2573',
    longitude: '-75.9153',
    city: 'Stittsville',
    state: 'ON',
    country: 'Canada',
  },
  K2T: {
    zip: 'K2T',
    latitude: '45.3121',
    longitude: '-75.9217',
    city: 'Kanata',
    state: 'ON',
    country: 'Canada',
  },
  K2V: {
    zip: 'K2V',
    latitude: '45.3018',
    longitude: '-75.9081',
    city: 'Kanata',
    state: 'ON',
    country: 'Canada',
  },
  K2W: {
    zip: 'K2W',
    latitude: '45.3564',
    longitude: '-75.9445',
    city: 'Kanata',
    state: 'ON',
    country: 'Canada',
  },
  K4A: {
    zip: 'K4A',
    latitude: '45.4769',
    longitude: '-75.4835',
    city: 'Orleans',
    state: 'ON',
    country: 'Canada',
  },
  K4B: {
    zip: 'K4B',
    latitude: '45.4251',
    longitude: '-75.4288',
    city: 'Cumberland Township',
    state: 'ON',
    country: 'Canada',
  },
  K4C: {
    zip: 'K4C',
    latitude: '45.5177',
    longitude: '-75.4108',
    city: 'Cumberland',
    state: 'ON',
    country: 'Canada',
  },
  K4K: {
    zip: 'K4K',
    latitude: '45.5415',
    longitude: '-75.3062',
    city: 'Rockland',
    state: 'ON',
    country: 'Canada',
  },
  K4M: {
    zip: 'K4M',
    latitude: '45.2289',
    longitude: '-75.6817',
    city: 'Manotick',
    state: 'ON',
    country: 'Canada',
  },
  K4P: {
    zip: 'K4P',
    latitude: '45.258',
    longitude: '-75.5762',
    city: 'Greely',
    state: 'ON',
    country: 'Canada',
  },
  K4R: {
    zip: 'K4R',
    latitude: '45.2573',
    longitude: '-75.3675',
    city: 'Russell',
    state: 'ON',
    country: 'Canada',
  },
  K6A: {
    zip: 'K6A',
    latitude: '45.6101',
    longitude: '-74.6085',
    city: 'Hawkesbury',
    state: 'ON',
    country: 'Canada',
  },
  K6H: {
    zip: 'K6H',
    latitude: '45.0186',
    longitude: '-74.7129',
    city: 'Cornwall East',
    state: 'ON',
    country: 'Canada',
  },
  K6J: {
    zip: 'K6J',
    latitude: '45.0149',
    longitude: '-74.7279',
    city: 'Cornwall West',
    state: 'ON',
    country: 'Canada',
  },
  K6K: {
    zip: 'K6K',
    latitude: '45.0607',
    longitude: '-74.7542',
    city: 'Cornwall North',
    state: 'ON',
    country: 'Canada',
  },
  K6T: {
    zip: 'K6T',
    latitude: '44.618',
    longitude: '-75.6895',
    city: 'Elizabethtown',
    state: 'ON',
    country: 'Canada',
  },
  K6V: {
    zip: 'K6V',
    latitude: '44.5906',
    longitude: '-75.6808',
    city: 'Brockville',
    state: 'ON',
    country: 'Canada',
  },
  K7A: {
    zip: 'K7A',
    latitude: '44.8995',
    longitude: '-76.021',
    city: 'Smiths Falls',
    state: 'ON',
    country: 'Canada',
  },
  K7C: {
    zip: 'K7C',
    latitude: '45.135',
    longitude: '-76.1313',
    city: 'Carleton Place',
    state: 'ON',
    country: 'Canada',
  },
  K7G: {
    zip: 'K7G',
    latitude: '44.3319',
    longitude: '-76.1471',
    city: 'Gananoque',
    state: 'ON',
    country: 'Canada',
  },
  K7H: {
    zip: 'K7H',
    latitude: '44.902',
    longitude: '-76.2457',
    city: 'Perth',
    state: 'ON',
    country: 'Canada',
  },
  K7K: {
    zip: 'K7K',
    latitude: '44.2322',
    longitude: '-76.4799',
    city: 'Kingston',
    state: 'ON',
    country: 'Canada',
  },
  K7L: {
    zip: 'K7L',
    latitude: '44.231',
    longitude: '-76.4791',
    city: 'Kingston',
    state: 'ON',
    country: 'Canada',
  },
  K7M: {
    zip: 'K7M',
    latitude: '44.2274',
    longitude: '-76.5134',
    city: 'Kingston',
    state: 'ON',
    country: 'Canada',
  },
  K7N: {
    zip: 'K7N',
    latitude: '44.2255',
    longitude: '-76.629',
    city: 'Amherstview',
    state: 'ON',
    country: 'Canada',
  },
  K7P: {
    zip: 'K7P',
    latitude: '44.2507',
    longitude: '-76.5828',
    city: 'Kingston',
    state: 'ON',
    country: 'Canada',
  },
  K7R: {
    zip: 'K7R',
    latitude: '44.2538',
    longitude: '-76.943',
    city: 'Napanee',
    state: 'ON',
    country: 'Canada',
  },
  K7S: {
    zip: 'K7S',
    latitude: '45.4238',
    longitude: '-76.3624',
    city: 'Arnprior',
    state: 'ON',
    country: 'Canada',
  },
  K7V: {
    zip: 'K7V',
    latitude: '45.4779',
    longitude: '-76.6731',
    city: 'Renfrew',
    state: 'ON',
    country: 'Canada',
  },
  K8A: {
    zip: 'K8A',
    latitude: '45.8173',
    longitude: '-77.1174',
    city: 'Pembroke Central And Northern Subdivisions',
    state: 'ON',
    country: 'Canada',
  },
  K8B: {
    zip: 'K8B',
    latitude: '45.815',
    longitude: '-77.1107',
    city: 'Pembroke',
    state: 'ON',
    country: 'Canada',
  },
  K8H: {
    zip: 'K8H',
    latitude: '45.9151',
    longitude: '-77.2754',
    city: 'Petawawa',
    state: 'ON',
    country: 'Canada',
  },
  K8N: {
    zip: 'K8N',
    latitude: '44.1607',
    longitude: '-77.369',
    city: 'Belleville East',
    state: 'ON',
    country: 'Canada',
  },
  K8P: {
    zip: 'K8P',
    latitude: '44.1605',
    longitude: '-77.3846',
    city: 'Belleville West',
    state: 'ON',
    country: 'Canada',
  },
  K8R: {
    zip: 'K8R',
    latitude: '44.1312',
    longitude: '-77.4521',
    city: 'Belleville',
    state: 'ON',
    country: 'Canada',
  },
  K8V: {
    zip: 'K8V',
    latitude: '44.1106',
    longitude: '-77.5569',
    city: 'Trenton',
    state: 'ON',
    country: 'Canada',
  },
  K9A: {
    zip: 'K9A',
    latitude: '43.9851',
    longitude: '-78.1621',
    city: 'Cobourg',
    state: 'ON',
    country: 'Canada',
  },
  K9H: {
    zip: 'K9H',
    latitude: '44.299',
    longitude: '-78.3145',
    city: 'Peterborough North',
    state: 'ON',
    country: 'Canada',
  },
  K9J: {
    zip: 'K9J',
    latitude: '44.2763',
    longitude: '-78.313',
    city: 'Peterborough South',
    state: 'ON',
    country: 'Canada',
  },
  K9K: {
    zip: 'K9K',
    latitude: '44.279',
    longitude: '-78.3659',
    city: 'Peterborough',
    state: 'ON',
    country: 'Canada',
  },
  K9L: {
    zip: 'K9L',
    latitude: '44.3238',
    longitude: '-78.303',
    city: 'Peterborough',
    state: 'ON',
    country: 'Canada',
  },
  K9V: {
    zip: 'K9V',
    latitude: '44.3512',
    longitude: '-78.7192',
    city: 'Lindsay',
    state: 'ON',
    country: 'Canada',
  },
  L0A: {
    zip: 'L0A',
    latitude: '44.1836',
    longitude: '-78.5563',
    city: 'West Northumberland County',
    state: 'ON',
    country: 'Canada',
  },
  L0B: {
    zip: 'L0B',
    latitude: '44.0286',
    longitude: '-79.0015',
    city: 'East Durham Regional Municipality',
    state: 'ON',
    country: 'Canada',
  },
  L0C: {
    zip: 'L0C',
    latitude: '44.0371',
    longitude: '-79.1964',
    city: 'West Durham Regional Municipality',
    state: 'ON',
    country: 'Canada',
  },
  L0E: {
    zip: 'L0E',
    latitude: '44.2406',
    longitude: '-79.357',
    city: 'Lake Simcoe Southeast Shore',
    state: 'ON',
    country: 'Canada',
  },
  L0G: {
    zip: 'L0G',
    latitude: '44.1595',
    longitude: '-79.8733',
    city: 'Ontario Centre',
    state: 'ON',
    country: 'Canada',
  },
  L0H: {
    zip: 'L0H',
    latitude: '43.9282',
    longitude: '-79.1201',
    city: 'Whitby Region',
    state: 'ON',
    country: 'Canada',
  },
  L0J: {
    zip: 'L0J',
    latitude: '43.7788',
    longitude: '-79.4991',
    city: 'North Peel Regional Municipality',
    state: 'ON',
    country: 'Canada',
  },
  L0K: {
    zip: 'L0K',
    latitude: '44.6072',
    longitude: '-79.6291',
    city: 'Lake Simcoe North Shore',
    state: 'ON',
    country: 'Canada',
  },
  L0L: {
    zip: 'L0L',
    latitude: '44.1535',
    longitude: '-79.8683',
    city: 'Lake Simcoe West Shore',
    state: 'ON',
    country: 'Canada',
  },
  L0M: {
    zip: 'L0M',
    latitude: '44.1476',
    longitude: '-79.872',
    city: 'Georgian Bay South Shore',
    state: 'ON',
    country: 'Canada',
  },
  L0N: {
    zip: 'L0N',
    latitude: '43.8582',
    longitude: '-80.0696',
    city: 'Dufferin County',
    state: 'ON',
    country: 'Canada',
  },
  L0P: {
    zip: 'L0P',
    latitude: '43.7882',
    longitude: '-79.6754',
    city: 'Halton Regional Municipality',
    state: 'ON',
    country: 'Canada',
  },
  L0R: {
    zip: 'L0R',
    latitude: '43.1661',
    longitude: '-80.0702',
    city: 'East Haldimand County',
    state: 'ON',
    country: 'Canada',
  },
  L0S: {
    zip: 'L0S',
    latitude: '43.0796',
    longitude: '-79.199',
    city: 'Niagara Regional Municipality',
    state: 'ON',
    country: 'Canada',
  },
  L1A: {
    zip: 'L1A',
    latitude: '43.9427',
    longitude: '-78.2944',
    city: 'Port Hope',
    state: 'ON',
    country: 'Canada',
  },
  L1B: {
    zip: 'L1B',
    latitude: '43.8966',
    longitude: '-78.6309',
    city: 'Bowmanville East',
    state: 'ON',
    country: 'Canada',
  },
  L1C: {
    zip: 'L1C',
    latitude: '43.9014',
    longitude: '-78.6755',
    city: 'Bowmanville West',
    state: 'ON',
    country: 'Canada',
  },
  L1E: {
    zip: 'L1E',
    latitude: '43.914',
    longitude: '-78.6925',
    city: 'Courtice',
    state: 'ON',
    country: 'Canada',
  },
  L1G: {
    zip: 'L1G',
    latitude: '43.898',
    longitude: '-78.8656',
    city: 'Oshawa Central',
    state: 'ON',
    country: 'Canada',
  },
  L1H: {
    zip: 'L1H',
    latitude: '43.8973',
    longitude: '-78.8641',
    city: 'Oshawa Southeast',
    state: 'ON',
    country: 'Canada',
  },
  L1J: {
    zip: 'L1J',
    latitude: '43.8587',
    longitude: '-78.8341',
    city: 'Oshawa Southwest',
    state: 'ON',
    country: 'Canada',
  },
  L1K: {
    zip: 'L1K',
    latitude: '43.9091',
    longitude: '-78.8088',
    city: 'Oshawa East',
    state: 'ON',
    country: 'Canada',
  },
  L1L: {
    zip: 'L1L',
    latitude: '43.9527',
    longitude: '-78.8795',
    city: 'Oshawa North',
    state: 'ON',
    country: 'Canada',
  },
  L1M: {
    zip: 'L1M',
    latitude: '43.9561',
    longitude: '-78.9556',
    city: 'Whitby North',
    state: 'ON',
    country: 'Canada',
  },
  L1N: {
    zip: 'L1N',
    latitude: '43.8581',
    longitude: '-78.9319',
    city: 'Whitby Southeast',
    state: 'ON',
    country: 'Canada',
  },
  L1P: {
    zip: 'L1P',
    latitude: '43.8744',
    longitude: '-78.9638',
    city: 'Whitby Southwest',
    state: 'ON',
    country: 'Canada',
  },
  L1R: {
    zip: 'L1R',
    latitude: '43.9018',
    longitude: '-78.9347',
    city: 'Whitby Central',
    state: 'ON',
    country: 'Canada',
  },
  L1S: {
    zip: 'L1S',
    latitude: '43.8265',
    longitude: '-78.9991',
    city: 'Ajax Southwest',
    state: 'ON',
    country: 'Canada',
  },
  L1T: {
    zip: 'L1T',
    latitude: '43.8603',
    longitude: '-79.0434',
    city: 'Ajax Northwest',
    state: 'ON',
    country: 'Canada',
  },
  L1V: {
    zip: 'L1V',
    latitude: '43.8087',
    longitude: '-79.1307',
    city: 'Pickering Southwest',
    state: 'ON',
    country: 'Canada',
  },
  L1W: {
    zip: 'L1W',
    latitude: '43.8125',
    longitude: '-79.0827',
    city: 'Pickering South',
    state: 'ON',
    country: 'Canada',
  },
  L1X: {
    zip: 'L1X',
    latitude: '43.8449',
    longitude: '-79.0996',
    city: 'Pickering Central',
    state: 'ON',
    country: 'Canada',
  },
  L1Y: {
    zip: 'L1Y',
    latitude: '43.9903',
    longitude: '-79.1004',
    city: 'Pickering North',
    state: 'ON',
    country: 'Canada',
  },
  L1Z: {
    zip: 'L1Z',
    latitude: '43.8627',
    longitude: '-79.0136',
    city: 'Ajax East',
    state: 'ON',
    country: 'Canada',
  },
  L2A: {
    zip: 'L2A',
    latitude: '42.8845',
    longitude: '-78.9398',
    city: 'Fort Erie',
    state: 'ON',
    country: 'Canada',
  },
  L2E: {
    zip: 'L2E',
    latitude: '43.0939',
    longitude: '-79.0699',
    city: 'Niagara Falls Central',
    state: 'ON',
    country: 'Canada',
  },
  L2G: {
    zip: 'L2G',
    latitude: '43.0963',
    longitude: '-79.074',
    city: 'Niagara Falls Southeast',
    state: 'ON',
    country: 'Canada',
  },
  L2H: {
    zip: 'L2H',
    latitude: '43.1148',
    longitude: '-79.1238',
    city: 'Niagara Falls West',
    state: 'ON',
    country: 'Canada',
  },
  L2J: {
    zip: 'L2J',
    latitude: '43.1155',
    longitude: '-79.0916',
    city: 'Niagara Falls North',
    state: 'ON',
    country: 'Canada',
  },
  L2M: {
    zip: 'L2M',
    latitude: '43.2237',
    longitude: '-79.2191',
    city: 'St. Catharines Northeast',
    state: 'ON',
    country: 'Canada',
  },
  L2N: {
    zip: 'L2N',
    latitude: '43.1751',
    longitude: '-79.2389',
    city: 'St. Catharines Northwest',
    state: 'ON',
    country: 'Canada',
  },
  L2P: {
    zip: 'L2P',
    latitude: '43.1418',
    longitude: '-79.2133',
    city: 'St. Catharines East',
    state: 'ON',
    country: 'Canada',
  },
  L2R: {
    zip: 'L2R',
    latitude: '43.1719',
    longitude: '-79.227',
    city: 'St. Catharines Central',
    state: 'ON',
    country: 'Canada',
  },
  L2S: {
    zip: 'L2S',
    latitude: '43.1275',
    longitude: '-79.2631',
    city: 'St. Catharines Southwest',
    state: 'ON',
    country: 'Canada',
  },
  L2T: {
    zip: 'L2T',
    latitude: '43.1334',
    longitude: '-79.1989',
    city: 'St. Catharines South',
    state: 'ON',
    country: 'Canada',
  },
  L2V: {
    zip: 'L2V',
    latitude: '43.1017',
    longitude: '-79.1997',
    city: 'St. Catharines Southeast',
    state: 'ON',
    country: 'Canada',
  },
  L2W: {
    zip: 'L2W',
    latitude: '43.1743',
    longitude: '-79.2744',
    city: 'St. Catharines West',
    state: 'ON',
    country: 'Canada',
  },
  L3B: {
    zip: 'L3B',
    latitude: '42.9859',
    longitude: '-79.2232',
    city: 'Welland East',
    state: 'ON',
    country: 'Canada',
  },
  L3C: {
    zip: 'L3C',
    latitude: '42.9989',
    longitude: '-79.2466',
    city: 'Welland West',
    state: 'ON',
    country: 'Canada',
  },
  L3K: {
    zip: 'L3K',
    latitude: '42.8754',
    longitude: '-79.237',
    city: 'Port Colborne',
    state: 'ON',
    country: 'Canada',
  },
  L3M: {
    zip: 'L3M',
    latitude: '43.2005',
    longitude: '-79.6292',
    city: 'Grimsby',
    state: 'ON',
    country: 'Canada',
  },
  L3P: {
    zip: 'L3P',
    latitude: '43.8605',
    longitude: '-79.3279',
    city: 'Markham Central',
    state: 'ON',
    country: 'Canada',
  },
  L3R: {
    zip: 'L3R',
    latitude: '43.86',
    longitude: '-79.3605',
    city: 'Markham Outer Southwest',
    state: 'ON',
    country: 'Canada',
  },
  L3S: {
    zip: 'L3S',
    latitude: '43.831',
    longitude: '-79.2768',
    city: 'Markham Southeast',
    state: 'ON',
    country: 'Canada',
  },
  L3T: {
    zip: 'L3T',
    latitude: '43.7984',
    longitude: '-79.4186',
    city: 'Thornhill East',
    state: 'ON',
    country: 'Canada',
  },
  L3V: {
    zip: 'L3V',
    latitude: '44.6039',
    longitude: '-79.4126',
    city: 'Orillia',
    state: 'ON',
    country: 'Canada',
  },
  L3X: {
    zip: 'L3X',
    latitude: '44.0464',
    longitude: '-79.4874',
    city: 'Newmarket Southwest',
    state: 'ON',
    country: 'Canada',
  },
  L3Y: {
    zip: 'L3Y',
    latitude: '44.0414',
    longitude: '-79.4534',
    city: 'Newmarket Northeast',
    state: 'ON',
    country: 'Canada',
  },
  L3Z: {
    zip: 'L3Z',
    latitude: '44.1208',
    longitude: '-79.5656',
    city: 'Bradford',
    state: 'ON',
    country: 'Canada',
  },
  L4A: {
    zip: 'L4A',
    latitude: '43.9707',
    longitude: '-79.2503',
    city: 'Stouffville',
    state: 'ON',
    country: 'Canada',
  },
  L4B: {
    zip: 'L4B',
    latitude: '43.8417',
    longitude: '-79.4011',
    city: 'Richmond Hill Southeast',
    state: 'ON',
    country: 'Canada',
  },
  L4C: {
    zip: 'L4C',
    latitude: '43.8759',
    longitude: '-79.4381',
    city: 'Richmond Hill Southwest',
    state: 'ON',
    country: 'Canada',
  },
  L4E: {
    zip: 'L4E',
    latitude: '43.9423',
    longitude: '-79.4595',
    city: 'Richmond Hill North',
    state: 'ON',
    country: 'Canada',
  },
  L4G: {
    zip: 'L4G',
    latitude: '43.9909',
    longitude: '-79.4639',
    city: 'Aurora',
    state: 'ON',
    country: 'Canada',
  },
  L4H: {
    zip: 'L4H',
    latitude: '43.8084',
    longitude: '-79.6089',
    city: 'Woodbridge North',
    state: 'ON',
    country: 'Canada',
  },
  L4J: {
    zip: 'L4J',
    latitude: '43.7964',
    longitude: '-79.4278',
    city: 'Thornhill West',
    state: 'ON',
    country: 'Canada',
  },
  L4K: {
    zip: 'L4K',
    latitude: '43.7848',
    longitude: '-79.4811',
    city: 'Concord',
    state: 'ON',
    country: 'Canada',
  },
  L4L: {
    zip: 'L4L',
    latitude: '43.7886',
    longitude: '-79.5919',
    city: 'Woodbridge South',
    state: 'ON',
    country: 'Canada',
  },
  L4M: {
    zip: 'L4M',
    latitude: '44.3885',
    longitude: '-79.6886',
    city: 'Barrie North',
    state: 'ON',
    country: 'Canada',
  },
  L4N: {
    zip: 'L4N',
    latitude: '44.3891',
    longitude: '-79.6901',
    city: 'Barrie South',
    state: 'ON',
    country: 'Canada',
  },
  L4P: {
    zip: 'L4P',
    latitude: '44.2421',
    longitude: '-79.4818',
    city: 'Keswick',
    state: 'ON',
    country: 'Canada',
  },
  L4R: {
    zip: 'L4R',
    latitude: '44.7542',
    longitude: '-79.9005',
    city: 'Midland',
    state: 'ON',
    country: 'Canada',
  },
  L4S: {
    zip: 'L4S',
    latitude: '43.8975',
    longitude: '-79.4415',
    city: 'Richmond Hill Central',
    state: 'ON',
    country: 'Canada',
  },
  L4T: {
    zip: 'L4T',
    latitude: '43.6951',
    longitude: '-79.6525',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L4V: {
    zip: 'L4V',
    latitude: '43.6879',
    longitude: '-79.6072',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L4W: {
    zip: 'L4W',
    latitude: '43.6272',
    longitude: '-79.6222',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L4X: {
    zip: 'L4X',
    latitude: '43.5996',
    longitude: '-79.5664',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L4Y: {
    zip: 'L4Y',
    latitude: '43.5854',
    longitude: '-79.583',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L4Z: {
    zip: 'L4Z',
    latitude: '43.6092',
    longitude: '-79.6201',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5A: {
    zip: 'L5A',
    latitude: '43.5701',
    longitude: '-79.5985',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5B: {
    zip: 'L5B',
    latitude: '43.5665',
    longitude: '-79.6035',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5C: {
    zip: 'L5C',
    latitude: '43.5591',
    longitude: '-79.6186',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5E: {
    zip: 'L5E',
    latitude: '43.571',
    longitude: '-79.5668',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5G: {
    zip: 'L5G',
    latitude: '43.5581',
    longitude: '-79.5738',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5H: {
    zip: 'L5H',
    latitude: '43.5472',
    longitude: '-79.585',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5J: {
    zip: 'L5J',
    latitude: '43.5146',
    longitude: '-79.6063',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5K: {
    zip: 'L5K',
    latitude: '43.5319',
    longitude: '-79.6403',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5L: {
    zip: 'L5L',
    latitude: '43.5372',
    longitude: '-79.6667',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5M: {
    zip: 'L5M',
    latitude: '43.5747',
    longitude: '-79.7278',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5N: {
    zip: 'L5N',
    latitude: '43.5892',
    longitude: '-79.7239',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5P: {
    zip: 'L5P',
    latitude: '43.6904',
    longitude: '-79.6238',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5R: {
    zip: 'L5R',
    latitude: '43.5974',
    longitude: '-79.6402',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5S: {
    zip: 'L5S',
    latitude: '43.6975',
    longitude: '-79.6615',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5T: {
    zip: 'L5T',
    latitude: '43.6578',
    longitude: '-79.6607',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5V: {
    zip: 'L5V',
    latitude: '43.6097',
    longitude: '-79.704',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L5W: {
    zip: 'L5W',
    latitude: '43.6261',
    longitude: '-79.729',
    city: 'Mississauga',
    state: 'ON',
    country: 'Canada',
  },
  L6A: {
    zip: 'L6A',
    latitude: '43.857',
    longitude: '-79.514',
    city: 'Maple',
    state: 'ON',
    country: 'Canada',
  },
  L6B: {
    zip: 'L6B',
    latitude: '43.8845',
    longitude: '-79.2339',
    city: 'Markham East',
    state: 'ON',
    country: 'Canada',
  },
  L6C: {
    zip: 'L6C',
    latitude: '43.8842',
    longitude: '-79.3359',
    city: 'Markham Northwest',
    state: 'ON',
    country: 'Canada',
  },
  L6E: {
    zip: 'L6E',
    latitude: '43.8927',
    longitude: '-79.2641',
    city: 'Markham Northeast',
    state: 'ON',
    country: 'Canada',
  },
  L6G: {
    zip: 'L6G',
    latitude: '43.8478',
    longitude: '-79.3447',
    city: 'Markham Inner Southwest',
    state: 'ON',
    country: 'Canada',
  },
  L6H: {
    zip: 'L6H',
    latitude: '43.4543',
    longitude: '-79.6921',
    city: 'Oakville North',
    state: 'ON',
    country: 'Canada',
  },
  L6J: {
    zip: 'L6J',
    latitude: '43.4427',
    longitude: '-79.6664',
    city: 'Oakville Northeast',
    state: 'ON',
    country: 'Canada',
  },
  L6K: {
    zip: 'L6K',
    latitude: '43.4401',
    longitude: '-79.669',
    city: 'Oakville East',
    state: 'ON',
    country: 'Canada',
  },
  L6L: {
    zip: 'L6L',
    latitude: '43.4037',
    longitude: '-79.6934',
    city: 'Oakville South',
    state: 'ON',
    country: 'Canada',
  },
  L6M: {
    zip: 'L6M',
    latitude: '43.4453',
    longitude: '-79.7095',
    city: 'Oakville West',
    state: 'ON',
    country: 'Canada',
  },
  L6P: {
    zip: 'L6P',
    latitude: '43.7794',
    longitude: '-79.7284',
    city: 'Brampton North',
    state: 'ON',
    country: 'Canada',
  },
  L6R: {
    zip: 'L6R',
    latitude: '43.7494',
    longitude: '-79.7511',
    city: 'Brampton Northwest',
    state: 'ON',
    country: 'Canada',
  },
  L6S: {
    zip: 'L6S',
    latitude: '43.7153',
    longitude: '-79.7321',
    city: 'Brampton North Central',
    state: 'ON',
    country: 'Canada',
  },
  L6T: {
    zip: 'L6T',
    latitude: '43.6892',
    longitude: '-79.7079',
    city: 'Brampton East',
    state: 'ON',
    country: 'Canada',
  },
  L6V: {
    zip: 'L6V',
    latitude: '43.7074',
    longitude: '-79.7853',
    city: 'Brampton Central',
    state: 'ON',
    country: 'Canada',
  },
  L6W: {
    zip: 'L6W',
    latitude: '43.6746',
    longitude: '-79.724',
    city: 'Brampton Southeast',
    state: 'ON',
    country: 'Canada',
  },
  L6X: {
    zip: 'L6X',
    latitude: '43.6858',
    longitude: '-79.7602',
    city: 'Brampton Southwest',
    state: 'ON',
    country: 'Canada',
  },
  L6Y: {
    zip: 'L6Y',
    latitude: '43.6699',
    longitude: '-79.7444',
    city: 'Brampton South',
    state: 'ON',
    country: 'Canada',
  },
  L6Z: {
    zip: 'L6Z',
    latitude: '43.7304',
    longitude: '-79.8042',
    city: 'Brampton West Central',
    state: 'ON',
    country: 'Canada',
  },
  L7A: {
    zip: 'L7A',
    latitude: '43.7023',
    longitude: '-79.7909',
    city: 'Brampton West',
    state: 'ON',
    country: 'Canada',
  },
  L7B: {
    zip: 'L7B',
    latitude: '43.9327',
    longitude: '-79.5104',
    city: 'King City',
    state: 'ON',
    country: 'Canada',
  },
  L7C: {
    zip: 'L7C',
    latitude: '43.7467',
    longitude: '-79.8304',
    city: 'Caledon',
    state: 'ON',
    country: 'Canada',
  },
  L7E: {
    zip: 'L7E',
    latitude: '43.8628',
    longitude: '-79.7147',
    city: 'Bolton',
    state: 'ON',
    country: 'Canada',
  },
  L7G: {
    zip: 'L7G',
    latitude: '43.644',
    longitude: '-79.8787',
    city: 'Georgetown',
    state: 'ON',
    country: 'Canada',
  },
  L7J: {
    zip: 'L7J',
    latitude: '43.634',
    longitude: '-80.0491',
    city: 'Acton',
    state: 'ON',
    country: 'Canada',
  },
  L7L: {
    zip: 'L7L',
    latitude: '43.3479',
    longitude: '-79.7593',
    city: 'Burlington Northeast',
    state: 'ON',
    country: 'Canada',
  },
  L7M: {
    zip: 'L7M',
    latitude: '43.3585',
    longitude: '-79.8093',
    city: 'Burlington North',
    state: 'ON',
    country: 'Canada',
  },
  L7N: {
    zip: 'L7N',
    latitude: '43.3336',
    longitude: '-79.7771',
    city: 'Burlington East',
    state: 'ON',
    country: 'Canada',
  },
  L7P: {
    zip: 'L7P',
    latitude: '43.3503',
    longitude: '-79.8117',
    city: 'Burlington West',
    state: 'ON',
    country: 'Canada',
  },
  L7R: {
    zip: 'L7R',
    latitude: '43.3248',
    longitude: '-79.7957',
    city: 'Burlington Southeast',
    state: 'ON',
    country: 'Canada',
  },
  L7S: {
    zip: 'L7S',
    latitude: '43.304',
    longitude: '-79.7991',
    city: 'Burlington South',
    state: 'ON',
    country: 'Canada',
  },
  L7T: {
    zip: 'L7T',
    latitude: '43.3018',
    longitude: '-79.8497',
    city: 'Burlington Southwest',
    state: 'ON',
    country: 'Canada',
  },
  L8E: {
    zip: 'L8E',
    latitude: '43.2318',
    longitude: '-79.7696',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L8G: {
    zip: 'L8G',
    latitude: '43.2298',
    longitude: '-79.7722',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L8H: {
    zip: 'L8H',
    latitude: '43.2369',
    longitude: '-79.7991',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L8J: {
    zip: 'L8J',
    latitude: '43.1907',
    longitude: '-79.7878',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L8K: {
    zip: 'L8K',
    latitude: '43.2424',
    longitude: '-79.8192',
    city: "Hamilton",
    state: 'ON',
    country: 'Canada',
  },
  L8L: {
    zip: 'L8L',
    latitude: '43.2645',
    longitude: '-79.8664',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L8M: {
    zip: 'L8M',
    latitude: '43.2522',
    longitude: '-79.8489',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L8N: {
    zip: 'L8N',
    latitude: '43.2566',
    longitude: '-79.8683',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L8P: {
    zip: 'L8P',
    latitude: '43.257',
    longitude: '-79.8697',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L8R: {
    zip: 'L8R',
    latitude: '43.2574',
    longitude: '-79.8676',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L8S: {
    zip: 'L8S',
    latitude: '43.2604',
    longitude: '-79.8961',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L8T: {
    zip: 'L8T',
    latitude: '43.2365',
    longitude: '-79.8338',
    city: "Hamilton",
    state: 'ON',
    country: 'Canada',
  },
  L8V: {
    zip: 'L8V',
    latitude: '43.2428',
    longitude: '-79.8524',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L8W: {
    zip: 'L8W',
    latitude: '43.2141',
    longitude: '-79.8626',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L9A: {
    zip: 'L9A',
    latitude: '43.241',
    longitude: '-79.8452',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L9B: {
    zip: 'L9B',
    latitude: '43.2116',
    longitude: '-79.8915',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L9C: {
    zip: 'L9C',
    latitude: '43.2432',
    longitude: '-79.876',
    city: 'Hamilton',
    state: 'ON',
    country: 'Canada',
  },
  L9G: {
    zip: 'L9G',
    latitude: '43.2199',
    longitude: '-79.9874',
    city: 'Ancaster West',
    state: 'ON',
    country: 'Canada',
  },
  L9H: {
    zip: 'L9H',
    latitude: '43.2638',
    longitude: '-79.9505',
    city: 'Dundas',
    state: 'ON',
    country: 'Canada',
  },
  L9K: {
    zip: 'L9K',
    latitude: '43.2359',
    longitude: '-79.9403',
    city: 'Ancaster East',
    state: 'ON',
    country: 'Canada',
  },
  L9L: {
    zip: 'L9L',
    latitude: '44.0905',
    longitude: '-78.9479',
    city: 'Port Perry',
    state: 'ON',
    country: 'Canada',
  },
  L9M: {
    zip: 'L9M',
    latitude: '44.7672',
    longitude: '-79.9385',
    city: 'Penetanguishene',
    state: 'ON',
    country: 'Canada',
  },
  L9N: {
    zip: 'L9N',
    latitude: '44.1315',
    longitude: '-79.4823',
    city: 'Holland Landing',
    state: 'ON',
    country: 'Canada',
  },
  L9P: {
    zip: 'L9P',
    latitude: '44.1065',
    longitude: '-79.1427',
    city: 'Uxbridge',
    state: 'ON',
    country: 'Canada',
  },
  L9R: {
    zip: 'L9R',
    latitude: '44.1513',
    longitude: '-79.8744',
    city: 'Alliston',
    state: 'ON',
    country: 'Canada',
  },
  L9S: {
    zip: 'L9S',
    latitude: '44.2871',
    longitude: '-79.6703',
    city: 'Innisfil',
    state: 'ON',
    country: 'Canada',
  },
  L9T: {
    zip: 'L9T',
    latitude: '43.5034',
    longitude: '-79.8773',
    city: 'Milton',
    state: 'ON',
    country: 'Canada',
  },
  L9V: {
    zip: 'L9V',
    latitude: '43.9471',
    longitude: '-80.1091',
    city: 'Orangeville North',
    state: 'ON',
    country: 'Canada',
  },
  L9W: {
    zip: 'L9W',
    latitude: '43.9258',
    longitude: '-80.1056',
    city: 'Orangeville South',
    state: 'ON',
    country: 'Canada',
  },
  L9Y: {
    zip: 'L9Y',
    latitude: '44.5029',
    longitude: '-80.2176',
    city: 'Collingwood',
    state: 'ON',
    country: 'Canada',
  },
  L9Z: {
    zip: 'L9Z',
    latitude: '44.5208',
    longitude: '-80.0162',
    city: 'Wasaga Beach',
    state: 'ON',
    country: 'Canada',
  },
  M1B: {
    zip: 'M1B',
    latitude: '43.7976',
    longitude: '-79.227',
    city: 'Scarborough',
    state: 'ON',
    country: 'Canada',
  },
  M1C: {
    zip: 'M1C',
    latitude: '43.7882',
    longitude: '-79.1911',
    city: 'Scarborough',
    state: 'ON',
    country: 'Canada',
  },
  M1E: {
    zip: 'M1E',
    latitude: '43.7385',
    longitude: '-79.2021',
    city: 'Scarborough',
    state: 'ON',
    country: 'Canada',
  },
  M1G: {
    zip: 'M1G',
    latitude: '43.7563',
    longitude: '-79.2224',
    city: 'Scarborough',
    state: 'ON',
    country: 'Canada',
  },
  M1H: {
    zip: 'M1H',
    latitude: '43.7563',
    longitude: '-79.2417',
    city: 'Scarborough',
    state: 'ON',
    country: 'Canada',
  },
  M1J: {
    zip: 'M1J',
    latitude: '43.7315',
    longitude: '-79.246',
    city: 'Scarborough',
    state: 'ON',
    country: 'Canada',
  },
  M1K: {
    zip: 'M1K',
    latitude: '43.7025',
    longitude: '-79.2656',
    city: 'Scarborough',
    state: 'ON',
    country: 'Canada',
  },
  M1L: {
    zip: 'M1L',
    latitude: '43.6905',
    longitude: '-79.2857',
    city: 'Scarborough',
    state: 'ON',
    country: 'Canada',
  },
  M1M: {
    zip: 'M1M',
    latitude: '43.7041',
    longitude: '-79.2446',
    city: 'Scarborough',
    state: 'ON',
    country: 'Canada',
  },
  M1N: {
    zip: 'M1N',
    latitude: '43.6748',
    longitude: '-79.2764',
    city: 'Scarborough',
    state: 'ON',
    country: 'Canada',
  },
  M1P: {
    zip: 'M1P',
    latitude: '43.7422',
    longitude: '-79.2818',
    city: 'Scarborough',
    state: 'ON',
    country: 'Canada',
  },
  M1R: {
    zip: 'M1R',
    latitude: '43.7293',
    longitude: '-79.3038',
    city: 'Scarborough',
    state: 'ON',
    country: 'Canada',
  },
  M1S: {
    zip: 'M1S',
    latitude: '43.7807',
    longitude: '-79.2855',
    city: 'Scarborough',
    state: 'ON',
    country: 'Canada',
  },
  M1T: {
    zip: 'M1T',
    latitude: '43.7719',
    longitude: '-79.3213',
    city: "Scarborough",
    state: 'ON',
    country: 'Canada',
  },
  M1V: {
    zip: 'M1V',
    latitude: '43.813',
    longitude: '-79.2781',
    city: "Scarborough",
    state: 'ON',
    country: 'Canada',
  },
  M1W: {
    zip: 'M1W',
    latitude: '43.7822',
    longitude: '-79.3261',
    city: "Scarborough",
    state: 'ON',
    country: 'Canada',
  },
  M1X: {
    zip: 'M1X',
    latitude: '43.8275',
    longitude: '-79.2437',
    city: 'Scarborough',
    state: 'ON',
    country: 'Canada',
  },
  M2H: {
    zip: 'M2H',
    latitude: '43.7895',
    longitude: '-79.3735',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M2J: {
    zip: 'M2J',
    latitude: '43.7685',
    longitude: '-79.3584',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M2K: {
    zip: 'M2K',
    latitude: '43.7657',
    longitude: '-79.3835',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M2L: {
    zip: 'M2L',
    latitude: '43.7352',
    longitude: '-79.3818',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M2M: {
    zip: 'M2M',
    latitude: '43.784',
    longitude: '-79.4263',
    city: 'Willowdale East',
    state: 'ON',
    country: 'Canada',
  },
  M2N: {
    zip: 'M2N',
    latitude: '43.7521',
    longitude: '-79.4202',
    city: 'Willowdale South',
    state: 'ON',
    country: 'Canada',
  },
  M2P: {
    zip: 'M2P',
    latitude: '43.7393',
    longitude: '-79.4005',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M2R: {
    zip: 'M2R',
    latitude: '43.7648',
    longitude: '-79.4325',
    city: 'Willowdale West',
    state: 'ON',
    country: 'Canada',
  },
  M3A: {
    zip: 'M3A',
    latitude: '43.7358',
    longitude: '-79.328',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M3B: {
    zip: 'M3B',
    latitude: '43.7363',
    longitude: '-79.3498',
    city: 'Don Mills North',
    state: 'ON',
    country: 'Canada',
  },
  M3C: {
    zip: 'M3C',
    latitude: '43.7122',
    longitude: '-79.3237',
    city: 'Don Mills South',
    state: 'ON',
    country: 'Canada',
  },
  M3H: {
    zip: 'M3H',
    latitude: '43.7387',
    longitude: '-79.4337',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M3J: {
    zip: 'M3J',
    latitude: '43.7496',
    longitude: '-79.4886',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M3K: {
    zip: 'M3K',
    latitude: '43.7271',
    longitude: '-79.4666',
    city: 'Downsview East',
    state: 'ON',
    country: 'Canada',
  },
  M3L: {
    zip: 'M3L',
    latitude: '43.7183',
    longitude: '-79.5119',
    city: 'Downsview West',
    state: 'ON',
    country: 'Canada',
  },
  M3M: {
    zip: 'M3M',
    latitude: '43.72',
    longitude: '-79.5085',
    city: 'Downsview Central',
    state: 'ON',
    country: 'Canada',
  },
  M3N: {
    zip: 'M3N',
    latitude: '43.7387',
    longitude: '-79.5166',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M4A: {
    zip: 'M4A',
    latitude: '43.7159',
    longitude: '-79.3037',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M4B: {
    zip: 'M4B',
    latitude: '43.6979',
    longitude: '-79.2986',
    city: 'East York',
    state: 'ON',
    country: 'Canada',
  },
  M4C: {
    zip: 'M4C',
    latitude: '43.68',
    longitude: '-79.3218',
    city: 'East York',
    state: 'ON',
    country: 'Canada',
  },
  M4E: {
    zip: 'M4E',
    latitude: '43.6675',
    longitude: '-79.296',
    city: 'East Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M4G: {
    zip: 'M4G',
    latitude: '43.6918',
    longitude: '-79.3708',
    city: 'East York',
    state: 'ON',
    country: 'Canada',
  },
  M4H: {
    zip: 'M4H',
    latitude: '43.7018',
    longitude: '-79.3578',
    city: 'East York',
    state: 'ON',
    country: 'Canada',
  },
  M4J: {
    zip: 'M4J',
    latitude: '43.6713',
    longitude: '-79.3412',
    city: 'East Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M4K: {
    zip: 'M4K',
    latitude: '43.6668',
    longitude: '-79.3501',
    city: 'East Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M4L: {
    zip: 'M4L',
    latitude: '43.662',
    longitude: '-79.3281',
    city: 'East Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M4M: {
    zip: 'M4M',
    latitude: '43.6505',
    longitude: '-79.3369',
    city: 'East Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M4N: {
    zip: 'M4N',
    latitude: '43.7168',
    longitude: '-79.3998',
    city: 'Central Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M4P: {
    zip: 'M4P',
    latitude: '43.7066',
    longitude: '-79.398',
    city: 'Central Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M4R: {
    zip: 'M4R',
    latitude: '43.7066',
    longitude: '-79.3996',
    city: 'Central Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M4S: {
    zip: 'M4S',
    latitude: '43.6964',
    longitude: '-79.3953',
    city: 'Central Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M4T: {
    zip: 'M4T',
    latitude: '43.6825',
    longitude: '-79.3897',
    city: 'Central Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M4V: {
    zip: 'M4V',
    latitude: '43.6778',
    longitude: '-79.3992',
    city: 'Central Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M4W: {
    zip: 'M4W',
    latitude: '43.6699',
    longitude: '-79.3887',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M4X: {
    zip: 'M4X',
    latitude: '43.6647',
    longitude: '-79.3695',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M4Y: {
    zip: 'M4Y',
    latitude: '43.6618',
    longitude: '-79.3847',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5A: {
    zip: 'M5A',
    latitude: '43.6369',
    longitude: '-79.3505',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5B: {
    zip: 'M5B',
    latitude: '43.6543',
    longitude: '-79.3796',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5C: {
    zip: 'M5C',
    latitude: '43.687',
    longitude: '-79.5318',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5E: {
    zip: 'M5E',
    latitude: '43.639',
    longitude: '-79.4499',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5G: {
    zip: 'M5G',
    latitude: '43.6519',
    longitude: '-79.3874',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5H: {
    zip: 'M5H',
    latitude: '43.649',
    longitude: '-79.3784',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5J: {
    zip: 'M5J',
    latitude: '43.6441',
    longitude: '-79.3801',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5K: {
    zip: 'M5K',
    latitude: '43.6469',
    longitude: '-79.3823',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5L: {
    zip: 'M5L',
    latitude: '43.6492',
    longitude: '-79.3823',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5M: {
    zip: 'M5M',
    latitude: '43.7248',
    longitude: '-79.4033',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M5N: {
    zip: 'M5N',
    latitude: '43.7043',
    longitude: '-79.4093',
    city: 'Central Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5P: {
    zip: 'M5P',
    latitude: '43.6981',
    longitude: '-79.3987',
    city: 'Central Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5R: {
    zip: 'M5R',
    latitude: '43.6705',
    longitude: '-79.3901',
    city: 'Central Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5S: {
    zip: 'M5S',
    latitude: '43.6619',
    longitude: '-79.3952',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5T: {
    zip: 'M5T',
    latitude: '43.6497',
    longitude: '-79.3952',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5V: {
    zip: 'M5V',
    latitude: '43.6525',
    longitude: '-79.3686',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M5W: {
    zip: 'M5W',
    latitude: '43.6437',
    longitude: '-79.3787',
    city: 'Downtown Toronto Stn A Po Boxes 25 The Esplanade',
    state: 'ON',
    country: 'Canada',
  },
  M5X: {
    zip: 'M5X',
    latitude: '43.6492',
    longitude: '-79.3823',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M6A: {
    zip: 'M6A',
    latitude: '43.7193',
    longitude: '-79.43',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M6B: {
    zip: 'M6B',
    latitude: '43.7054',
    longitude: '-79.4272',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M6C: {
    zip: 'M6C',
    latitude: '43.683',
    longitude: '-79.4184',
    city: 'York',
    state: 'ON',
    country: 'Canada',
  },
  M6E: {
    zip: 'M6E',
    latitude: '43.6797',
    longitude: '-79.4358',
    city: 'York',
    state: 'ON',
    country: 'Canada',
  },
  M6G: {
    zip: 'M6G',
    latitude: '43.6565',
    longitude: '-79.4079',
    city: 'Downtown Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M6H: {
    zip: 'M6H',
    latitude: '43.6536',
    longitude: '-79.4258',
    city: 'West Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M6J: {
    zip: 'M6J',
    latitude: '43.644',
    longitude: '-79.4062',
    city: 'West Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M6K: {
    zip: 'M6K',
    latitude: '43.6392',
    longitude: '-79.4058',
    city: 'West Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M6L: {
    zip: 'M6L',
    latitude: '43.7103',
    longitude: '-79.4714',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M6M: {
    zip: 'M6M',
    latitude: '43.6815',
    longitude: '-79.4668',
    city: 'York',
    state: 'ON',
    country: 'Canada',
  },
  M6N: {
    zip: 'M6N',
    latitude: '43.668',
    longitude: '-79.4515',
    city: 'York',
    state: 'ON',
    country: 'Canada',
  },
  M6P: {
    zip: 'M6P',
    latitude: '43.6558',
    longitude: '-79.4663',
    city: 'West Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M6R: {
    zip: 'M6R',
    latitude: '43.6403',
    longitude: '-79.4374',
    city: 'West Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M6S: {
    zip: 'M6S',
    latitude: '43.6358',
    longitude: '-79.4668',
    city: 'West Toronto',
    state: 'ON',
    country: 'Canada',
  },
  M7A: {
    zip: 'M7A',
    latitude: '43.6641',
    longitude: '-79.3889',
    city: "Queen's Park Ontario Provincial Government",
    state: 'ON',
    country: 'Canada',
  },
  M7Y: {
    zip: 'M7Y',
    latitude: '43.7804',
    longitude: '-79.2505',
    city: 'East Toronto Business Reply Mail Processing Centre 969 Eastern',
    state: 'ON',
    country: 'Canada',
  },
  M8V: {
    zip: 'M8V',
    latitude: '43.6305',
    longitude: '-79.4762',
    city: 'Etobicoke',
    state: 'ON',
    country: 'Canada',
  },
  M8W: {
    zip: 'M8W',
    latitude: '43.5908',
    longitude: '-79.5218',
    city: 'Etobicoke',
    state: 'ON',
    country: 'Canada',
  },
  M8X: {
    zip: 'M8X',
    latitude: '43.649',
    longitude: '-79.4977',
    city: 'Etobicoke',
    state: 'ON',
    country: 'Canada',
  },
  M8Y: {
    zip: 'M8Y',
    latitude: '43.6181',
    longitude: '-79.4967',
    city: "Etobicoke",
    state: 'ON',
    country: 'Canada',
  },
  M8Z: {
    zip: 'M8Z',
    latitude: '43.6053',
    longitude: '-79.5201',
    city: 'Etobicoke',
    state: 'ON',
    country: 'Canada',
  },
  M9A: {
    zip: 'M9A',
    latitude: '43.6434',
    longitude: '-79.5297',
    city: 'Etobicoke',
    state: 'ON',
    country: 'Canada',
  },
  M9B: {
    zip: 'M9B',
    latitude: '43.6383',
    longitude: '-79.5356',
    city: 'Etobicoke',
    state: 'ON',
    country: 'Canada',
  },
  M9C: {
    zip: 'M9C',
    latitude: '43.6088',
    longitude: '-79.5574',
    city: 'Etobicoke',
    state: 'ON',
    country: 'Canada',
  },
  M9L: {
    zip: 'M9L',
    latitude: '43.7494',
    longitude: '-79.5614',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M9M: {
    zip: 'M9M',
    latitude: '43.7182',
    longitude: '-79.5216',
    city: 'North York',
    state: 'ON',
    country: 'Canada',
  },
  M9N: {
    zip: 'M9N',
    latitude: '43.7087',
    longitude: '-79.5287',
    city: 'Weston',
    state: 'ON',
    country: 'Canada',
  },
  M9P: {
    zip: 'M9P',
    latitude: '43.6814',
    longitude: '-79.5367',
    city: 'Etobicoke',
    state: 'ON',
    country: 'Canada',
  },
  M9R: {
    zip: 'M9R',
    latitude: '43.6808',
    longitude: '-79.5438',
    city: 'Etobicoke',
    state: 'ON',
    country: 'Canada',
  },
  M9V: {
    zip: 'M9V',
    latitude: '43.73',
    longitude: '-79.5542',
    city: 'Etobicoke',
    state: 'ON',
    country: 'Canada',
  },
  M9W: {
    zip: 'M9W',
    latitude: '43.6772',
    longitude: '-79.5894',
    city: 'Etobicoke Northwest',
    state: 'ON',
    country: 'Canada',
  },
  N0A: {
    zip: 'N0A',
    latitude: '42.9466',
    longitude: '-79.8509',
    city: 'West Haldimand',
    state: 'ON',
    country: 'Canada',
  },
  N0B: {
    zip: 'N0B',
    latitude: '43.7722',
    longitude: '-80.6586',
    city: 'Wellington',
    state: 'ON',
    country: 'Canada',
  },
  N0C: {
    zip: 'N0C',
    latitude: '44.2999',
    longitude: '-80.4804',
    city: 'Georgian Bay Southwest Shore',
    state: 'ON',
    country: 'Canada',
  },
  N0E: {
    zip: 'N0E',
    latitude: '43.0986',
    longitude: '-80.5633',
    city: 'Brant And Norfolk',
    state: 'ON',
    country: 'Canada',
  },
  N0G: {
    zip: 'N0G',
    latitude: '43.8567',
    longitude: '-81.4023',
    city: 'Huron',
    state: 'ON',
    country: 'Canada',
  },
  N0H: {
    zip: 'N0H',
    latitude: '44.3483',
    longitude: '-80.914',
    city: 'Bruce Peninsula',
    state: 'ON',
    country: 'Canada',
  },
  N0J: {
    zip: 'N0J',
    latitude: '43.221',
    longitude: '-80.5613',
    city: 'Oxford',
    state: 'ON',
    country: 'Canada',
  },
  N0K: {
    zip: 'N0K',
    latitude: '43.5838',
    longitude: '-81.2351',
    city: 'Perth',
    state: 'ON',
    country: 'Canada',
  },
  N0L: {
    zip: 'N0L',
    latitude: '42.8188',
    longitude: '-81.6437',
    city: 'Elgin',
    state: 'ON',
    country: 'Canada',
  },
  N0M: {
    zip: 'N0M',
    latitude: '43.5651',
    longitude: '-81.6986',
    city: 'Middlesex',
    state: 'ON',
    country: 'Canada',
  },
  N0N: {
    zip: 'N0N',
    latitude: '42.7967',
    longitude: '-81.7938',
    city: 'Lambton',
    state: 'ON',
    country: 'Canada',
  },
  N0P: {
    zip: 'N0P',
    latitude: '42.5323',
    longitude: '-81.7991',
    city: 'Kent',
    state: 'ON',
    country: 'Canada',
  },
  N0R: {
    zip: 'N0R',
    latitude: '42.2932',
    longitude: '-82.7075',
    city: 'Essex',
    state: 'ON',
    country: 'Canada',
  },
  N1A: {
    zip: 'N1A',
    latitude: '42.9132',
    longitude: '-79.6101',
    city: 'Dunnville',
    state: 'ON',
    country: 'Canada',
  },
  N1C: {
    zip: 'N1C',
    latitude: '43.5036',
    longitude: '-80.2394',
    city: 'Guelph South',
    state: 'ON',
    country: 'Canada',
  },
  N1E: {
    zip: 'N1E',
    latitude: '43.5749',
    longitude: '-80.2688',
    city: 'Guelph North',
    state: 'ON',
    country: 'Canada',
  },
  N1G: {
    zip: 'N1G',
    latitude: '43.5325',
    longitude: '-80.2531',
    city: 'Guelph Central',
    state: 'ON',
    country: 'Canada',
  },
  N1H: {
    zip: 'N1H',
    latitude: '43.555',
    longitude: '-80.2868',
    city: 'Guelph Northwest',
    state: 'ON',
    country: 'Canada',
  },
  N1K: {
    zip: 'N1K',
    latitude: '43.5156',
    longitude: '-80.2827',
    city: 'Guelph West',
    state: 'ON',
    country: 'Canada',
  },
  N1L: {
    zip: 'N1L',
    latitude: '43.5225',
    longitude: '-80.2095',
    city: 'Guelph East',
    state: 'ON',
    country: 'Canada',
  },
  N1M: {
    zip: 'N1M',
    latitude: '43.7157',
    longitude: '-80.387',
    city: 'Fergus',
    state: 'ON',
    country: 'Canada',
  },
  N1P: {
    zip: 'N1P',
    latitude: '43.3372',
    longitude: '-80.3021',
    city: 'Cambridge South',
    state: 'ON',
    country: 'Canada',
  },
  N1R: {
    zip: 'N1R',
    latitude: '43.3831',
    longitude: '-80.3191',
    city: 'Cambridge Central',
    state: 'ON',
    country: 'Canada',
  },
  N1S: {
    zip: 'N1S',
    latitude: '43.3742',
    longitude: '-80.3457',
    city: 'Cambridge Southwest',
    state: 'ON',
    country: 'Canada',
  },
  N1T: {
    zip: 'N1T',
    latitude: '43.4067',
    longitude: '-80.3037',
    city: 'Cambridge East',
    state: 'ON',
    country: 'Canada',
  },
  N2A: {
    zip: 'N2A',
    latitude: '43.4353',
    longitude: '-80.4527',
    city: 'Kitchener East',
    state: 'ON',
    country: 'Canada',
  },
  N2B: {
    zip: 'N2B',
    latitude: '43.448',
    longitude: '-80.4589',
    city: 'Kitchener Northeast',
    state: 'ON',
    country: 'Canada',
  },
  N2C: {
    zip: 'N2C',
    latitude: '43.4346',
    longitude: '-80.4532',
    city: 'Kitchener South Central',
    state: 'ON',
    country: 'Canada',
  },
  N2E: {
    zip: 'N2E',
    latitude: '43.4236',
    longitude: '-80.48',
    city: 'Kitchener Southwest',
    state: 'ON',
    country: 'Canada',
  },
  N2G: {
    zip: 'N2G',
    latitude: '43.4497',
    longitude: '-80.4893',
    city: 'Kitchener Central',
    state: 'ON',
    country: 'Canada',
  },
  N2H: {
    zip: 'N2H',
    latitude: '43.4487',
    longitude: '-80.4849',
    city: 'Kitchener North Central',
    state: 'ON',
    country: 'Canada',
  },
  N2J: {
    zip: 'N2J',
    latitude: '43.4613',
    longitude: '-80.507',
    city: 'Waterloo Southeast',
    state: 'ON',
    country: 'Canada',
  },
  N2K: {
    zip: 'N2K',
    latitude: '43.4801',
    longitude: '-80.4801',
    city: 'Kitchener North',
    state: 'ON',
    country: 'Canada',
  },
  N2L: {
    zip: 'N2L',
    latitude: '43.4529',
    longitude: '-80.5281',
    city: 'Waterloo South',
    state: 'ON',
    country: 'Canada',
  },
  N2M: {
    zip: 'N2M',
    latitude: '43.4422',
    longitude: '-80.4968',
    city: 'Kitchener Northwest',
    state: 'ON',
    country: 'Canada',
  },
  N2N: {
    zip: 'N2N',
    latitude: '43.4241',
    longitude: '-80.5214',
    city: 'Kitchener West',
    state: 'ON',
    country: 'Canada',
  },
  N2P: {
    zip: 'N2P',
    latitude: '43.3938',
    longitude: '-80.4443',
    city: 'Kitchener Southeast',
    state: 'ON',
    country: 'Canada',
  },
  N2R: {
    zip: 'N2R',
    latitude: '43.3965',
    longitude: '-80.4575',
    city: 'Kitchener South',
    state: 'ON',
    country: 'Canada',
  },
  N2T: {
    zip: 'N2T',
    latitude: '43.4511',
    longitude: '-80.5572',
    city: 'Waterloo Southwest',
    state: 'ON',
    country: 'Canada',
  },
  N2V: {
    zip: 'N2V',
    latitude: '43.5036',
    longitude: '-80.5413',
    city: 'Waterloo Northwest',
    state: 'ON',
    country: 'Canada',
  },
  N2Z: {
    zip: 'N2Z',
    latitude: '44.1821',
    longitude: '-81.6373',
    city: 'Kincardine',
    state: 'ON',
    country: 'Canada',
  },
  N3A: {
    zip: 'N3A',
    latitude: '43.4161',
    longitude: '-80.688',
    city: 'Baden',
    state: 'ON',
    country: 'Canada',
  },
  N3B: {
    zip: 'N3B',
    latitude: '43.5852',
    longitude: '-80.5662',
    city: 'Elmira',
    state: 'ON',
    country: 'Canada',
  },
  N3C: {
    zip: 'N3C',
    latitude: '43.4317',
    longitude: '-80.3112',
    city: 'Cambridge Northeast',
    state: 'ON',
    country: 'Canada',
  },
  N3E: {
    zip: 'N3E',
    latitude: '43.4244',
    longitude: '-80.3364',
    city: 'Cambridge Northwest',
    state: 'ON',
    country: 'Canada',
  },
  N3H: {
    zip: 'N3H',
    latitude: '43.4061',
    longitude: '-80.3503',
    city: 'Cambridge West',
    state: 'ON',
    country: 'Canada',
  },
  N3L: {
    zip: 'N3L',
    latitude: '43.1834',
    longitude: '-80.3749',
    city: 'Paris',
    state: 'ON',
    country: 'Canada',
  },
  N3P: {
    zip: 'N3P',
    latitude: '43.1884',
    longitude: '-80.2422',
    city: 'Brantford Northeast',
    state: 'ON',
    country: 'Canada',
  },
  N3R: {
    zip: 'N3R',
    latitude: '43.1501',
    longitude: '-80.2766',
    city: 'Brantford Central',
    state: 'ON',
    country: 'Canada',
  },
  N3S: {
    zip: 'N3S',
    latitude: '43.1242',
    longitude: '-80.2412',
    city: 'Brantford Southeast',
    state: 'ON',
    country: 'Canada',
  },
  N3T: {
    zip: 'N3T',
    latitude: '43.1094',
    longitude: '-80.275',
    city: 'Brantford Southwest',
    state: 'ON',
    country: 'Canada',
  },
  N3V: {
    zip: 'N3V',
    latitude: '43.1704',
    longitude: '-80.2937',
    city: 'Brantford Northwest',
    state: 'ON',
    country: 'Canada',
  },
  N3W: {
    zip: 'N3W',
    latitude: '43.0776',
    longitude: '-79.9639',
    city: 'Caledonia',
    state: 'ON',
    country: 'Canada',
  },
  N3Y: {
    zip: 'N3Y',
    latitude: '42.8126',
    longitude: '-80.3091',
    city: 'Simcoe',
    state: 'ON',
    country: 'Canada',
  },
  N4B: {
    zip: 'N4B',
    latitude: '42.824',
    longitude: '-80.4811',
    city: 'Delhi',
    state: 'ON',
    country: 'Canada',
  },
  N4G: {
    zip: 'N4G',
    latitude: '42.8806',
    longitude: '-80.7527',
    city: 'Tillsonburg',
    state: 'ON',
    country: 'Canada',
  },
  N4K: {
    zip: 'N4K',
    latitude: '44.5519',
    longitude: '-80.9385',
    city: 'Owen Sound',
    state: 'ON',
    country: 'Canada',
  },
  N4L: {
    zip: 'N4L',
    latitude: '44.6079',
    longitude: '-80.5922',
    city: 'Meaford',
    state: 'ON',
    country: 'Canada',
  },
  N4N: {
    zip: 'N4N',
    latitude: '44.1385',
    longitude: '-81.0237',
    city: 'Hanover',
    state: 'ON',
    country: 'Canada',
  },
  N4S: {
    zip: 'N4S',
    latitude: '43.1277',
    longitude: '-80.7743',
    city: 'Woodstock Central',
    state: 'ON',
    country: 'Canada',
  },
  N4T: {
    zip: 'N4T',
    latitude: '43.1477',
    longitude: '-80.7285',
    city: 'Woodstock North',
    state: 'ON',
    country: 'Canada',
  },
  N4V: {
    zip: 'N4V',
    latitude: '43.1127',
    longitude: '-80.7368',
    city: 'Woodstock South',
    state: 'ON',
    country: 'Canada',
  },
  N4W: {
    zip: 'N4W',
    latitude: '43.7315',
    longitude: '-80.9533',
    city: 'Listowel',
    state: 'ON',
    country: 'Canada',
  },
  N4X: {
    zip: 'N4X',
    latitude: '43.261',
    longitude: '-81.1516',
    city: "St. Mary's",
    state: 'ON',
    country: 'Canada',
  },
  N4Z: {
    zip: 'N4Z',
    latitude: '43.3555',
    longitude: '-80.9961',
    city: 'Stratford South',
    state: 'ON',
    country: 'Canada',
  },
  N5A: {
    zip: 'N5A',
    latitude: '43.3717',
    longitude: '-80.9844',
    city: 'Stratford North',
    state: 'ON',
    country: 'Canada',
  },
  N5C: {
    zip: 'N5C',
    latitude: '43.027',
    longitude: '-80.8706',
    city: 'Ingersoll',
    state: 'ON',
    country: 'Canada',
  },
  N5H: {
    zip: 'N5H',
    latitude: '42.7797',
    longitude: '-80.9864',
    city: 'Aylmer',
    state: 'ON',
    country: 'Canada',
  },
  N5L: {
    zip: 'N5L',
    latitude: '42.6652',
    longitude: '-81.2018',
    city: 'Port Stanley',
    state: 'ON',
    country: 'Canada',
  },
  N5P: {
    zip: 'N5P',
    latitude: '42.7788',
    longitude: '-81.2134',
    city: 'St. Thomas North',
    state: 'ON',
    country: 'Canada',
  },
  N5R: {
    zip: 'N5R',
    latitude: '42.7725',
    longitude: '-81.2003',
    city: 'St. Thomas South',
    state: 'ON',
    country: 'Canada',
  },
  N5V: {
    zip: 'N5V',
    latitude: '42.9927',
    longitude: '-81.1686',
    city: 'London',
    state: 'ON',
    country: 'Canada',
  },
  N5W: {
    zip: 'N5W',
    latitude: '42.9778',
    longitude: '-81.1941',
    city: 'London East',
    state: 'ON',
    country: 'Canada',
  },
  N5X: {
    zip: 'N5X',
    latitude: '43.0303',
    longitude: '-81.2676',
    city: 'London',
    state: 'ON',
    country: 'Canada',
  },
  N5Y: {
    zip: 'N5Y',
    latitude: '43.0093',
    longitude: '-81.21',
    city: 'London',
    state: 'ON',
    country: 'Canada',
  },
  N5Z: {
    zip: 'N5Z',
    latitude: '42.9743',
    longitude: '-81.1946',
    city: 'London',
    state: 'ON',
    country: 'Canada',
  },
  N6A: {
    zip: 'N6A',
    latitude: '42.9793',
    longitude: '-81.2556',
    city: 'London North',
    state: 'ON',
    country: 'Canada',
  },
  N6B: {
    zip: 'N6B',
    latitude: '42.9759',
    longitude: '-81.229',
    city: 'London Central',
    state: 'ON',
    country: 'Canada',
  },
  N6C: {
    zip: 'N6C',
    latitude: '42.9799',
    longitude: '-81.2609',
    city: 'London South',
    state: 'ON',
    country: 'Canada',
  },
  N6E: {
    zip: 'N6E',
    latitude: '42.9419',
    longitude: '-81.2475',
    city: 'London',
    state: 'ON',
    country: 'Canada',
  },
  N6G: {
    zip: 'N6G',
    latitude: '42.9943',
    longitude: '-81.2623',
    city: 'London',
    state: 'ON',
    country: 'Canada',
  },
  N6H: {
    zip: 'N6H',
    latitude: '42.9899',
    longitude: '-81.2607',
    city: 'London West',
    state: 'ON',
    country: 'Canada',
  },
  N6J: {
    zip: 'N6J',
    latitude: '42.9797',
    longitude: '-81.2639',
    city: 'London',
    state: 'ON',
    country: 'Canada',
  },
  N6K: {
    zip: 'N6K',
    latitude: '42.9627',
    longitude: '-81.2948',
    city: 'London',
    state: 'ON',
    country: 'Canada',
  },
  N6L: {
    zip: 'N6L',
    latitude: '42.9344',
    longitude: '-81.2802',
    city: 'London',
    state: 'ON',
    country: 'Canada',
  },
  N6M: {
    zip: 'N6M',
    latitude: '42.9922',
    longitude: '-81.1398',
    city: 'London',
    state: 'ON',
    country: 'Canada',
  },
  N6N: {
    zip: 'N6N',
    latitude: '42.9324',
    longitude: '-81.1916',
    city: 'London',
    state: 'ON',
    country: 'Canada',
  },
  N6P: {
    zip: 'N6P',
    latitude: '42.9114',
    longitude: '-81.2999',
    city: 'London',
    state: 'ON',
    country: 'Canada',
  },
  N7A: {
    zip: 'N7A',
    latitude: '43.7347',
    longitude: '-81.7105',
    city: 'Goderich',
    state: 'ON',
    country: 'Canada',
  },
  N7G: {
    zip: 'N7G',
    latitude: '42.9625',
    longitude: '-81.6081',
    city: 'Strathroy',
    state: 'ON',
    country: 'Canada',
  },
  N7L: {
    zip: 'N7L',
    latitude: '42.4029',
    longitude: '-82.1941',
    city: 'Chatham Northwest',
    state: 'ON',
    country: 'Canada',
  },
  N7M: {
    zip: 'N7M',
    latitude: '42.3997',
    longitude: '-82.1996',
    city: 'Chatham Southeast',
    state: 'ON',
    country: 'Canada',
  },
  N7S: {
    zip: 'N7S',
    latitude: '42.9607',
    longitude: '-82.3718',
    city: 'Sarnia Central',
    state: 'ON',
    country: 'Canada',
  },
  N7T: {
    zip: 'N7T',
    latitude: '42.971',
    longitude: '-82.4084',
    city: 'Sarnia Southwest',
    state: 'ON',
    country: 'Canada',
  },
  N7V: {
    zip: 'N7V',
    latitude: '42.9891',
    longitude: '-82.399',
    city: 'Sarnia Northwest',
    state: 'ON',
    country: 'Canada',
  },
  N7W: {
    zip: 'N7W',
    latitude: '42.9838',
    longitude: '-82.3214',
    city: 'Sarnia Southeast',
    state: 'ON',
    country: 'Canada',
  },
  N7X: {
    zip: 'N7X',
    latitude: '43.0147',
    longitude: '-82.3417',
    city: 'Sarnia Northeast',
    state: 'ON',
    country: 'Canada',
  },
  N8A: {
    zip: 'N8A',
    latitude: '42.5799',
    longitude: '-82.3823',
    city: 'Wallaceburg',
    state: 'ON',
    country: 'Canada',
  },
  N8H: {
    zip: 'N8H',
    latitude: '42.0606',
    longitude: '-82.6029',
    city: 'Leamington',
    state: 'ON',
    country: 'Canada',
  },
  N8M: {
    zip: 'N8M',
    latitude: '42.1754',
    longitude: '-82.8226',
    city: 'Essex',
    state: 'ON',
    country: 'Canada',
  },
  N8N: {
    zip: 'N8N',
    latitude: '42.3326',
    longitude: '-82.8926',
    city: 'Tecumseh Outskirts',
    state: 'ON',
    country: 'Canada',
  },
  N8P: {
    zip: 'N8P',
    latitude: '42.3391',
    longitude: '-82.9279',
    city: 'Windsor',
    state: 'ON',
    country: 'Canada',
  },
  N8R: {
    zip: 'N8R',
    latitude: '42.3136',
    longitude: '-82.9338',
    city: 'Windsor',
    state: 'ON',
    country: 'Canada',
  },
  N8S: {
    zip: 'N8S',
    latitude: '42.3307',
    longitude: '-82.9752',
    city: 'Windsor',
    state: 'ON',
    country: 'Canada',
  },
  N8T: {
    zip: 'N8T',
    latitude: '42.3188',
    longitude: '-82.965',
    city: 'Windsor',
    state: 'ON',
    country: 'Canada',
  },
  N8V: {
    zip: 'N8V',
    latitude: '42.2679',
    longitude: '-82.9699',
    city: 'Tecumseh',
    state: 'ON',
    country: 'Canada',
  },
  N8W: {
    zip: 'N8W',
    latitude: '42.3062',
    longitude: '-83.0017',
    city: 'Windsor',
    state: 'ON',
    country: 'Canada',
  },
  N8X: {
    zip: 'N8X',
    latitude: '42.3039',
    longitude: '-83.0308',
    city: 'Windsor South Central',
    state: 'ON',
    country: 'Canada',
  },
  N8Y: {
    zip: 'N8Y',
    latitude: '42.3251',
    longitude: '-83.0171',
    city: 'Windsor East',
    state: 'ON',
    country: 'Canada',
  },
  N9A: {
    zip: 'N9A',
    latitude: '42.3159',
    longitude: '-83.0393',
    city: 'Windsor',
    state: 'ON',
    country: 'Canada',
  },
  N9B: {
    zip: 'N9B',
    latitude: '42.3158',
    longitude: '-83.0568',
    city: 'Windsor',
    state: 'ON',
    country: 'Canada',
  },
  N9C: {
    zip: 'N9C',
    latitude: '42.3077',
    longitude: '-83.0724',
    city: 'Windsor',
    state: 'ON',
    country: 'Canada',
  },
  N9E: {
    zip: 'N9E',
    latitude: '42.2736',
    longitude: '-83.0416',
    city: 'Windsor South',
    state: 'ON',
    country: 'Canada',
  },
  N9G: {
    zip: 'N9G',
    latitude: '42.2581',
    longitude: '-82.9988',
    city: 'Windsor',
    state: 'ON',
    country: 'Canada',
  },
  N9H: {
    zip: 'N9H',
    latitude: '42.2351',
    longitude: '-82.998',
    city: 'La Salle East',
    state: 'ON',
    country: 'Canada',
  },
  N9J: {
    zip: 'N9J',
    latitude: '42.247',
    longitude: '-83.1',
    city: 'La Salle West',
    state: 'ON',
    country: 'Canada',
  },
  N9K: {
    zip: 'N9K',
    latitude: '42.049',
    longitude: '-83.1032',
    city: 'Tecumseh Central',
    state: 'ON',
    country: 'Canada',
  },
  N9V: {
    zip: 'N9V',
    latitude: '42.1106',
    longitude: '-83.1115',
    city: 'Amherstburg',
    state: 'ON',
    country: 'Canada',
  },
  N9Y: {
    zip: 'N9Y',
    latitude: '42.0377',
    longitude: '-82.7394',
    city: 'Kingsville',
    state: 'ON',
    country: 'Canada',
  },
  P0A: {
    zip: 'P0A',
    latitude: '45.4139',
    longitude: '-79.6728',
    city: "Nipissing Central",
    state: 'ON',
    country: 'Canada',
  },
  P0B: {
    zip: 'P0B',
    latitude: '45.1103',
    longitude: '-79.158',
    city: 'Nipissing South',
    state: 'ON',
    country: 'Canada',
  },
  P0C: {
    zip: 'P0C',
    latitude: '44.8462',
    longitude: '-79.7954',
    city: 'Parry Sound Mid-shore',
    state: 'ON',
    country: 'Canada',
  },
  P0E: {
    zip: 'P0E',
    latitude: '44.8935',
    longitude: '-79.741',
    city: 'Parry Sound South Shore',
    state: 'ON',
    country: 'Canada',
  },
  P0G: {
    zip: 'P0G',
    latitude: '45.9033',
    longitude: '-80.5762',
    city: 'Parry Sound North Shore',
    state: 'ON',
    country: 'Canada',
  },
  P0H: {
    zip: 'P0H',
    latitude: '45.8738',
    longitude: '-79.8846',
    city: 'Nipissing North',
    state: 'ON',
    country: 'Canada',
  },
  P0J: {
    zip: 'P0J',
    latitude: '47.6756',
    longitude: '-79.5424',
    city: 'Timiskaming South',
    state: 'ON',
    country: 'Canada',
  },
  P0K: {
    zip: 'P0K',
    latitude: '48.1346',
    longitude: '-80.0769',
    city: 'Timiskaming North',
    state: 'ON',
    country: 'Canada',
  },
  P0L: {
    zip: 'P0L',
    latitude: '52.923',
    longitude: '-82.4173',
    city: 'Cochrane Region',
    state: 'ON',
    country: 'Canada',
  },
  P0M: {
    zip: 'P0M',
    latitude: 'null',
    longitude: '46.1329',
    city: 'Algoma',
    state: 'Sudbury District and Greater Sudbury',
    country: 'Canada',
  },
  P0N: {
    zip: 'P0N',
    latitude: '48.4466',
    longitude: '-80.8161',
    city: 'Timmins Region',
    state: 'ON',
    country: 'Canada',
  },
  P0P: {
    zip: 'P0P',
    latitude: '46.0182',
    longitude: '-82.2507',
    city: 'Manitoulin',
    state: 'ON',
    country: 'Canada',
  },
  P0R: {
    zip: 'P0R',
    latitude: '46.1849',
    longitude: '-82.8228',
    city: 'Algoma Southwest',
    state: 'ON',
    country: 'Canada',
  },
  P0S: {
    zip: 'P0S',
    latitude: '46.9551',
    longitude: '-84.5005',
    city: 'Lake Superior East Shore',
    state: 'ON',
    country: 'Canada',
  },
  P0T: {
    zip: 'P0T',
    latitude: '50.139',
    longitude: '-89.0561',
    city: 'Lake Superior North Shore',
    state: 'ON',
    country: 'Canada',
  },
  P0V: {
    zip: 'P0V',
    latitude: '50.2407',
    longitude: '-90.2024',
    city: 'Northwestern Ontario',
    state: 'ON',
    country: 'Canada',
  },
  P0W: {
    zip: 'P0W',
    latitude: '48.7778',
    longitude: '-93.962',
    city: 'Rainy River Region',
    state: 'ON',
    country: 'Canada',
  },
  P0X: {
    zip: 'P0X',
    latitude: '49.7003',
    longitude: '-94.8583',
    city: 'Kenora Region',
    state: 'ON',
    country: 'Canada',
  },
  P0Y: {
    zip: 'P0Y',
    latitude: '49.7857',
    longitude: '-95.1168',
    city: 'Lake Of The Woods East Shore',
    state: 'ON',
    country: 'Canada',
  },
  P1A: {
    zip: 'P1A',
    latitude: '46.3036',
    longitude: '-79.4624',
    city: 'North Bay South',
    state: 'ON',
    country: 'Canada',
  },
  P1B: {
    zip: 'P1B',
    latitude: '46.3094',
    longitude: '-79.464',
    city: 'North Bay Central',
    state: 'ON',
    country: 'Canada',
  },
  P1C: {
    zip: 'P1C',
    latitude: '46.3411',
    longitude: '-79.4457',
    city: 'North Bay North',
    state: 'ON',
    country: 'Canada',
  },
  P1H: {
    zip: 'P1H',
    latitude: '45.3272',
    longitude: '-79.2151',
    city: 'Huntsville',
    state: 'ON',
    country: 'Canada',
  },
  P1L: {
    zip: 'P1L',
    latitude: '45.057',
    longitude: '-79.3366',
    city: 'Bracebridge',
    state: 'ON',
    country: 'Canada',
  },
  P1P: {
    zip: 'P1P',
    latitude: '44.9451',
    longitude: '-79.3549',
    city: 'Gravenhurst',
    state: 'ON',
    country: 'Canada',
  },
  P2A: {
    zip: 'P2A',
    latitude: '45.3405',
    longitude: '-80.0365',
    city: 'Parry Sound',
    state: 'ON',
    country: 'Canada',
  },
  P2B: {
    zip: 'P2B',
    latitude: '46.3664',
    longitude: '-79.9178',
    city: 'Sturgeon Falls',
    state: 'ON',
    country: 'Canada',
  },
  P2N: {
    zip: 'P2N',
    latitude: '48.151',
    longitude: '-80.0328',
    city: 'Kirkland Lake',
    state: 'ON',
    country: 'Canada',
  },
  P3A: {
    zip: 'P3A',
    latitude: '46.5076',
    longitude: '-80.9872',
    city: 'Greater Sudbury',
    state: 'ON',
    country: 'Canada',
  },
  P3B: {
    zip: 'P3B',
    latitude: '46.4769',
    longitude: '-80.9099',
    city: 'Greater Sudbury',
    state: 'ON',
    country: 'Canada',
  },
  P3C: {
    zip: 'P3C',
    latitude: '46.4727',
    longitude: '-81.0291',
    city: 'Greater Sudbury',
    state: 'ON',
    country: 'Canada',
  },
  P3E: {
    zip: 'P3E',
    latitude: '46.4918',
    longitude: '-80.9955',
    city: 'Greater Sudbury',
    state: 'ON',
    country: 'Canada',
  },
  P3G: {
    zip: 'P3G',
    latitude: '46.4106',
    longitude: '-81.0517',
    city: 'Greater Sudbury',
    state: 'ON',
    country: 'Canada',
  },
  P3L: {
    zip: 'P3L',
    latitude: '46.5625',
    longitude: '-80.8665',
    city: 'Greater Sudbury',
    state: 'ON',
    country: 'Canada',
  },
  P3N: {
    zip: 'P3N',
    latitude: '46.6191',
    longitude: '-81.0356',
    city: 'Greater Sudbury',
    state: 'ON',
    country: 'Canada',
  },
  P3P: {
    zip: 'P3P',
    latitude: '46.6318',
    longitude: '-81.0147',
    city: 'Greater Sudbury',
    state: 'ON',
    country: 'Canada',
  },
  P3Y: {
    zip: 'P3Y',
    latitude: '46.4223',
    longitude: '-81.1165',
    city: 'Greater Sudbury',
    state: 'ON',
    country: 'Canada',
  },
  P4N: {
    zip: 'P4N',
    latitude: '48.4757',
    longitude: '-81.3366',
    city: 'Timmins Southeast',
    state: 'ON',
    country: 'Canada',
  },
  P4P: {
    zip: 'P4P',
    latitude: '48.4951',
    longitude: '-81.3513',
    city: 'Timmins North',
    state: 'ON',
    country: 'Canada',
  },
  P4R: {
    zip: 'P4R',
    latitude: '48.473',
    longitude: '-81.3765',
    city: 'Timmins West',
    state: 'ON',
    country: 'Canada',
  },
  P5A: {
    zip: 'P5A',
    latitude: '46.372',
    longitude: '-82.6721',
    city: 'Elliot Lake',
    state: 'ON',
    country: 'Canada',
  },
  P5E: {
    zip: 'P5E',
    latitude: '46.2629',
    longitude: '-81.7719',
    city: 'Espanola',
    state: 'ON',
    country: 'Canada',
  },
  P5N: {
    zip: 'P5N',
    latitude: '49.4134',
    longitude: '-82.4203',
    city: 'Kapuskasing',
    state: 'ON',
    country: 'Canada',
  },
  P6A: {
    zip: 'P6A',
    latitude: '46.5175',
    longitude: '-84.3414',
    city: 'Sault Ste. Marie East',
    state: 'ON',
    country: 'Canada',
  },
  P6B: {
    zip: 'P6B',
    latitude: '46.5105',
    longitude: '-84.321',
    city: 'Sault Ste. Marie Central',
    state: 'ON',
    country: 'Canada',
  },
  P6C: {
    zip: 'P6C',
    latitude: '46.5245',
    longitude: '-84.3768',
    city: 'Sault Ste. Marie North',
    state: 'ON',
    country: 'Canada',
  },
  P7A: {
    zip: 'P7A',
    latitude: '48.4578',
    longitude: '-89.1885',
    city: 'Thunder Bay Northeast',
    state: 'ON',
    country: 'Canada',
  },
  P7B: {
    zip: 'P7B',
    latitude: '48.4349',
    longitude: '-89.2192',
    city: 'Thunder Bay North Central',
    state: 'ON',
    country: 'Canada',
  },
  P7C: {
    zip: 'P7C',
    latitude: '48.3852',
    longitude: '-89.242',
    city: 'Thunder Bay Central',
    state: 'ON',
    country: 'Canada',
  },
  P7E: {
    zip: 'P7E',
    latitude: '48.3775',
    longitude: '-89.2704',
    city: 'Thunder Bay South Central',
    state: 'ON',
    country: 'Canada',
  },
  P7G: {
    zip: 'P7G',
    latitude: '48.4511',
    longitude: '-89.273',
    city: 'Thunder Bay North',
    state: 'ON',
    country: 'Canada',
  },
  P7J: {
    zip: 'P7J',
    latitude: '48.3187',
    longitude: '-89.3415',
    city: 'Thunder Bay South',
    state: 'ON',
    country: 'Canada',
  },
  P7K: {
    zip: 'P7K',
    latitude: '48.3959',
    longitude: '-89.3556',
    city: 'Thunder Bay West',
    state: 'ON',
    country: 'Canada',
  },
  P8N: {
    zip: 'P8N',
    latitude: '49.7856',
    longitude: '-92.8364',
    city: 'Dryden',
    state: 'ON',
    country: 'Canada',
  },
  P8T: {
    zip: 'P8T',
    latitude: '50.0885',
    longitude: '-91.9086',
    city: 'Sioux Lookout',
    state: 'ON',
    country: 'Canada',
  },
  P9A: {
    zip: 'P9A',
    latitude: '48.6075',
    longitude: '-93.3869',
    city: 'Fort Frances',
    state: 'ON',
    country: 'Canada',
  },
  P9N: {
    zip: 'P9N',
    latitude: '49.7667',
    longitude: '-94.4848',
    city: 'Kenora',
    state: 'ON',
    country: 'Canada',
  },
  C0A: {
    zip: 'C0A',
    latitude: '46.1668',
    longitude: '-62.6487',
    city: 'Montague',
    state: 'PE',
    country: 'Canada',
  },
  C0B: {
    zip: 'C0B',
    latitude: '46.3182',
    longitude: '-63.5586',
    city: 'Prince County',
    state: 'PE',
    country: 'Canada',
  },
  C1A: {
    zip: 'C1A',
    latitude: '46.2318',
    longitude: '-63.1192',
    city: 'Charlottetown Southeast Prince Edward Island Provincial Government',
    state: 'PE',
    country: 'Canada',
  },
  C1B: {
    zip: 'C1B',
    latitude: '46.2067',
    longitude: '-63.0729',
    city: 'Stratford',
    state: 'PE',
    country: 'Canada',
  },
  C1C: {
    zip: 'C1C',
    latitude: '46.2688',
    longitude: '-63.1097',
    city: 'Charlottetown North',
    state: 'PE',
    country: 'Canada',
  },
  C1E: {
    zip: 'C1E',
    latitude: '46.2607',
    longitude: '-63.16',
    city: 'Charlottetown West',
    state: 'PE',
    country: 'Canada',
  },
  C1N: {
    zip: 'C1N',
    latitude: '46.3907',
    longitude: '-63.7868',
    city: 'Summerside',
    state: 'PE',
    country: 'Canada',
  },
  G0A: {
    zip: 'G0A',
    latitude: '46.8524',
    longitude: '-72.0259',
    city: 'Capitale-nationale',
    state: 'QC',
    country: 'Canada',
  },
  G0B: {
    zip: 'G0B',
    latitude: '47.3983',
    longitude: '-61.7742',
    city: 'Cap-aux-meules',
    state: 'QC',
    country: 'Canada',
  },
  G0C: {
    zip: 'G0C',
    latitude: '48.1496',
    longitude: '-65.7053',
    city: 'Gaspesie-sud',
    state: 'QC',
    country: 'Canada',
  },
  G0E: {
    zip: 'G0E',
    latitude: '48.9298',
    longitude: '-64.3438',
    city: 'Gaspesie-nord',
    state: 'QC',
    country: 'Canada',
  },
  G0G: {
    zip: 'G0G',
    latitude: '50.1169',
    longitude: '-62.4136',
    city: 'Côte-nord/anticosti',
    state: 'QC',
    country: 'Canada',
  },
  G0H: {
    zip: 'G0H',
    latitude: '49.1633',
    longitude: '-68.3335',
    city: 'Manicouagan',
    state: 'QC',
    country: 'Canada',
  },
  G0J: {
    zip: 'G0J',
    latitude: '49.0226',
    longitude: '-66.8158',
    city: 'Gaspesie-ouest',
    state: 'QC',
    country: 'Canada',
  },
  G0K: {
    zip: 'G0K',
    latitude: '48.3473',
    longitude: '-68.3948',
    city: 'Bas-st-laurent- Est',
    state: 'QC',
    country: 'Canada',
  },
  G0L: {
    zip: 'G0L',
    latitude: '47.6843',
    longitude: '-68.8681',
    city: 'Bas-st-laurent- Ouest',
    state: 'QC',
    country: 'Canada',
  },
  G0M: {
    zip: 'G0M',
    latitude: '46.2057',
    longitude: '-70.8326',
    city: 'Region De Beauce',
    state: 'QC',
    country: 'Canada',
  },
  G0N: {
    zip: 'G0N',
    latitude: '46.0651',
    longitude: '-71.4352',
    city: 'Chaudière-sud',
    state: 'QC',
    country: 'Canada',
  },
  G0P: {
    zip: 'G0P',
    latitude: '45.8641',
    longitude: '-71.6523',
    city: 'Centre-du- Québec-est',
    state: 'QC',
    country: 'Canada',
  },
  G0R: {
    zip: 'G0R',
    latitude: '46.9055',
    longitude: '-70.7456',
    city: 'Appalaches',
    state: 'QC',
    country: 'Canada',
  },
  G0S: {
    zip: 'G0S',
    latitude: '46.2635',
    longitude: '-70.7929',
    city: 'Chaudière-nord',
    state: 'QC',
    country: 'Canada',
  },
  G0T: {
    zip: 'G0T',
    latitude: '47.6525',
    longitude: '-70.4067',
    city: 'Le Fjord',
    state: 'QC',
    country: 'Canada',
  },
  G0V: {
    zip: 'G0V',
    latitude: '48.3448',
    longitude: '-70.9869',
    city: 'Saguenay- Lac-st-jean',
    state: 'QC',
    country: 'Canada',
  },
  G0W: {
    zip: 'G0W',
    latitude: '48.8854',
    longitude: '-72.4433',
    city: 'Region De Mistassini',
    state: 'QC',
    country: 'Canada',
  },
  G0X: {
    zip: 'G0X',
    latitude: '46.6996',
    longitude: '-72.643',
    city: 'Mauricie',
    state: 'QC',
    country: 'Canada',
  },
  G0Y: {
    zip: 'G0Y',
    latitude: '45.6544',
    longitude: '-71.0379',
    city: "L'erable",
    state: 'QC',
    country: 'Canada',
  },
  G0Z: {
    zip: 'G0Z',
    latitude: '46.152',
    longitude: '-72.1347',
    city: 'Centre-du- Québec-nord',
    state: 'QC',
    country: 'Canada',
  },
  G1A: {
    zip: 'G1A',
    latitude: '46.9181',
    longitude: '-71.2036',
    city: 'Quebec Provincial Government',
    state: 'QC',
    country: 'Canada',
  },
  G1B: {
    zip: 'G1B',
    latitude: '46.9179',
    longitude: '-71.1964',
    city: 'Beauport North',
    state: 'QC',
    country: 'Canada',
  },
  G1C: {
    zip: 'G1C',
    latitude: '46.8886',
    longitude: '-71.2212',
    city: 'Beauport Central',
    state: 'QC',
    country: 'Canada',
  },
  G1E: {
    zip: 'G1E',
    latitude: '46.876',
    longitude: '-71.192',
    city: 'Beauport South',
    state: 'QC',
    country: 'Canada',
  },
  G1G: {
    zip: 'G1G',
    latitude: '46.8921',
    longitude: '-71.3056',
    city: 'Jean-talon Southeast',
    state: 'QC',
    country: 'Canada',
  },
  G1H: {
    zip: 'G1H',
    latitude: '46.8615',
    longitude: '-71.2698',
    city: 'Charlesbourg South',
    state: 'QC',
    country: 'Canada',
  },
  G1J: {
    zip: 'G1J',
    latitude: '46.8483',
    longitude: '-71.234',
    city: 'Quebec City Lower Riverbank',
    state: 'QC',
    country: 'Canada',
  },
  G1K: {
    zip: 'G1K',
    latitude: '46.8143',
    longitude: '-71.2431',
    city: 'Quebec City Mid-riverbank',
    state: 'QC',
    country: 'Canada',
  },
  G1L: {
    zip: 'G1L',
    latitude: '46.8396',
    longitude: '-71.2506',
    city: 'Quebec City Northeast',
    state: 'QC',
    country: 'Canada',
  },
  G1M: {
    zip: 'G1M',
    latitude: '46.8165',
    longitude: '-71.236',
    city: 'Quebec City North Central',
    state: 'QC',
    country: 'Canada',
  },
  G1N: {
    zip: 'G1N',
    latitude: '46.81',
    longitude: '-71.2526',
    city: 'Quebec City South Central',
    state: 'QC',
    country: 'Canada',
  },
  G1P: {
    zip: 'G1P',
    latitude: '46.8257',
    longitude: '-71.331',
    city: 'Quebec City West',
    state: 'QC',
    country: 'Canada',
  },
  G1R: {
    zip: 'G1R',
    latitude: '46.8128',
    longitude: '-71.2194',
    city: 'Quebec City East',
    state: 'QC',
    country: 'Canada',
  },
  G1S: {
    zip: 'G1S',
    latitude: '46.7867',
    longitude: '-71.2436',
    city: 'Quebec City South',
    state: 'QC',
    country: 'Canada',
  },
  G1T: {
    zip: 'G1T',
    latitude: '46.7863',
    longitude: '-71.2579',
    city: 'Quebec City Upper Riverbank',
    state: 'QC',
    country: 'Canada',
  },
  G1V: {
    zip: 'G1V',
    latitude: '46.789',
    longitude: '-71.2936',
    city: 'Sainte-foy Northeast',
    state: 'QC',
    country: 'Canada',
  },
  G1W: {
    zip: 'G1W',
    latitude: '46.7673',
    longitude: '-71.2857',
    city: 'Sainte-foy Southeast',
    state: 'QC',
    country: 'Canada',
  },
  G1X: {
    zip: 'G1X',
    latitude: '46.7828',
    longitude: '-71.3149',
    city: 'Sainte-foy West',
    state: 'QC',
    country: 'Canada',
  },
  G1Y: {
    zip: 'G1Y',
    latitude: '46.7595',
    longitude: '-71.3433',
    city: 'Cap-rouge',
    state: 'QC',
    country: 'Canada',
  },
  G2A: {
    zip: 'G2A',
    latitude: '46.8681',
    longitude: '-71.3787',
    city: 'Loretteville North',
    state: 'QC',
    country: 'Canada',
  },
  G2B: {
    zip: 'G2B',
    latitude: '46.8569',
    longitude: '-71.3506',
    city: 'Loretteville South',
    state: 'QC',
    country: 'Canada',
  },
  G2C: {
    zip: 'G2C',
    latitude: '46.8342',
    longitude: '-71.3463',
    city: 'Quebec City Northwest',
    state: 'QC',
    country: 'Canada',
  },
  G2E: {
    zip: 'G2E',
    latitude: '46.8175',
    longitude: '-71.371',
    city: "L'ancienne- Lorette Northeast",
    state: 'QC',
    country: 'Canada',
  },
  G2G: {
    zip: 'G2G',
    latitude: '46.8119',
    longitude: '-71.3906',
    city: "L'ancienne- Lorette Southwest",
    state: 'QC',
    country: 'Canada',
  },
  G2J: {
    zip: 'G2J',
    latitude: '46.8428',
    longitude: '-71.2774',
    city: 'Quebec City Inner North',
    state: 'QC',
    country: 'Canada',
  },
  G2K: {
    zip: 'G2K',
    latitude: '46.8105',
    longitude: '-71.2426',
    city: 'Quebec City Outer North',
    state: 'QC',
    country: 'Canada',
  },
  G2L: {
    zip: 'G2L',
    latitude: '46.8921',
    longitude: '-71.2732',
    city: 'Charlesbourg North',
    state: 'QC',
    country: 'Canada',
  },
  G2M: {
    zip: 'G2M',
    latitude: '46.9159',
    longitude: '-71.3163',
    city: 'Jean-talon Northeast',
    state: 'QC',
    country: 'Canada',
  },
  G2N: {
    zip: 'G2N',
    latitude: '46.9338',
    longitude: '-71.3446',
    city: 'Jean-talon West',
    state: 'QC',
    country: 'Canada',
  },
  G3A: {
    zip: 'G3A',
    latitude: '46.7529',
    longitude: '-71.3734',
    city: 'St-augustin- De-desmaures',
    state: 'QC',
    country: 'Canada',
  },
  G3E: {
    zip: 'G3E',
    latitude: '46.8765',
    longitude: '-71.3233',
    city: 'Saint-Émile',
    state: 'QC',
    country: 'Canada',
  },
  G3G: {
    zip: 'G3G',
    latitude: '46.9445',
    longitude: '-71.4133',
    city: 'Lac-saint-charles',
    state: 'QC',
    country: 'Canada',
  },
  G3H: {
    zip: 'G3H',
    latitude: '46.756',
    longitude: '-71.6969',
    city: 'Pont-rouge',
    state: 'QC',
    country: 'Canada',
  },
  G3J: {
    zip: 'G3J',
    latitude: '46.8617',
    longitude: '-71.4241',
    city: 'Val-bélair North',
    state: 'QC',
    country: 'Canada',
  },
  G3K: {
    zip: 'G3K',
    latitude: '46.8388',
    longitude: '-71.3998',
    city: 'Val-bélair South',
    state: 'QC',
    country: 'Canada',
  },
  G3L: {
    zip: 'G3L',
    latitude: '46.8897',
    longitude: '-71.8349',
    city: 'Saint-raymond',
    state: 'QC',
    country: 'Canada',
  },
  G3M: {
    zip: 'G3M',
    latitude: '46.6725',
    longitude: '-71.7368',
    city: 'Donnacona',
    state: 'QC',
    country: 'Canada',
  },
  G3Z: {
    zip: 'G3Z',
    latitude: '47.4454',
    longitude: '-70.5199',
    city: 'Baie-saint-paul',
    state: 'QC',
    country: 'Canada',
  },
  G4A: {
    zip: 'G4A',
    latitude: '47.695',
    longitude: '-70.2239',
    city: 'Clermont',
    state: 'QC',
    country: 'Canada',
  },
  G4R: {
    zip: 'G4R',
    latitude: '50.2206',
    longitude: '-66.3581',
    city: 'Sept-Îles Southeast',
    state: 'QC',
    country: 'Canada',
  },
  G4S: {
    zip: 'G4S',
    latitude: '50.2309',
    longitude: '-66.3901',
    city: 'Sept-Îles Northwest',
    state: 'QC',
    country: 'Canada',
  },
  G4T: {
    zip: 'G4T',
    latitude: '47.5371',
    longitude: '-61.5387',
    city: 'Les Îles-de-la- Madeleine',
    state: 'QC',
    country: 'Canada',
  },
  G4V: {
    zip: 'G4V',
    latitude: '49.1283',
    longitude: '-66.4906',
    city: 'Sainte-anne- Des-monts',
    state: 'QC',
    country: 'Canada',
  },
  G4W: {
    zip: 'G4W',
    latitude: '48.8526',
    longitude: '-67.518',
    city: 'Matane',
    state: 'QC',
    country: 'Canada',
  },
  G4X: {
    zip: 'G4X',
    latitude: '48.8319',
    longitude: '-64.4813',
    city: 'Gaspé',
    state: 'QC',
    country: 'Canada',
  },
  G4Z: {
    zip: 'G4Z',
    latitude: '49.2446',
    longitude: '-68.1442',
    city: 'Baie-comeau Northeast',
    state: 'QC',
    country: 'Canada',
  },
  G5A: {
    zip: 'G5A',
    latitude: '47.6259',
    longitude: '-70.0967',
    city: 'La Malbaie',
    state: 'QC',
    country: 'Canada',
  },
  G5B: {
    zip: 'G5B',
    latitude: '50.0382',
    longitude: '-66.8659',
    city: 'Port-cartier',
    state: 'QC',
    country: 'Canada',
  },
  G5C: {
    zip: 'G5C',
    latitude: '49.1962',
    longitude: '-68.2976',
    city: 'Baie-comeau Southwest',
    state: 'QC',
    country: 'Canada',
  },
  G5H: {
    zip: 'G5H',
    latitude: '48.5949',
    longitude: '-68.1883',
    city: 'Mont-joli',
    state: 'QC',
    country: 'Canada',
  },
  G5J: {
    zip: 'G5J',
    latitude: '48.4584',
    longitude: '-67.4333',
    city: 'Amqui',
    state: 'QC',
    country: 'Canada',
  },
  G5L: {
    zip: 'G5L',
    latitude: '48.4525',
    longitude: '-68.5232',
    city: 'Rimouski Central',
    state: 'QC',
    country: 'Canada',
  },
  G5M: {
    zip: 'G5M',
    latitude: '48.4547',
    longitude: '-68.4973',
    city: 'Rimouski Northeast',
    state: 'QC',
    country: 'Canada',
  },
  G5N: {
    zip: 'G5N',
    latitude: '48.4277',
    longitude: '-68.5122',
    city: 'Rimouski Southwest',
    state: 'QC',
    country: 'Canada',
  },
  G5R: {
    zip: 'G5R',
    latitude: '47.8559',
    longitude: '-69.5376',
    city: 'Rivière-du-loup',
    state: 'QC',
    country: 'Canada',
  },
  G5T: {
    zip: 'G5T',
    latitude: '47.5521',
    longitude: '-68.6441',
    city: 'Degelis',
    state: 'QC',
    country: 'Canada',
  },
  G5V: {
    zip: 'G5V',
    latitude: '46.9984',
    longitude: '-70.5595',
    city: 'Montmagny',
    state: 'QC',
    country: 'Canada',
  },
  G5X: {
    zip: 'G5X',
    latitude: '46.2093',
    longitude: '-70.7788',
    city: 'Beauceville',
    state: 'QC',
    country: 'Canada',
  },
  G5Y: {
    zip: 'G5Y',
    latitude: '46.13',
    longitude: '-70.6557',
    city: 'Saint-georges Central',
    state: 'QC',
    country: 'Canada',
  },
  G5Z: {
    zip: 'G5Z',
    latitude: '46.1231',
    longitude: '-70.647',
    city: 'Saint-georges Southeast',
    state: 'QC',
    country: 'Canada',
  },
  G6A: {
    zip: 'G6A',
    latitude: '46.1379',
    longitude: '-70.6715',
    city: 'Saint-georges Northwest',
    state: 'QC',
    country: 'Canada',
  },
  G6B: {
    zip: 'G6B',
    latitude: '45.5946',
    longitude: '-70.9176',
    city: 'Lac-megantic',
    state: 'QC',
    country: 'Canada',
  },
  G6C: {
    zip: 'G6C',
    latitude: '46.7557',
    longitude: '-71.124',
    city: 'Pintendre',
    state: 'QC',
    country: 'Canada',
  },
  G6E: {
    zip: 'G6E',
    latitude: '46.4691',
    longitude: '-71.0427',
    city: 'Sainte-marie',
    state: 'QC',
    country: 'Canada',
  },
  G6G: {
    zip: 'G6G',
    latitude: '46.1134',
    longitude: '-71.3108',
    city: 'Thetford Mines',
    state: 'QC',
    country: 'Canada',
  },
  G6H: {
    zip: 'G6H',
    latitude: '46.0654',
    longitude: '-71.356',
    city: 'Black Lake',
    state: 'QC',
    country: 'Canada',
  },
  G6J: {
    zip: 'G6J',
    latitude: '46.6561',
    longitude: '-71.3095',
    city: 'Saint-etienne- De-lauzon',
    state: 'QC',
    country: 'Canada',
  },
  G6K: {
    zip: 'G6K',
    latitude: '46.7038',
    longitude: '-71.2837',
    city: 'Saint- Redempteur',
    state: 'QC',
    country: 'Canada',
  },
  G6L: {
    zip: 'G6L',
    latitude: '46.2255',
    longitude: '-71.7779',
    city: 'Plessisville',
    state: 'QC',
    country: 'Canada',
  },
  G6P: {
    zip: 'G6P',
    latitude: '46.0606',
    longitude: '-71.9477',
    city: 'Victoriaville Central',
    state: 'QC',
    country: 'Canada',
  },
  G6R: {
    zip: 'G6R',
    latitude: '46.0388',
    longitude: '-71.9596',
    city: 'Victoriaville South',
    state: 'QC',
    country: 'Canada',
  },
  G6S: {
    zip: 'G6S',
    latitude: '46.0714',
    longitude: '-71.9332',
    city: 'Victoriaville East',
    state: 'QC',
    country: 'Canada',
  },
  G6T: {
    zip: 'G6T',
    latitude: '46.0477',
    longitude: '-71.9549',
    city: 'Victoriaville Northwest',
    state: 'QC',
    country: 'Canada',
  },
  G6V: {
    zip: 'G6V',
    latitude: '46.8207',
    longitude: '-71.1787',
    city: 'Lévis North',
    state: 'QC',
    country: 'Canada',
  },
  G6W: {
    zip: 'G6W',
    latitude: '46.7933',
    longitude: '-71.1885',
    city: 'Lévis South',
    state: 'QC',
    country: 'Canada',
  },
  G6X: {
    zip: 'G6X',
    latitude: '46.7228',
    longitude: '-71.2788',
    city: 'Charny',
    state: 'QC',
    country: 'Canada',
  },
  G6Z: {
    zip: 'G6Z',
    latitude: '46.7391',
    longitude: '-71.2055',
    city: 'Saint-jean- Chrysostome',
    state: 'QC',
    country: 'Canada',
  },
  G7A: {
    zip: 'G7A',
    latitude: '46.6709',
    longitude: '-71.3548',
    city: 'Saint-nicolas',
    state: 'QC',
    country: 'Canada',
  },
  G7B: {
    zip: 'G7B',
    latitude: '48.3133',
    longitude: '-70.8557',
    city: 'La Baie',
    state: 'QC',
    country: 'Canada',
  },
  G7G: {
    zip: 'G7G',
    latitude: '48.4572',
    longitude: '-71.0591',
    city: 'Chicoutimi North',
    state: 'QC',
    country: 'Canada',
  },
  G7H: {
    zip: 'G7H',
    latitude: '48.4337',
    longitude: '-71.0225',
    city: 'Chicoutimi East',
    state: 'QC',
    country: 'Canada',
  },
  G7J: {
    zip: 'G7J',
    latitude: '48.4377',
    longitude: '-71.1244',
    city: 'Chicoutimi West',
    state: 'QC',
    country: 'Canada',
  },
  G7K: {
    zip: 'G7K',
    latitude: '48.3976',
    longitude: '-71.11',
    city: 'Chicoutimi Southwest',
    state: 'QC',
    country: 'Canada',
  },
  G7N: {
    zip: 'G7N',
    latitude: '48.3084',
    longitude: '-71.1104',
    city: 'Laterrière',
    state: 'QC',
    country: 'Canada',
  },
  G7P: {
    zip: 'G7P',
    latitude: '48.51',
    longitude: '-71.268',
    city: 'Saint-ambroise',
    state: 'QC',
    country: 'Canada',
  },
  G7S: {
    zip: 'G7S',
    latitude: '48.4099',
    longitude: '-71.1961',
    city: 'Jonquière Northeast',
    state: 'QC',
    country: 'Canada',
  },
  G7T: {
    zip: 'G7T',
    latitude: '48.4112',
    longitude: '-71.2149',
    city: 'Jonquière Southeast',
    state: 'QC',
    country: 'Canada',
  },
  G7X: {
    zip: 'G7X',
    latitude: '48.4359',
    longitude: '-71.2318',
    city: 'Jonquière Central',
    state: 'QC',
    country: 'Canada',
  },
  G7Y: {
    zip: 'G7Y',
    latitude: '48.3933',
    longitude: '-71.267',
    city: 'Jonquière Southwest',
    state: 'QC',
    country: 'Canada',
  },
  G7Z: {
    zip: 'G7Z',
    latitude: '48.4327',
    longitude: '-71.262',
    city: 'Jonquière Northwest',
    state: 'QC',
    country: 'Canada',
  },
  G8A: {
    zip: 'G8A',
    latitude: '48.4244',
    longitude: '-71.2619',
    city: 'Jonquière West',
    state: 'QC',
    country: 'Canada',
  },
  G8B: {
    zip: 'G8B',
    latitude: '48.5468',
    longitude: '-71.6399',
    city: 'Alma Southeast',
    state: 'QC',
    country: 'Canada',
  },
  G8C: {
    zip: 'G8C',
    latitude: '48.5292',
    longitude: '-71.642',
    city: 'Alma Southwest',
    state: 'QC',
    country: 'Canada',
  },
  G8E: {
    zip: 'G8E',
    latitude: '48.5592',
    longitude: '-71.6416',
    city: 'Alma North',
    state: 'QC',
    country: 'Canada',
  },
  G8G: {
    zip: 'G8G',
    latitude: '48.4223',
    longitude: '-71.8737',
    city: 'Métabetchouan- Lac-a-la-croix',
    state: 'QC',
    country: 'Canada',
  },
  G8H: {
    zip: 'G8H',
    latitude: '48.5044',
    longitude: '-72.2165',
    city: 'Roberval',
    state: 'QC',
    country: 'Canada',
  },
  G8J: {
    zip: 'G8J',
    latitude: '48.5774',
    longitude: '-72.441',
    city: 'Saint-prime',
    state: 'QC',
    country: 'Canada',
  },
  G8K: {
    zip: 'G8K',
    latitude: '48.6556',
    longitude: '-72.4469',
    city: 'Saint-félicien',
    state: 'QC',
    country: 'Canada',
  },
  G8L: {
    zip: 'G8L',
    latitude: '48.8707',
    longitude: '-72.2141',
    city: 'Dolbeau- Mistassini',
    state: 'QC',
    country: 'Canada',
  },
  G8M: {
    zip: 'G8M',
    latitude: '48.8892',
    longitude: '-72.1938',
    city: 'Albanel',
    state: 'QC',
    country: 'Canada',
  },
  G8N: {
    zip: 'G8N',
    latitude: '48.3942',
    longitude: '-71.6775',
    city: 'Hébertville',
    state: 'QC',
    country: 'Canada',
  },
  G8P: {
    zip: 'G8P',
    latitude: '49.9214',
    longitude: '-74.3601',
    city: 'Chibougamau',
    state: 'QC',
    country: 'Canada',
  },
  G8T: {
    zip: 'G8T',
    latitude: '46.419',
    longitude: '-72.6006',
    city: 'Cap-de-la- Madeleine Central And Southeast',
    state: 'QC',
    country: 'Canada',
  },
  G8V: {
    zip: 'G8V',
    latitude: '46.3887',
    longitude: '-72.4875',
    city: 'Cap-de-la- Madeleine Northeast',
    state: 'QC',
    country: 'Canada',
  },
  G8W: {
    zip: 'G8W',
    latitude: '46.4024',
    longitude: '-72.5846',
    city: 'Cap-de-la- Madeleine West',
    state: 'QC',
    country: 'Canada',
  },
  G8Y: {
    zip: 'G8Y',
    latitude: '46.3688',
    longitude: '-72.58',
    city: 'Trois-rivières Central',
    state: 'QC',
    country: 'Canada',
  },
  G8Z: {
    zip: 'G8Z',
    latitude: '46.3648',
    longitude: '-72.5564',
    city: 'Trois-rivières Northeast',
    state: 'QC',
    country: 'Canada',
  },
  G9A: {
    zip: 'G9A',
    latitude: '46.3647',
    longitude: '-72.5558',
    city: 'Trois-rivières East',
    state: 'QC',
    country: 'Canada',
  },
  G9B: {
    zip: 'G9B',
    latitude: '46.3111',
    longitude: '-72.5718',
    city: 'Trois-rivières South',
    state: 'QC',
    country: 'Canada',
  },
  G9C: {
    zip: 'G9C',
    latitude: '46.3938',
    longitude: '-72.6534',
    city: 'Trois-rivières West',
    state: 'QC',
    country: 'Canada',
  },
  G9H: {
    zip: 'G9H',
    latitude: '46.3445',
    longitude: '-72.4369',
    city: 'Becancour',
    state: 'QC',
    country: 'Canada',
  },
  G9N: {
    zip: 'G9N',
    latitude: '46.5429',
    longitude: '-72.748',
    city: 'Shawinigan Central',
    state: 'QC',
    country: 'Canada',
  },
  G9P: {
    zip: 'G9P',
    latitude: '46.5258',
    longitude: '-72.7381',
    city: 'Shawinigan Southeast',
    state: 'QC',
    country: 'Canada',
  },
  G9R: {
    zip: 'G9R',
    latitude: '46.576',
    longitude: '-72.7764',
    city: 'Shawinigan Northwest',
    state: 'QC',
    country: 'Canada',
  },
  G9T: {
    zip: 'G9T',
    latitude: '46.6168',
    longitude: '-72.7336',
    city: 'Grand-mère',
    state: 'QC',
    country: 'Canada',
  },
  G9X: {
    zip: 'G9X',
    latitude: '47.4583',
    longitude: '-72.7729',
    city: 'La Tuque',
    state: 'QC',
    country: 'Canada',
  },
  H0H: {
    zip: 'H0H',
    latitude: '90',
    longitude: '0',
    city: 'Reserved',
    state: 'QC',
    country: 'Canada',
  },
  H0M: {
    zip: 'H0M',
    latitude: '45.6986',
    longitude: '-73.5025',
    city: 'Akwesasne Region',
    state: 'QC',
    country: 'Canada',
  },
  H1A: {
    zip: 'H1A',
    latitude: '45.6587',
    longitude: '-73.5236',
    city: 'Pointe-aux-trembles',
    state: 'QC',
    country: 'Canada',
  },
  H1B: {
    zip: 'H1B',
    latitude: '45.6454',
    longitude: '-73.5502',
    city: 'Montreal East',
    state: 'QC',
    country: 'Canada',
  },
  H1C: {
    zip: 'H1C',
    latitude: '45.6596',
    longitude: '-73.5704',
    city: 'Rivière-des-prairies Northeast',
    state: 'QC',
    country: 'Canada',
  },
  H1E: {
    zip: 'H1E',
    latitude: '45.6595',
    longitude: '-73.5729',
    city: 'Rivière-des-prairies Southwest',
    state: 'QC',
    country: 'Canada',
  },
  H1G: {
    zip: 'H1G',
    latitude: '45.6061',
    longitude: '-73.6389',
    city: 'Montreal North North',
    state: 'QC',
    country: 'Canada',
  },
  H1H: {
    zip: 'H1H',
    latitude: '45.5829',
    longitude: '-73.6524',
    city: 'Montreal North South',
    state: 'QC',
    country: 'Canada',
  },
  H1J: {
    zip: 'H1J',
    latitude: '45.6036',
    longitude: '-73.569',
    city: 'Anjou West',
    state: 'QC',
    country: 'Canada',
  },
  H1K: {
    zip: 'H1K',
    latitude: '45.6077',
    longitude: '-73.5428',
    city: 'Anjou East',
    state: 'QC',
    country: 'Canada',
  },
  H1L: {
    zip: 'H1L',
    latitude: '45.5943',
    longitude: '-73.5362',
    city: 'Mercier North',
    state: 'QC',
    country: 'Canada',
  },
  H1M: {
    zip: 'H1M',
    latitude: '45.5902',
    longitude: '-73.5559',
    city: 'Mercier West',
    state: 'QC',
    country: 'Canada',
  },
  H1N: {
    zip: 'H1N',
    latitude: '45.5719',
    longitude: '-73.5499',
    city: 'Mercier Southeast',
    state: 'QC',
    country: 'Canada',
  },
  H1P: {
    zip: 'H1P',
    latitude: '45.6105',
    longitude: '-73.6048',
    city: 'Saint-léonard North',
    state: 'QC',
    country: 'Canada',
  },
  H1R: {
    zip: 'H1R',
    latitude: '45.5844',
    longitude: '-73.6229',
    city: 'Saint-léonard West',
    state: 'QC',
    country: 'Canada',
  },
  H1S: {
    zip: 'H1S',
    latitude: '45.5716',
    longitude: '-73.5985',
    city: 'Saint-léonard Southeast',
    state: 'QC',
    country: 'Canada',
  },
  H1T: {
    zip: 'H1T',
    latitude: '45.5653',
    longitude: '-73.5869',
    city: 'Rosemont North',
    state: 'QC',
    country: 'Canada',
  },
  H1V: {
    zip: 'H1V',
    latitude: '45.5702',
    longitude: '-73.551',
    city: 'Maisonneuve',
    state: 'QC',
    country: 'Canada',
  },
  H1W: {
    zip: 'H1W',
    latitude: '45.5423',
    longitude: '-73.5616',
    city: 'Hochelaga',
    state: 'QC',
    country: 'Canada',
  },
  H1X: {
    zip: 'H1X',
    latitude: '45.5577',
    longitude: '-73.5935',
    city: 'Rosemont Central',
    state: 'QC',
    country: 'Canada',
  },
  H1Y: {
    zip: 'H1Y',
    latitude: '45.5525',
    longitude: '-73.598',
    city: 'Rosemont South',
    state: 'QC',
    country: 'Canada',
  },
  H1Z: {
    zip: 'H1Z',
    latitude: '45.5652',
    longitude: '-73.6444',
    city: 'Saint-michel West',
    state: 'QC',
    country: 'Canada',
  },
  H2A: {
    zip: 'H2A',
    latitude: '45.5583',
    longitude: '-73.6118',
    city: 'Saint-michel East',
    state: 'QC',
    country: 'Canada',
  },
  H2B: {
    zip: 'H2B',
    latitude: '45.5664',
    longitude: '-73.647',
    city: 'Ahuntsic North',
    state: 'QC',
    country: 'Canada',
  },
  H2C: {
    zip: 'H2C',
    latitude: '45.5593',
    longitude: '-73.6719',
    city: 'Ahuntsic Central',
    state: 'QC',
    country: 'Canada',
  },
  H2E: {
    zip: 'H2E',
    latitude: '45.5522',
    longitude: '-73.6256',
    city: 'Villeray Northeast',
    state: 'QC',
    country: 'Canada',
  },
  H2G: {
    zip: 'H2G',
    latitude: '45.5434',
    longitude: '-73.6061',
    city: 'Petite-patrie Northeast',
    state: 'QC',
    country: 'Canada',
  },
  H2H: {
    zip: 'H2H',
    latitude: '45.5377',
    longitude: '-73.5837',
    city: 'Plateau Mont-royal North',
    state: 'QC',
    country: 'Canada',
  },
  H2J: {
    zip: 'H2J',
    latitude: '45.5289',
    longitude: '-73.5928',
    city: 'Plateau Mont-royal North Central',
    state: 'QC',
    country: 'Canada',
  },
  H2K: {
    zip: 'H2K',
    latitude: '45.53',
    longitude: '-73.5672',
    city: 'Centre-sud North',
    state: 'QC',
    country: 'Canada',
  },
  H2L: {
    zip: 'H2L',
    latitude: '45.5252',
    longitude: '-73.5744',
    city: 'Centre-sud South',
    state: 'QC',
    country: 'Canada',
  },
  H2M: {
    zip: 'H2M',
    latitude: '45.55',
    longitude: '-73.6515',
    city: 'Ahuntsic East',
    state: 'QC',
    country: 'Canada',
  },
  H2N: {
    zip: 'H2N',
    latitude: '45.5402',
    longitude: '-73.659',
    city: 'Ahuntsic Southeast',
    state: 'QC',
    country: 'Canada',
  },
  H2P: {
    zip: 'H2P',
    latitude: '45.5409',
    longitude: '-73.6418',
    city: 'Villeray West',
    state: 'QC',
    country: 'Canada',
  },
  H2R: {
    zip: 'H2R',
    latitude: '45.5452',
    longitude: '-73.6266',
    city: 'Villeray Southeast',
    state: 'QC',
    country: 'Canada',
  },
  H2S: {
    zip: 'H2S',
    latitude: '45.5356',
    longitude: '-73.6144',
    city: 'Petite-patrie Southwest',
    state: 'QC',
    country: 'Canada',
  },
  H2T: {
    zip: 'H2T',
    latitude: '45.5278',
    longitude: '-73.6024',
    city: 'Plateau Mont-royal West',
    state: 'QC',
    country: 'Canada',
  },
  H2V: {
    zip: 'H2V',
    latitude: '45.5298',
    longitude: '-73.6153',
    city: 'Outremont',
    state: 'QC',
    country: 'Canada',
  },
  H2W: {
    zip: 'H2W',
    latitude: '45.5194',
    longitude: '-73.5839',
    city: 'Plateau Mont-royal South Central',
    state: 'QC',
    country: 'Canada',
  },
  H2X: {
    zip: 'H2X',
    latitude: '45.5148',
    longitude: '-73.5739',
    city: 'Plateau Mont-royal Southeast',
    state: 'QC',
    country: 'Canada',
  },
  H2Y: {
    zip: 'H2Y',
    latitude: '45.508',
    longitude: '-73.554',
    city: 'Old Montreal',
    state: 'QC',
    country: 'Canada',
  },
  H2Z: {
    zip: 'H2Z',
    latitude: '45.5066',
    longitude: '-73.5623',
    city: 'Downtown Montreal Northeast',
    state: 'QC',
    country: 'Canada',
  },
  H3A: {
    zip: 'H3A',
    latitude: '45.5078',
    longitude: '-73.5804',
    city: 'Downtown Montreal North',
    state: 'QC',
    country: 'Canada',
  },
  H3B: {
    zip: 'H3B',
    latitude: '45.5058',
    longitude: '-73.5672',
    city: 'Downtown Montreal East',
    state: 'QC',
    country: 'Canada',
  },
  H3C: {
    zip: 'H3C',
    latitude: '45.503',
    longitude: '-73.5679',
    city: 'Griffintown',
    state: 'QC',
    country: 'Canada',
  },
  H3E: {
    zip: 'H3E',
    latitude: '45.4679',
    longitude: '-73.5457',
    city: "L'Île-des-soeurs",
    state: 'QC',
    country: 'Canada',
  },
  H3G: {
    zip: 'H3G',
    latitude: '45.5019',
    longitude: '-73.5853',
    city: 'Downtown Montreal Southeast',
    state: 'QC',
    country: 'Canada',
  },
  H3H: {
    zip: 'H3H',
    latitude: '45.5123',
    longitude: '-73.5967',
    city: 'Downtown Montreal South & West',
    state: 'QC',
    country: 'Canada',
  },
  H3J: {
    zip: 'H3J',
    latitude: '45.4922',
    longitude: '-73.5725',
    city: 'Petite-bourgogne',
    state: 'QC',
    country: 'Canada',
  },
  H3K: {
    zip: 'H3K',
    latitude: '45.4858',
    longitude: '-73.564',
    city: 'Pointe-saint-charles',
    state: 'QC',
    country: 'Canada',
  },
  H3L: {
    zip: 'H3L',
    latitude: '45.5529',
    longitude: '-73.6754',
    city: 'Ahuntsic Southwest',
    state: 'QC',
    country: 'Canada',
  },
  H3M: {
    zip: 'H3M',
    latitude: '45.5459',
    longitude: '-73.6979',
    city: 'Cartierville Northeast',
    state: 'QC',
    country: 'Canada',
  },
  H3N: {
    zip: 'H3N',
    latitude: '45.5335',
    longitude: '-73.6464',
    city: 'Parc-extension',
    state: 'QC',
    country: 'Canada',
  },
  H3P: {
    zip: 'H3P',
    latitude: '45.5209',
    longitude: '-73.653',
    city: 'Mount Royal North',
    state: 'QC',
    country: 'Canada',
  },
  H3R: {
    zip: 'H3R',
    latitude: '45.5181',
    longitude: '-73.6545',
    city: 'Mount Royal Central',
    state: 'QC',
    country: 'Canada',
  },
  H3S: {
    zip: 'H3S',
    latitude: '45.5155',
    longitude: '-73.6292',
    city: 'Côte-des-neiges North',
    state: 'QC',
    country: 'Canada',
  },
  H3T: {
    zip: 'H3T',
    latitude: '45.5115',
    longitude: '-73.616',
    city: 'Côte-des-neiges Northeast',
    state: 'QC',
    country: 'Canada',
  },
  H3V: {
    zip: 'H3V',
    latitude: '45.4965',
    longitude: '-73.6177',
    city: 'Côte-des-neiges East',
    state: 'QC',
    country: 'Canada',
  },
  H3W: {
    zip: 'H3W',
    latitude: '45.4988',
    longitude: '-73.6442',
    city: 'Côte-des-neiges Southwest',
    state: 'QC',
    country: 'Canada',
  },
  H3X: {
    zip: 'H3X',
    latitude: '45.4915',
    longitude: '-73.6483',
    city: 'Hampstead',
    state: 'QC',
    country: 'Canada',
  },
  H3Y: {
    zip: 'H3Y',
    latitude: '45.489',
    longitude: '-73.618',
    city: 'Westmount West',
    state: 'QC',
    country: 'Canada',
  },
  H3Z: {
    zip: 'H3Z',
    latitude: '45.4909',
    longitude: '-73.5885',
    city: 'Westmount East',
    state: 'QC',
    country: 'Canada',
  },
  H4A: {
    zip: 'H4A',
    latitude: '45.4781',
    longitude: '-73.6252',
    city: 'Notre-dame-de-grâce Northeast',
    state: 'QC',
    country: 'Canada',
  },
  H4B: {
    zip: 'H4B',
    latitude: '45.4681',
    longitude: '-73.636',
    city: 'Notre-dame-de-grâce Southwest',
    state: 'QC',
    country: 'Canada',
  },
  H4C: {
    zip: 'H4C',
    latitude: '45.478',
    longitude: '-73.5922',
    city: 'Saint-henri',
    state: 'QC',
    country: 'Canada',
  },
  H4E: {
    zip: 'H4E',
    latitude: '45.468',
    longitude: '-73.5863',
    city: 'Ville Émard',
    state: 'QC',
    country: 'Canada',
  },
  H4G: {
    zip: 'H4G',
    latitude: '45.4644',
    longitude: '-73.5798',
    city: 'Verdun North',
    state: 'QC',
    country: 'Canada',
  },
  H4H: {
    zip: 'H4H',
    latitude: '45.4532',
    longitude: '-73.5818',
    city: 'Verdun South',
    state: 'QC',
    country: 'Canada',
  },
  H4J: {
    zip: 'H4J',
    latitude: '45.5353',
    longitude: '-73.7231',
    city: 'Cartierville Central',
    state: 'QC',
    country: 'Canada',
  },
  H4K: {
    zip: 'H4K',
    latitude: '45.5248',
    longitude: '-73.7392',
    city: 'Cartierville Southwest',
    state: 'QC',
    country: 'Canada',
  },
  H4L: {
    zip: 'H4L',
    latitude: '45.5269',
    longitude: '-73.6974',
    city: 'Saint-laurent Inner Northeast',
    state: 'QC',
    country: 'Canada',
  },
  H4M: {
    zip: 'H4M',
    latitude: '45.5067',
    longitude: '-73.6906',
    city: 'Saint-laurent East',
    state: 'QC',
    country: 'Canada',
  },
  H4N: {
    zip: 'H4N',
    latitude: '45.5329',
    longitude: '-73.6807',
    city: 'Saint-laurent Outer Northeast',
    state: 'QC',
    country: 'Canada',
  },
  H4P: {
    zip: 'H4P',
    latitude: '45.4991',
    longitude: '-73.6722',
    city: 'Mount Royal South',
    state: 'QC',
    country: 'Canada',
  },
  H4R: {
    zip: 'H4R',
    latitude: '45.5148',
    longitude: '-73.7309',
    city: 'Saint-laurent Central',
    state: 'QC',
    country: 'Canada',
  },
  H4S: {
    zip: 'H4S',
    latitude: '45.4958',
    longitude: '-73.754',
    city: 'Saint-laurent Southwest',
    state: 'QC',
    country: 'Canada',
  },
  H4T: {
    zip: 'H4T',
    latitude: '45.4954',
    longitude: '-73.6798',
    city: 'Saint-laurent Southeast',
    state: 'QC',
    country: 'Canada',
  },
  H4V: {
    zip: 'H4V',
    latitude: '45.4755',
    longitude: '-73.6555',
    city: 'Côte-saint-luc East',
    state: 'QC',
    country: 'Canada',
  },
  H4W: {
    zip: 'H4W',
    latitude: '45.478',
    longitude: '-73.6704',
    city: 'Côte-saint-luc West',
    state: 'QC',
    country: 'Canada',
  },
  H4X: {
    zip: 'H4X',
    latitude: '45.4575',
    longitude: '-73.6649',
    city: 'Montreal West',
    state: 'QC',
    country: 'Canada',
  },
  H4Y: {
    zip: 'H4Y',
    latitude: '45.5103',
    longitude: '-73.6818',
    city: 'Dorval Central',
    state: 'QC',
    country: 'Canada',
  },
  H4Z: {
    zip: 'H4Z',
    latitude: '45.5003',
    longitude: '-73.5621',
    city: 'Tour De La Bourse',
    state: 'QC',
    country: 'Canada',
  },
  H5A: {
    zip: 'H5A',
    latitude: '45.503',
    longitude: '-73.5679',
    city: 'Place Bonaventure',
    state: 'QC',
    country: 'Canada',
  },
  H5B: {
    zip: 'H5B',
    latitude: '45.5066',
    longitude: '-73.5623',
    city: 'Place Desjardins',
    state: 'QC',
    country: 'Canada',
  },
  H7A: {
    zip: 'H7A',
    latitude: '45.6736',
    longitude: '-73.5919',
    city: 'Duvernay-est',
    state: 'QC',
    country: 'Canada',
  },
  H7B: {
    zip: 'H7B',
    latitude: '45.6346',
    longitude: '-73.6769',
    city: 'Saint-françois',
    state: 'QC',
    country: 'Canada',
  },
  H7C: {
    zip: 'H7C',
    latitude: '45.6176',
    longitude: '-73.6637',
    city: 'Saint-vincent-de-paul',
    state: 'QC',
    country: 'Canada',
  },
  H7E: {
    zip: 'H7E',
    latitude: '45.6142',
    longitude: '-73.669',
    city: 'Duvernay',
    state: 'QC',
    country: 'Canada',
  },
  H7G: {
    zip: 'H7G',
    latitude: '45.5565',
    longitude: '-73.6791',
    city: 'Pont-viau',
    state: 'QC',
    country: 'Canada',
  },
  H7H: {
    zip: 'H7H',
    latitude: '45.6429',
    longitude: '-73.7494',
    city: 'Auteuil West',
    state: 'QC',
    country: 'Canada',
  },
  H7J: {
    zip: 'H7J',
    latitude: '45.6837',
    longitude: '-73.6728',
    city: 'Auteuil Northeast',
    state: 'QC',
    country: 'Canada',
  },
  H7K: {
    zip: 'H7K',
    latitude: '45.6121',
    longitude: '-73.7898',
    city: 'Auteuil South',
    state: 'QC',
    country: 'Canada',
  },
  H7L: {
    zip: 'H7L',
    latitude: '45.6303',
    longitude: '-73.7802',
    city: 'Sainte-rose',
    state: 'QC',
    country: 'Canada',
  },
  H7M: {
    zip: 'H7M',
    latitude: '45.6089',
    longitude: '-73.7331',
    city: 'Vimont',
    state: 'QC',
    country: 'Canada',
  },
  H7N: {
    zip: 'H7N',
    latitude: '45.5772',
    longitude: '-73.7007',
    city: 'Laval-des-rapides',
    state: 'QC',
    country: 'Canada',
  },
  H7P: {
    zip: 'H7P',
    latitude: '45.5917',
    longitude: '-73.8293',
    city: 'Fabreville',
    state: 'QC',
    country: 'Canada',
  },
  H7R: {
    zip: 'H7R',
    latitude: '45.5483',
    longitude: '-73.8578',
    city: 'Laval-sur-le-lac',
    state: 'QC',
    country: 'Canada',
  },
  H7S: {
    zip: 'H7S',
    latitude: '45.5732',
    longitude: '-73.7444',
    city: 'Chomedey Northeast',
    state: 'QC',
    country: 'Canada',
  },
  H7T: {
    zip: 'H7T',
    latitude: '45.5569',
    longitude: '-73.748',
    city: 'Chomedey Northwest',
    state: 'QC',
    country: 'Canada',
  },
  H7V: {
    zip: 'H7V',
    latitude: '45.5364',
    longitude: '-73.7267',
    city: 'Chomedey East',
    state: 'QC',
    country: 'Canada',
  },
  H7W: {
    zip: 'H7W',
    latitude: '45.549',
    longitude: '-73.7641',
    city: 'Chomedey South',
    state: 'QC',
    country: 'Canada',
  },
  H7X: {
    zip: 'H7X',
    latitude: '45.5359',
    longitude: '-73.8231',
    city: 'Sainte-dorothée',
    state: 'QC',
    country: 'Canada',
  },
  H7Y: {
    zip: 'H7Y',
    latitude: '45.5209',
    longitude: '-73.8354',
    city: 'Îles-laval',
    state: 'QC',
    country: 'Canada',
  },
  H8N: {
    zip: 'H8N',
    latitude: '45.4551',
    longitude: '-73.6084',
    city: 'Lasalle Northwest',
    state: 'QC',
    country: 'Canada',
  },
  H8P: {
    zip: 'H8P',
    latitude: '45.4371',
    longitude: '-73.5979',
    city: 'Lasalle Southeast',
    state: 'QC',
    country: 'Canada',
  },
  H8R: {
    zip: 'H8R',
    latitude: '45.4473',
    longitude: '-73.6557',
    city: 'Saint-pierre',
    state: 'QC',
    country: 'Canada',
  },
  H8S: {
    zip: 'H8S',
    latitude: '45.4496',
    longitude: '-73.6811',
    city: 'Lachine East',
    state: 'QC',
    country: 'Canada',
  },
  H8T: {
    zip: 'H8T',
    latitude: '45.4648',
    longitude: '-73.7192',
    city: 'Lachine West',
    state: 'QC',
    country: 'Canada',
  },
  H8Y: {
    zip: 'H8Y',
    latitude: '45.5145',
    longitude: '-73.8162',
    city: 'Roxboro',
    state: 'QC',
    country: 'Canada',
  },
  H8Z: {
    zip: 'H8Z',
    latitude: '45.5135',
    longitude: '-73.8389',
    city: 'Pierrefonds',
    state: 'QC',
    country: 'Canada',
  },
  H9A: {
    zip: 'H9A',
    latitude: '45.5055',
    longitude: '-73.823',
    city: 'Dollard-des- Ormeaux Northwest',
    state: 'QC',
    country: 'Canada',
  },
  H9B: {
    zip: 'H9B',
    latitude: '45.4937',
    longitude: '-73.8132',
    city: 'Dollard-des- Ormeaux East',
    state: 'QC',
    country: 'Canada',
  },
  H9C: {
    zip: 'H9C',
    latitude: '45.5141',
    longitude: '-73.9012',
    city: "L'Île Bizard Northeast",
    state: 'QC',
    country: 'Canada',
  },
  H9E: {
    zip: 'H9E',
    latitude: '45.5106',
    longitude: '-73.91',
    city: "L'Île-bizard Southwest",
    state: 'QC',
    country: 'Canada',
  },
  H9G: {
    zip: 'H9G',
    latitude: '45.4794',
    longitude: '-73.8446',
    city: 'Dollard-des- Ormeaux Southwest',
    state: 'QC',
    country: 'Canada',
  },
  H9H: {
    zip: 'H9H',
    latitude: '45.4873',
    longitude: '-73.8635',
    city: 'Sainte-geneviève',
    state: 'QC',
    country: 'Canada',
  },
  H9J: {
    zip: 'H9J',
    latitude: '45.469',
    longitude: '-73.8862',
    city: 'Kirkland',
    state: 'QC',
    country: 'Canada',
  },
  H9K: {
    zip: 'H9K',
    latitude: '45.4643',
    longitude: '-73.8936',
    city: 'Senneville',
    state: 'QC',
    country: 'Canada',
  },
  H9P: {
    zip: 'H9P',
    latitude: '45.4617',
    longitude: '-73.7305',
    city: 'Dorval Outskirts',
    state: 'QC',
    country: 'Canada',
  },
  H9R: {
    zip: 'H9R',
    latitude: '45.4748',
    longitude: '-73.8207',
    city: 'Pointe-claire',
    state: 'QC',
    country: 'Canada',
  },
  H9S: {
    zip: 'H9S',
    latitude: '45.4409',
    longitude: '-73.7733',
    city: "L'Île-dorval",
    state: 'QC',
    country: 'Canada',
  },
  H9W: {
    zip: 'H9W',
    latitude: '45.4407',
    longitude: '-73.8727',
    city: 'Beaconsfield',
    state: 'QC',
    country: 'Canada',
  },
  H9X: {
    zip: 'H9X',
    latitude: '45.418',
    longitude: '-73.9515',
    city: 'Sainte-anne-de- Bellevue',
    state: 'QC',
    country: 'Canada',
  },
  J0A: {
    zip: 'J0A',
    latitude: '45.6999',
    longitude: '-72.0033',
    city: 'Centre-du- Québec-sud',
    state: 'QC',
    country: 'Canada',
  },
  J0B: {
    zip: 'J0B',
    latitude: '45.242',
    longitude: '-72.0177',
    city: 'Estrie-est',
    state: 'QC',
    country: 'Canada',
  },
  J0C: {
    zip: 'J0C',
    latitude: '45.9914',
    longitude: '-72.3216',
    city: 'Centre-du- Québec-ouest',
    state: 'QC',
    country: 'Canada',
  },
  J0E: {
    zip: 'J0E',
    latitude: '45.3973',
    longitude: '-72.8797',
    city: 'Estrie-ouest',
    state: 'QC',
    country: 'Canada',
  },
  J0G: {
    zip: 'J0G',
    latitude: '46.0668',
    longitude: '-72.8043',
    city: 'Bois-francs-nord',
    state: 'QC',
    country: 'Canada',
  },
  J0H: {
    zip: 'J0H',
    latitude: '45.6125',
    longitude: '-72.5205',
    city: "Bois-francs-sud",
    state: 'QC',
    country: 'Canada',
  },
  J0J: {
    zip: 'J0J',
    latitude: '45.0784',
    longitude: '-73.0291',
    city: 'Montérégie-est',
    state: 'QC',
    country: 'Canada',
  },
  J0K: {
    zip: 'J0K',
    latitude: '46.104',
    longitude: '-73.256',
    city: 'Lanaudière-nord',
    state: 'QC',
    country: 'Canada',
  },
  J0L: {
    zip: 'J0L',
    latitude: '45.7317',
    longitude: '-73.2793',
    city: 'Montérégie-nord',
    state: 'QC',
    country: 'Canada',
  },
  J0M: {
    zip: 'J0M',
    latitude: '60.0342',
    longitude: '-70.0118',
    city: 'Nunavik',
    state: 'QC',
    country: 'Canada',
  },
  J0N: {
    zip: 'J0N',
    latitude: '45.718',
    longitude: '-73.6354',
    city: "Region D'oka",
    state: 'QC',
    country: 'Canada',
  },
  J0P: {
    zip: 'J0P',
    latitude: '45.4487',
    longitude: '-74.1015',
    city: 'Vaudreuil- Soulanges',
    state: 'QC',
    country: 'Canada',
  },
  J0R: {
    zip: 'J0R',
    latitude: '45.8373',
    longitude: '-74.1387',
    city: 'Lanaudière-sud',
    state: 'QC',
    country: 'Canada',
  },
  J0S: {
    zip: 'J0S',
    latitude: '45.0131',
    longitude: '-74.1744',
    city: 'Montérégie- Ouest',
    state: 'QC',
    country: 'Canada',
  },
  J0T: {
    zip: 'J0T',
    latitude: '46.2634',
    longitude: '-74.7687',
    city: 'Laurentides-nord',
    state: 'QC',
    country: 'Canada',
  },
  J0V: {
    zip: 'J0V',
    latitude: '45.7631',
    longitude: '-74.4624',
    city: 'Laurentides-sud',
    state: 'QC',
    country: 'Canada',
  },
  J0W: {
    zip: 'J0W',
    latitude: '46.7019',
    longitude: '-75.437',
    city: 'Outaouais-nord',
    state: 'QC',
    country: 'Canada',
  },
  J0X: {
    zip: 'J0X',
    latitude: '45.5234',
    longitude: '-76.4392',
    city: 'Outaouais-sud',
    state: 'QC',
    country: 'Canada',
  },
  J0Y: {
    zip: 'J0Y',
    latitude: '48.4606',
    longitude: '-78.1936',
    city: 'Abitibi- Témiscamingue- Est',
    state: 'QC',
    country: 'Canada',
  },
  J0Z: {
    zip: 'J0Z',
    latitude: '47.4822',
    longitude: '-79.2102',
    city: 'Abitibi- Témiscamingue- Ouest',
    state: 'QC',
    country: 'Canada',
  },
  J1A: {
    zip: 'J1A',
    latitude: '45.1563',
    longitude: '-71.8095',
    city: 'Coaticook',
    state: 'QC',
    country: 'Canada',
  },
  J1E: {
    zip: 'J1E',
    latitude: '45.4301',
    longitude: '-71.8901',
    city: 'Sherbrooke Northeast',
    state: 'QC',
    country: 'Canada',
  },
  J1G: {
    zip: 'J1G',
    latitude: '45.4038',
    longitude: '-71.8853',
    city: 'Sherbrooke East',
    state: 'QC',
    country: 'Canada',
  },
  J1H: {
    zip: 'J1H',
    latitude: '45.4117',
    longitude: '-71.9074',
    city: 'Sherbrooke Central',
    state: 'QC',
    country: 'Canada',
  },
  J1J: {
    zip: 'J1J',
    latitude: '45.4242',
    longitude: '-71.9188',
    city: 'Sherbrooke North',
    state: 'QC',
    country: 'Canada',
  },
  J1K: {
    zip: 'J1K',
    latitude: '45.3928',
    longitude: '-71.9441',
    city: 'Sherbrooke West',
    state: 'QC',
    country: 'Canada',
  },
  J1L: {
    zip: 'J1L',
    latitude: '45.4053',
    longitude: '-71.9387',
    city: 'Sherbrooke Northwest',
    state: 'QC',
    country: 'Canada',
  },
  J1M: {
    zip: 'J1M',
    latitude: '45.3672',
    longitude: '-71.8692',
    city: 'Sherbrooke Southeast',
    state: 'QC',
    country: 'Canada',
  },
  J1N: {
    zip: 'J1N',
    latitude: '45.3814',
    longitude: '-71.9827',
    city: 'Rock Forest',
    state: 'QC',
    country: 'Canada',
  },
  J1S: {
    zip: 'J1S',
    latitude: '45.582',
    longitude: '-72.0094',
    city: 'Windsor',
    state: 'QC',
    country: 'Canada',
  },
  J1T: {
    zip: 'J1T',
    latitude: '45.7808',
    longitude: '-71.9348',
    city: 'Asbestos',
    state: 'QC',
    country: 'Canada',
  },
  J1X: {
    zip: 'J1X',
    latitude: '45.282',
    longitude: '-72.139',
    city: 'Magog',
    state: 'QC',
    country: 'Canada',
  },
  J1Z: {
    zip: 'J1Z',
    latitude: '45.8852',
    longitude: '-72.414',
    city: 'Saint-cyrille- De-wendover',
    state: 'QC',
    country: 'Canada',
  },
  J2A: {
    zip: 'J2A',
    latitude: '45.8459',
    longitude: '-72.44',
    city: 'Drummondville Southeast',
    state: 'QC',
    country: 'Canada',
  },
  J2B: {
    zip: 'J2B',
    latitude: '45.8845',
    longitude: '-72.4841',
    city: 'Drummondville South',
    state: 'QC',
    country: 'Canada',
  },
  J2C: {
    zip: 'J2C',
    latitude: '45.9092',
    longitude: '-72.4808',
    city: 'Drummondville Central',
    state: 'QC',
    country: 'Canada',
  },
  J2E: {
    zip: 'J2E',
    latitude: '45.9037',
    longitude: '-72.5297',
    city: 'Drummondville Northwest',
    state: 'QC',
    country: 'Canada',
  },
  J2G: {
    zip: 'J2G',
    latitude: '45.4109',
    longitude: '-72.7103',
    city: 'Granby Central',
    state: 'QC',
    country: 'Canada',
  },
  J2H: {
    zip: 'J2H',
    latitude: '45.4036',
    longitude: '-72.7097',
    city: 'Granby East',
    state: 'QC',
    country: 'Canada',
  },
  J2J: {
    zip: 'J2J',
    latitude: '45.3915',
    longitude: '-72.7799',
    city: 'Granby West',
    state: 'QC',
    country: 'Canada',
  },
  J2K: {
    zip: 'J2K',
    latitude: '45.2214',
    longitude: '-72.7567',
    city: 'Cowansville',
    state: 'QC',
    country: 'Canada',
  },
  J2L: {
    zip: 'J2L',
    latitude: '45.3161',
    longitude: '-72.6501',
    city: 'Bromont',
    state: 'QC',
    country: 'Canada',
  },
  J2N: {
    zip: 'J2N',
    latitude: '45.2925',
    longitude: '-72.978',
    city: 'Farnham',
    state: 'QC',
    country: 'Canada',
  },
  J2R: {
    zip: 'J2R',
    latitude: '45.648',
    longitude: '-73.0056',
    city: 'Saint-hyacinthe Northwest',
    state: 'QC',
    country: 'Canada',
  },
  J2S: {
    zip: 'J2S',
    latitude: '45.6352',
    longitude: '-72.9726',
    city: 'Saint-hyacinthe Southwest',
    state: 'QC',
    country: 'Canada',
  },
  J2T: {
    zip: 'J2T',
    latitude: '45.6414',
    longitude: '-72.9243',
    city: 'Saint-hyacinthe East',
    state: 'QC',
    country: 'Canada',
  },
  J2W: {
    zip: 'J2W',
    latitude: '45.3988',
    longitude: '-73.3723',
    city: 'Saint-luc',
    state: 'QC',
    country: 'Canada',
  },
  J2X: {
    zip: 'J2X',
    latitude: '45.3167',
    longitude: '-73.2338',
    city: 'Saint-jean- Sur-richelieu East',
    state: 'QC',
    country: 'Canada',
  },
  J2Y: {
    zip: 'J2Y',
    latitude: '45.3172',
    longitude: '-73.3346',
    city: 'Saint-jean- Sur-richelieu West',
    state: 'QC',
    country: 'Canada',
  },
  J3A: {
    zip: 'J3A',
    latitude: '45.334',
    longitude: '-73.2662',
    city: 'Saint-jean- Sur-richelieu North',
    state: 'QC',
    country: 'Canada',
  },
  J3B: {
    zip: 'J3B',
    latitude: '45.3234',
    longitude: '-73.2662',
    city: 'Saint-jean- Sur-richelieu Central',
    state: 'QC',
    country: 'Canada',
  },
  J3E: {
    zip: 'J3E',
    latitude: '45.5806',
    longitude: '-73.336',
    city: 'Sainte-julie',
    state: 'QC',
    country: 'Canada',
  },
  J3G: {
    zip: 'J3G',
    latitude: '45.5462',
    longitude: '-73.2339',
    city: 'Beloeil West',
    state: 'QC',
    country: 'Canada',
  },
  J3H: {
    zip: 'J3H',
    latitude: '45.5413',
    longitude: '-73.2215',
    city: 'Beloeil East',
    state: 'QC',
    country: 'Canada',
  },
  J3L: {
    zip: 'J3L',
    latitude: '45.4694',
    longitude: '-73.289',
    city: 'Chambly',
    state: 'QC',
    country: 'Canada',
  },
  J3M: {
    zip: 'J3M',
    latitude: '45.4355',
    longitude: '-73.1738',
    city: 'Marieville',
    state: 'QC',
    country: 'Canada',
  },
  J3N: {
    zip: 'J3N',
    latitude: '45.5355',
    longitude: '-73.2719',
    city: 'Saint-basile- Le-grand',
    state: 'QC',
    country: 'Canada',
  },
  J3P: {
    zip: 'J3P',
    latitude: '46.045',
    longitude: '-73.1172',
    city: 'Sorel Central',
    state: 'QC',
    country: 'Canada',
  },
  J3R: {
    zip: 'J3R',
    latitude: '46.0476',
    longitude: '-73.1263',
    city: 'Sorel Southwest',
    state: 'QC',
    country: 'Canada',
  },
  J3T: {
    zip: 'J3T',
    latitude: '46.2326',
    longitude: '-72.5995',
    city: 'Nicolet',
    state: 'QC',
    country: 'Canada',
  },
  J3V: {
    zip: 'J3V',
    latitude: '45.5392',
    longitude: '-73.3598',
    city: 'Saint-bruno',
    state: 'QC',
    country: 'Canada',
  },
  J3X: {
    zip: 'J3X',
    latitude: '45.6911',
    longitude: '-73.4312',
    city: 'Varennes',
    state: 'QC',
    country: 'Canada',
  },
  J3Y: {
    zip: 'J3Y',
    latitude: '45.4841',
    longitude: '-73.4329',
    city: 'Saint-hubert Central',
    state: 'QC',
    country: 'Canada',
  },
  J3Z: {
    zip: 'J3Z',
    latitude: '45.4732',
    longitude: '-73.3716',
    city: 'Saint-hubert East',
    state: 'QC',
    country: 'Canada',
  },
  J4B: {
    zip: 'J4B',
    latitude: '45.5685',
    longitude: '-73.423',
    city: 'Boucherville',
    state: 'QC',
    country: 'Canada',
  },
  J4G: {
    zip: 'J4G',
    latitude: '45.5535',
    longitude: '-73.4987',
    city: 'Longueuil North',
    state: 'QC',
    country: 'Canada',
  },
  J4H: {
    zip: 'J4H',
    latitude: '45.5428',
    longitude: '-73.5083',
    city: 'Longueuil West',
    state: 'QC',
    country: 'Canada',
  },
  J4J: {
    zip: 'J4J',
    latitude: '45.529',
    longitude: '-73.5039',
    city: 'Longueuil Central',
    state: 'QC',
    country: 'Canada',
  },
  J4K: {
    zip: 'J4K',
    latitude: '45.5284',
    longitude: '-73.5246',
    city: 'Longueuil Southwest',
    state: 'QC',
    country: 'Canada',
  },
  J4L: {
    zip: 'J4L',
    latitude: '45.5291',
    longitude: '-73.4708',
    city: 'Longueuil Southeast',
    state: 'QC',
    country: 'Canada',
  },
  J4M: {
    zip: 'J4M',
    latitude: '45.544',
    longitude: '-73.4505',
    city: 'Longueuil East',
    state: 'QC',
    country: 'Canada',
  },
  J4N: {
    zip: 'J4N',
    latitude: '45.5382',
    longitude: '-73.4577',
    city: 'Longueuil Northeast',
    state: 'QC',
    country: 'Canada',
  },
  J4P: {
    zip: 'J4P',
    latitude: '45.4993',
    longitude: '-73.5157',
    city: 'Saint-lambert North',
    state: 'QC',
    country: 'Canada',
  },
  J4R: {
    zip: 'J4R',
    latitude: '45.4876',
    longitude: '-73.5092',
    city: 'Saint-lambert Central',
    state: 'QC',
    country: 'Canada',
  },
  J4S: {
    zip: 'J4S',
    latitude: '45.4832',
    longitude: '-73.5067',
    city: 'Saint-lambert South',
    state: 'QC',
    country: 'Canada',
  },
  J4T: {
    zip: 'J4T',
    latitude: '45.4966',
    longitude: '-73.4481',
    city: 'Saint-hubert West',
    state: 'QC',
    country: 'Canada',
  },
  J4V: {
    zip: 'J4V',
    latitude: '45.4926',
    longitude: '-73.4473',
    city: 'Greenfield Park',
    state: 'QC',
    country: 'Canada',
  },
  J4W: {
    zip: 'J4W',
    latitude: '45.4769',
    longitude: '-73.4992',
    city: 'Brossard Northwest',
    state: 'QC',
    country: 'Canada',
  },
  J4X: {
    zip: 'J4X',
    latitude: '45.4564',
    longitude: '-73.4931',
    city: 'Brossard Southwest',
    state: 'QC',
    country: 'Canada',
  },
  J4Y: {
    zip: 'J4Y',
    latitude: '45.4605',
    longitude: '-73.4651',
    city: 'Brossard South',
    state: 'QC',
    country: 'Canada',
  },
  J4Z: {
    zip: 'J4Z',
    latitude: '45.4814',
    longitude: '-73.4649',
    city: 'Brossard Northeast',
    state: 'QC',
    country: 'Canada',
  },
  J5A: {
    zip: 'J5A',
    latitude: '45.384',
    longitude: '-73.5591',
    city: 'Saint-constant',
    state: 'QC',
    country: 'Canada',
  },
  J5B: {
    zip: 'J5B',
    latitude: '45.4024',
    longitude: '-73.5376',
    city: 'Delson',
    state: 'QC',
    country: 'Canada',
  },
  J5J: {
    zip: 'J5J',
    latitude: '45.8184',
    longitude: '-73.8983',
    city: 'Saint-sophie',
    state: 'QC',
    country: 'Canada',
  },
  J5K: {
    zip: 'J5K',
    latitude: '45.7334',
    longitude: '-74.1309',
    city: 'Saint-colomban',
    state: 'QC',
    country: 'Canada',
  },
  J5L: {
    zip: 'J5L',
    latitude: '45.8052',
    longitude: '-74.1051',
    city: 'Saint-jérôme West',
    state: 'QC',
    country: 'Canada',
  },
  J5M: {
    zip: 'J5M',
    latitude: '45.8522',
    longitude: '-73.7577',
    city: 'Saint-lin- Laurentides',
    state: 'QC',
    country: 'Canada',
  },
  J5R: {
    zip: 'J5R',
    latitude: '45.3973',
    longitude: '-73.5284',
    city: 'La Prairie',
    state: 'QC',
    country: 'Canada',
  },
  J5T: {
    zip: 'J5T',
    latitude: '45.905',
    longitude: '-73.2594',
    city: 'Lavaltrie',
    state: 'QC',
    country: 'Canada',
  },
  J5V: {
    zip: 'J5V',
    latitude: '46.2675',
    longitude: '-72.9382',
    city: 'Louiseville',
    state: 'QC',
    country: 'Canada',
  },
  J5W: {
    zip: 'J5W',
    latitude: '45.8313',
    longitude: '-73.4233',
    city: "L'assomption",
    state: 'QC',
    country: 'Canada',
  },
  J5X: {
    zip: 'J5X',
    latitude: '45.8508',
    longitude: '-73.4824',
    city: "L'Épiphanie",
    state: 'QC',
    country: 'Canada',
  },
  J5Y: {
    zip: 'J5Y',
    latitude: '45.7599',
    longitude: '-73.4343',
    city: 'Repentigny Northeast',
    state: 'QC',
    country: 'Canada',
  },
  J5Z: {
    zip: 'J5Z',
    latitude: '45.7289',
    longitude: '-73.4907',
    city: 'Repentigny West',
    state: 'QC',
    country: 'Canada',
  },
  J6A: {
    zip: 'J6A',
    latitude: '45.7134',
    longitude: '-73.4778',
    city: 'Repentigny South',
    state: 'QC',
    country: 'Canada',
  },
  J6E: {
    zip: 'J6E',
    latitude: '46.0551',
    longitude: '-73.432',
    city: 'Joliette',
    state: 'QC',
    country: 'Canada',
  },
  J6J: {
    zip: 'J6J',
    latitude: '45.3944',
    longitude: '-73.7494',
    city: 'Châteauguay North',
    state: 'QC',
    country: 'Canada',
  },
  J6K: {
    zip: 'J6K',
    latitude: '45.3631',
    longitude: '-73.7085',
    city: 'Châteauguay South',
    state: 'QC',
    country: 'Canada',
  },
  J6N: {
    zip: 'J6N',
    latitude: '45.3577',
    longitude: '-73.7851',
    city: 'Beauharnois',
    state: 'QC',
    country: 'Canada',
  },
  J6R: {
    zip: 'J6R',
    latitude: '45.3063',
    longitude: '-73.748',
    city: 'Mercier',
    state: 'QC',
    country: 'Canada',
  },
  J6S: {
    zip: 'J6S',
    latitude: '45.2788',
    longitude: '-74.1422',
    city: 'Salaberry-de- Valleyfield North',
    state: 'QC',
    country: 'Canada',
  },
  J6T: {
    zip: 'J6T',
    latitude: '45.2571',
    longitude: '-74.12',
    city: 'Salaberry-de- Valleyfield South',
    state: 'QC',
    country: 'Canada',
  },
  J6V: {
    zip: 'J6V',
    latitude: '45.7005',
    longitude: '-73.5298',
    city: 'Terrebonne East',
    state: 'QC',
    country: 'Canada',
  },
  J6W: {
    zip: 'J6W',
    latitude: '45.6908',
    longitude: '-73.6308',
    city: 'Terrebonne Central',
    state: 'QC',
    country: 'Canada',
  },
  J6X: {
    zip: 'J6X',
    latitude: '45.6986',
    longitude: '-73.6632',
    city: 'Terrebonne Northwest',
    state: 'QC',
    country: 'Canada',
  },
  J6Y: {
    zip: 'J6Y',
    latitude: '45.6999',
    longitude: '-73.8112',
    city: 'Terrebonne Southwest',
    state: 'QC',
    country: 'Canada',
  },
  J6Z: {
    zip: 'J6Z',
    latitude: '45.6693',
    longitude: '-73.7484',
    city: 'Sainte-thérèse- De-blainville Northeast',
    state: 'QC',
    country: 'Canada',
  },
  J7A: {
    zip: 'J7A',
    latitude: '45.6179',
    longitude: '-73.8038',
    city: 'Sainte-thérèse- De-blainville East',
    state: 'QC',
    country: 'Canada',
  },
  J7B: {
    zip: 'J7B',
    latitude: '45.6462',
    longitude: '-73.8092',
    city: 'Sainte-thérèse- De-blainville North',
    state: 'QC',
    country: 'Canada',
  },
  J7C: {
    zip: 'J7C',
    latitude: '45.6488',
    longitude: '-73.8466',
    city: 'Sainte-thérèse- De-blainville Northwest',
    state: 'QC',
    country: 'Canada',
  },
  J7E: {
    zip: 'J7E',
    latitude: '45.6318',
    longitude: '-73.8261',
    city: 'Sainte-thérèse- De-blainville Central',
    state: 'QC',
    country: 'Canada',
  },
  J7G: {
    zip: 'J7G',
    latitude: '45.5999',
    longitude: '-73.8301',
    city: 'Sainte-thérèse- De-blainville South',
    state: 'QC',
    country: 'Canada',
  },
  J7H: {
    zip: 'J7H',
    latitude: '45.62',
    longitude: '-73.8564',
    city: 'Sainte-thérèse- De-blainville Southwest',
    state: 'QC',
    country: 'Canada',
  },
  J7J: {
    zip: 'J7J',
    latitude: '45.6563',
    longitude: '-73.9753',
    city: 'Mirabel Northeast',
    state: 'QC',
    country: 'Canada',
  },
  J7K: {
    zip: 'J7K',
    latitude: '45.7551',
    longitude: '-73.5959',
    city: 'Mascouche Extremities',
    state: 'QC',
    country: 'Canada',
  },
  J7L: {
    zip: 'J7L',
    latitude: '45.7567',
    longitude: '-73.6263',
    city: 'Mascouche Central',
    state: 'QC',
    country: 'Canada',
  },
  J7M: {
    zip: 'J7M',
    latitude: '45.7915',
    longitude: '-73.7559',
    city: 'La Plaine',
    state: 'QC',
    country: 'Canada',
  },
  J7N: {
    zip: 'J7N',
    latitude: '45.72',
    longitude: '-74.0327',
    city: 'Mirabel Southwest',
    state: 'QC',
    country: 'Canada',
  },
  J7P: {
    zip: 'J7P',
    latitude: '45.5618',
    longitude: '-73.8881',
    city: 'Saint-eustache Northeast',
    state: 'QC',
    country: 'Canada',
  },
  J7R: {
    zip: 'J7R',
    latitude: '45.5321',
    longitude: '-73.894',
    city: 'Saint-eustache Southwest',
    state: 'QC',
    country: 'Canada',
  },
  J7T: {
    zip: 'J7T',
    latitude: '45.3135',
    longitude: '-74.0573',
    city: 'Vaudreuil- Dorion Rcm',
    state: 'QC',
    country: 'Canada',
  },
  J7V: {
    zip: 'J7V',
    latitude: '45.4042',
    longitude: '-74.034',
    city: 'Vaudreuil- Dorion',
    state: 'QC',
    country: 'Canada',
  },
  J7X: {
    zip: 'J7X',
    latitude: '45.2616',
    longitude: '-74.2078',
    city: 'Valleyfield',
    state: 'QC',
    country: 'Canada',
  },
  J7Y: {
    zip: 'J7Y',
    latitude: '45.814',
    longitude: '-74.0176',
    city: 'Saint-jérôme North',
    state: 'QC',
    country: 'Canada',
  },
  J7Z: {
    zip: 'J7Z',
    latitude: '45.795',
    longitude: '-74.0017',
    city: 'Saint-jérôme Southeast',
    state: 'QC',
    country: 'Canada',
  },
  J8A: {
    zip: 'J8A',
    latitude: '45.9261',
    longitude: '-74.0244',
    city: 'Saint-hippolyte',
    state: 'QC',
    country: 'Canada',
  },
  J8B: {
    zip: 'J8B',
    latitude: '45.9454',
    longitude: '-74.1327',
    city: 'Sainte-adèle',
    state: 'QC',
    country: 'Canada',
  },
  J8C: {
    zip: 'J8C',
    latitude: '46.0469',
    longitude: '-74.2901',
    city: 'Sainte-agathe- Des-monts',
    state: 'QC',
    country: 'Canada',
  },
  J8E: {
    zip: 'J8E',
    latitude: '46.156',
    longitude: '-74.5627',
    city: 'Mont-tremblant',
    state: 'QC',
    country: 'Canada',
  },
  J8G: {
    zip: 'J8G',
    latitude: '45.6068',
    longitude: '-74.4387',
    city: 'Chatham',
    state: 'QC',
    country: 'Canada',
  },
  J8H: {
    zip: 'J8H',
    latitude: '45.6484',
    longitude: '-74.3406',
    city: 'Lachute',
    state: 'QC',
    country: 'Canada',
  },
  J8L: {
    zip: 'J8L',
    latitude: '45.599',
    longitude: '-75.4206',
    city: 'Buckingham',
    state: 'QC',
    country: 'Canada',
  },
  J8M: {
    zip: 'J8M',
    latitude: '45.5555',
    longitude: '-75.4352',
    city: 'Masson-angers',
    state: 'QC',
    country: 'Canada',
  },
  J8N: {
    zip: 'J8N',
    latitude: '45.688',
    longitude: '-75.7837',
    city: 'Val-des-monts',
    state: 'QC',
    country: 'Canada',
  },
  J8P: {
    zip: 'J8P',
    latitude: '45.495',
    longitude: '-75.5883',
    city: 'Gatineau Southeast',
    state: 'QC',
    country: 'Canada',
  },
  J8R: {
    zip: 'J8R',
    latitude: '45.4914',
    longitude: '-75.6057',
    city: 'Gatineau Northeast',
    state: 'QC',
    country: 'Canada',
  },
  J8T: {
    zip: 'J8T',
    latitude: '45.4979',
    longitude: '-75.7043',
    city: 'Gatineau Southwest',
    state: 'QC',
    country: 'Canada',
  },
  J8V: {
    zip: 'J8V',
    latitude: '45.488',
    longitude: '-75.7474',
    city: 'Gatineau Northwest',
    state: 'QC',
    country: 'Canada',
  },
  J8X: {
    zip: 'J8X',
    latitude: '45.4465',
    longitude: '-75.7156',
    city: 'Hull Southeast',
    state: 'QC',
    country: 'Canada',
  },
  J8Y: {
    zip: 'J8Y',
    latitude: '45.4603',
    longitude: '-75.7606',
    city: 'Hull Central',
    state: 'QC',
    country: 'Canada',
  },
  J8Z: {
    zip: 'J8Z',
    latitude: '45.4659',
    longitude: '-75.7558',
    city: 'Hull North',
    state: 'QC',
    country: 'Canada',
  },
  J9A: {
    zip: 'J9A',
    latitude: '45.4206',
    longitude: '-75.7538',
    city: 'Hull Southwest',
    state: 'QC',
    country: 'Canada',
  },
  J9B: {
    zip: 'J9B',
    latitude: '45.4039',
    longitude: '-75.826',
    city: 'Chelsea',
    state: 'QC',
    country: 'Canada',
  },
  J9E: {
    zip: 'J9E',
    latitude: '46.3741',
    longitude: '-75.9823',
    city: 'Maniwaki',
    state: 'QC',
    country: 'Canada',
  },
  J9H: {
    zip: 'J9H',
    latitude: '45.3958',
    longitude: '-75.8259',
    city: 'Aylmer South',
    state: 'QC',
    country: 'Canada',
  },
  J9J: {
    zip: 'J9J',
    latitude: '45.4202',
    longitude: '-75.7748',
    city: 'Aylmer North',
    state: 'QC',
    country: 'Canada',
  },
  J9L: {
    zip: 'J9L',
    latitude: '46.5442',
    longitude: '-75.4972',
    city: 'Mont-laurier',
    state: 'QC',
    country: 'Canada',
  },
  J9P: {
    zip: 'J9P',
    latitude: '48.1068',
    longitude: '-77.7833',
    city: "Val-d'or",
    state: 'QC',
    country: 'Canada',
  },
  J9T: {
    zip: 'J9T',
    latitude: '48.5837',
    longitude: '-78.1002',
    city: 'Amos',
    state: 'QC',
    country: 'Canada',
  },
  J9V: {
    zip: 'J9V',
    latitude: '47.3288',
    longitude: '-79.441',
    city: 'Ville-marie',
    state: 'QC',
    country: 'Canada',
  },
  J9X: {
    zip: 'J9X',
    latitude: '48.25',
    longitude: '-79.0253',
    city: 'Rouyn-noranda South',
    state: 'QC',
    country: 'Canada',
  },
  J9Y: {
    zip: 'J9Y',
    latitude: '48.8054',
    longitude: '-79.1991',
    city: 'Rouyn-noranda North',
    state: 'QC',
    country: 'Canada',
  },
  J9Z: {
    zip: 'J9Z',
    latitude: '48.8131',
    longitude: '-79.2026',
    city: 'La Sarre',
    state: 'QC',
    country: 'Canada',
  },
  S0A: {
    zip: 'S0A',
    latitude: '51.8194',
    longitude: '-103.5644',
    city: 'Yorkton Region',
    state: 'SK',
    country: 'Canada',
  },
  S0C: {
    zip: 'S0C',
    latitude: '49.1895',
    longitude: '-104.4374',
    city: 'Southeastern Saskatchewan',
    state: 'SK',
    country: 'Canada',
  },
  S0E: {
    zip: 'S0E',
    latitude: '53.1325',
    longitude: '-104.6719',
    city: 'Eastern Saskatchewan',
    state: 'SK',
    country: 'Canada',
  },
  S0G: {
    zip: 'S0G',
    latitude: '51.3669',
    longitude: '-105.9973',
    city: "South Central Saskatchewan",
    state: 'SK',
    country: 'Canada',
  },
  S0H: {
    zip: 'S0H',
    latitude: '50.1971',
    longitude: '-105.8481',
    city: 'Southern Saskatchewan',
    state: 'SK',
    country: 'Canada',
  },
  S0J: {
    zip: 'S0J',
    latitude: '52.7586',
    longitude: '-107.4669',
    city: 'Northern Saskatchewan',
    state: 'SK',
    country: 'Canada',
  },
  S0K: {
    zip: 'S0K',
    latitude: '52.807',
    longitude: '-105.3626',
    city: 'Central Saskatchewan',
    state: 'SK',
    country: 'Canada',
  },
  S0L: {
    zip: 'S0L',
    latitude: '51.2296',
    longitude: '-108.702',
    city: 'Western Saskatchewan',
    state: 'SK',
    country: 'Canada',
  },
  S0M: {
    zip: 'S0M',
    latitude: '54.2836',
    longitude: '-109.2415',
    city: 'Northwestern Saskatchewan',
    state: 'SK',
    country: 'Canada',
  },
  S0N: {
    zip: 'S0N',
    latitude: '50.3599',
    longitude: '-108.5139',
    city: 'Southwestern Saskatchewan',
    state: 'SK',
    country: 'Canada',
  },
  S0P: {
    zip: 'S0P',
    latitude: '54.663',
    longitude: '-102.0822',
    city: 'Northeastern Saskatchewan',
    state: 'SK',
    country: 'Canada',
  },
  S2V: {
    zip: 'S2V',
    latitude: '50.7763',
    longitude: '-104.9291',
    city: 'Buena Vista',
    state: 'SK',
    country: 'Canada',
  },
  S3N: {
    zip: 'S3N',
    latitude: '51.202',
    longitude: '-102.457',
    city: 'Yorkton',
    state: 'SK',
    country: 'Canada',
  },
  S4A: {
    zip: 'S4A',
    latitude: '49.1433',
    longitude: '-102.9987',
    city: 'Estevan',
    state: 'SK',
    country: 'Canada',
  },
  S4H: {
    zip: 'S4H',
    latitude: '49.6719',
    longitude: '-103.8491',
    city: 'Weyburn',
    state: 'SK',
    country: 'Canada',
  },
  S4L: {
    zip: 'S4L',
    latitude: '50.4395',
    longitude: '-104.5758',
    city: 'Regina East',
    state: 'SK',
    country: 'Canada',
  },
  S4N: {
    zip: 'S4N',
    latitude: '50.4399',
    longitude: '-104.574',
    city: 'Regina Northeast And East Central',
    state: 'SK',
    country: 'Canada',
  },
  S4P: {
    zip: 'S4P',
    latitude: '50.4423',
    longitude: '-104.6116',
    city: 'Regina Central',
    state: 'SK',
    country: 'Canada',
  },
  S4R: {
    zip: 'S4R',
    latitude: '50.4707',
    longitude: '-104.6116',
    city: 'Regina North Central',
    state: 'SK',
    country: 'Canada',
  },
  S4S: {
    zip: 'S4S',
    latitude: '50.4253',
    longitude: '-104.6347',
    city: 'Regina South Saskatchewan Provincial Government',
    state: 'SK',
    country: 'Canada',
  },
  S4T: {
    zip: 'S4T',
    latitude: '50.4552',
    longitude: '-104.6376',
    city: 'Regina West',
    state: 'SK',
    country: 'Canada',
  },
  S4V: {
    zip: 'S4V',
    latitude: '50.4364',
    longitude: '-104.5438',
    city: 'Regina Southeast',
    state: 'SK',
    country: 'Canada',
  },
  S4W: {
    zip: 'S4W',
    latitude: '50.4896',
    longitude: '-104.6694',
    city: 'Regina Southwest',
    state: 'SK',
    country: 'Canada',
  },
  S4X: {
    zip: 'S4X',
    latitude: '50.4722',
    longitude: '-104.6828',
    city: 'Regina Northwest',
    state: 'SK',
    country: 'Canada',
  },
  S4Y: {
    zip: 'S4Y',
    latitude: '50.478',
    longitude: '-104.6987',
    city: 'Regina Outer Northwest',
    state: 'SK',
    country: 'Canada',
  },
  S4Z: {
    zip: 'S4Z',
    latitude: '50.4529',
    longitude: '-104.5345',
    city: 'Regina Northeast',
    state: 'SK',
    country: 'Canada',
  },
  S6H: {
    zip: 'S6H',
    latitude: '50.4019',
    longitude: '-105.5325',
    city: 'Moose Jaw Southeast',
    state: 'SK',
    country: 'Canada',
  },
  S6J: {
    zip: 'S6J',
    latitude: '50.4241',
    longitude: '-105.5467',
    city: 'Moose Jaw Northeast',
    state: 'SK',
    country: 'Canada',
  },
  S6K: {
    zip: 'S6K',
    latitude: '50.3768',
    longitude: '-105.5819',
    city: 'Moose Jaw West',
    state: 'SK',
    country: 'Canada',
  },
  S6V: {
    zip: 'S6V',
    latitude: '53.2027',
    longitude: '-105.7503',
    city: 'Prince Albert Central',
    state: 'SK',
    country: 'Canada',
  },
  S6W: {
    zip: 'S6W',
    latitude: '53.1744',
    longitude: '-105.7636',
    city: 'Prince Albert Southwest',
    state: 'SK',
    country: 'Canada',
  },
  S6X: {
    zip: 'S6X',
    latitude: '53.1922',
    longitude: '-105.7055',
    city: 'Prince Albert East',
    state: 'SK',
    country: 'Canada',
  },
  S7H: {
    zip: 'S7H',
    latitude: '52.1131',
    longitude: '-106.622',
    city: 'Saskatoon East Central',
    state: 'SK',
    country: 'Canada',
  },
  S7J: {
    zip: 'S7J',
    latitude: '52.1068',
    longitude: '-106.6552',
    city: 'Saskatoon South Central',
    state: 'SK',
    country: 'Canada',
  },
  S7K: {
    zip: 'S7K',
    latitude: '52.1542',
    longitude: '-106.6415',
    city: 'Saskatoon North Central',
    state: 'SK',
    country: 'Canada',
  },
  S7L: {
    zip: 'S7L',
    latitude: '52.1449',
    longitude: '-106.6704',
    city: 'Saskatoon West',
    state: 'SK',
    country: 'Canada',
  },
  S7M: {
    zip: 'S7M',
    latitude: '52.1261',
    longitude: '-106.6985',
    city: 'Saskatoon Southwest',
    state: 'SK',
    country: 'Canada',
  },
  S7N: {
    zip: 'S7N',
    latitude: '52.1193',
    longitude: '-106.6594',
    city: 'Saskatoon Northeast Central',
    state: 'SK',
    country: 'Canada',
  },
  S7P: {
    zip: 'S7P',
    latitude: '52.1695',
    longitude: '-106.5869',
    city: 'Saskatoon North',
    state: 'SK',
    country: 'Canada',
  },
  S7R: {
    zip: 'S7R',
    latitude: '52.2022',
    longitude: '-106.6765',
    city: 'Saskatoon Northwest',
    state: 'SK',
    country: 'Canada',
  },
  S7S: {
    zip: 'S7S',
    latitude: '52.1584',
    longitude: '-106.5955',
    city: 'Saskatoon Northeast',
    state: 'SK',
    country: 'Canada',
  },
  S7T: {
    zip: 'S7T',
    latitude: '52.0554',
    longitude: '-106.7036',
    city: 'Saskatoon South',
    state: 'SK',
    country: 'Canada',
  },
  S7V: {
    zip: 'S7V',
    latitude: '52.1103',
    longitude: '-106.5698',
    city: 'Saskatoon Southeast',
    state: 'SK',
    country: 'Canada',
  },
  S9A: {
    zip: 'S9A',
    latitude: '52.779',
    longitude: '-108.2983',
    city: 'North Battleford',
    state: 'SK',
    country: 'Canada',
  },
  S9H: {
    zip: 'S9H',
    latitude: '50.2875',
    longitude: '-107.8113',
    city: 'Swift Current',
    state: 'SK',
    country: 'Canada',
  },
  S9V: {
    zip: 'S9V',
    latitude: '53.2719',
    longitude: '-110.0044',
    city: 'Lloydminster',
    state: 'SK',
    country: 'Canada',
  },
  S9X: {
    zip: 'S9X',
    latitude: '54.132',
    longitude: '-108.4314',
    city: 'Meadow Lake',
    state: 'SK',
    country: 'Canada',
  },
  Y0A: {
    zip: 'Y0A',
    latitude: '60.1734',
    longitude: '-129.0159',
    city: 'Southeastern Yukon',
    state: 'YT',
    country: 'Canada',
  },
  Y0B: {
    zip: 'Y0B',
    latitude: '64.062',
    longitude: '-139.4351',
    city: 'Central Yukon',
    state: 'YT',
    country: 'Canada',
  },
  Y1A: {
    zip: 'Y1A',
    latitude: '60.7227',
    longitude: '-135.0534',
    city: 'Whitehorse',
    state: 'YT',
    country: 'Canada',
  },
};
const stateMap = {
  AB: [
    'T0A',
    'T0B',
    'T0C',
    'T0E',
    'T0G',
    'T0H',
    'T0J',
    'T0K',
    'T0L',
    'T0M',
    'T0P',
    'T0V',
    'T1A',
    'T1B',
    'T1C',
    'T1G',
    'T1H',
    'T1J',
    'T1K',
    'T1L',
    'T1M',
    'T1P',
    'T1R',
    'T1S',
    'T1V',
    'T1W',
    'T1X',
    'T1Y',
    'T2A',
    'T2B',
    'T2C',
    'T2E',
    'T2G',
    'T2H',
    'T2J',
    'T2K',
    'T2L',
    'T2M',
    'T2N',
    'T2P',
    'T2R',
    'T2S',
    'T2T',
    'T2V',
    'T2W',
    'T2X',
    'T2Y',
    'T2Z',
    'T3A',
    'T3B',
    'T3C',
    'T3E',
    'T3G',
    'T3H',
    'T3J',
    'T3K',
    'T3L',
    'T3M',
    'T3N',
    'T3P',
    'T3R',
    'T3Z',
    'T4A',
    'T4B',
    'T4C',
    'T4E',
    'T4G',
    'T4H',
    'T4J',
    'T4L',
    'T4N',
    'T4P',
    'T4R',
    'T4S',
    'T4T',
    'T4V',
    'T4X',
    'T5A',
    'T5B',
    'T5C',
    'T5E',
    'T5G',
    'T5H',
    'T5J',
    'T5K',
    'T5L',
    'T5M',
    'T5N',
    'T5P',
    'T5R',
    'T5S',
    'T5T',
    'T5V',
    'T5W',
    'T5X',
    'T5Y',
    'T5Z',
    'T6A',
    'T6B',
    'T6C',
    'T6E',
    'T6G',
    'T6H',
    'T6J',
    'T6K',
    'T6L',
    'T6M',
    'T6N',
    'T6P',
    'T6R',
    'T6S',
    'T6T',
    'T6V',
    'T6W',
    'T6X',
    'T7A',
    'T7E',
    'T7N',
    'T7P',
    'T7S',
    'T7V',
    'T7X',
    'T7Y',
    'T7Z',
    'T8A',
    'T8B',
    'T8C',
    'T8E',
    'T8G',
    'T8H',
    'T8L',
    'T8N',
    'T8R',
    'T8S',
    'T8V',
    'T8W',
    'T8X',
    'T9A',
    'T9C',
    'T9E',
    'T9G',
    'T9H',
    'T9J',
    'T9K',
    'T9M',
    'T9N',
    'T9S',
    'T9V',
    'T9W',
    'T9X',
  ],
  BC: [
    'V0A',
    'V0B',
    'V0C',
    'V0E',
    'V0G',
    'V0H',
    'V0J',
    'V0K',
    'V0L',
    'V0M',
    'V0P',
    'V0R',
    'V0S',
    'V0T',
    'V0V',
    'V0W',
    'V0X',
    'V1A',
    'V1B',
    'V1C',
    'V1E',
    'V1G',
    'V1H',
    'V1J',
    'V1K',
    'V1L',
    'V1M',
    'V1N',
    'V1P',
    'V1R',
    'V1S',
    'V1T',
    'V1V',
    'V1W',
    'V1X',
    'V1Y',
    'V1Z',
    'V2A',
    'V2B',
    'V2C',
    'V2E',
    'V2G',
    'V2H',
    'V2J',
    'V2K',
    'V2L',
    'V2M',
    'V2N',
    'V2P',
    'V2R',
    'V2S',
    'V2T',
    'V2V',
    'V2W',
    'V2X',
    'V2Y',
    'V2Z',
    'V3A',
    'V3B',
    'V3C',
    'V3E',
    'V3G',
    'V3H',
    'V3J',
    'V3K',
    'V3L',
    'V3M',
    'V3N',
    'V3R',
    'V3S',
    'V3T',
    'V3V',
    'V3W',
    'V3X',
    'V3Y',
    'V4A',
    'V4B',
    'V4C',
    'V4E',
    'V4G',
    'V4K',
    'V4L',
    'V4M',
    'V4N',
    'V4P',
    'V4R',
    'V4S',
    'V4T',
    'V4V',
    'V4W',
    'V4X',
    'V4Z',
    'V5A',
    'V5B',
    'V5C',
    'V5E',
    'V5G',
    'V5H',
    'V5J',
    'V5K',
    'V5L',
    'V5M',
    'V5N',
    'V5P',
    'V5R',
    'V5S',
    'V5T',
    'V5V',
    'V5W',
    'V5X',
    'V5Y',
    'V5Z',
    'V6A',
    'V6B',
    'V6C',
    'V6E',
    'V6G',
    'V6H',
    'V6J',
    'V6K',
    'V6L',
    'V6M',
    'V6N',
    'V6P',
    'V6R',
    'V6S',
    'V6T',
    'V6V',
    'V6W',
    'V6X',
    'V6Y',
    'V6Z',
    'V7A',
    'V7B',
    'V7C',
    'V7E',
    'V7G',
    'V7H',
    'V7J',
    'V7K',
    'V7L',
    'V7M',
    'V7N',
    'V7P',
    'V7R',
    'V7S',
    'V7T',
    'V7V',
    'V7W',
    'V7X',
    'V7Y',
    'V8A',
    'V8B',
    'V8C',
    'V8G',
    'V8J',
    'V8K',
    'V8L',
    'V8M',
    'V8N',
    'V8P',
    'V8R',
    'V8S',
    'V8T',
    'V8V',
    'V8W',
    'V8X',
    'V8Y',
    'V8Z',
    'V9A',
    'V9B',
    'V9C',
    'V9E',
    'V9G',
    'V9H',
    'V9J',
    'V9K',
    'V9L',
    'V9M',
    'V9N',
    'V9P',
    'V9R',
    'V9S',
    'V9T',
    'V9V',
    'V9W',
    'V9X',
    'V9Y',
  ],
  'Sunshine Coast': ['V0N'],
  MB: [
    'R0A',
    'R0B',
    'R0C',
    'R0E',
    'R0G',
    'R0H',
    'R0J',
    'R0K',
    'R0L',
    'R0M',
    'R1A',
    'R1B',
    'R1N',
    'R2C',
    'R2E',
    'R2G',
    'R2H',
    'R2J',
    'R2K',
    'R2L',
    'R2M',
    'R2N',
    'R2P',
    'R2R',
    'R2V',
    'R2W',
    'R2X',
    'R2Y',
    'R3A',
    'R3B',
    'R3C',
    'R3E',
    'R3G',
    'R3H',
    'R3J',
    'R3K',
    'R3L',
    'R3M',
    'R3N',
    'R3P',
    'R3R',
    'R3S',
    'R3T',
    'R3V',
    'R3W',
    'R3X',
    'R3Y',
    'R4A',
    'R4H',
    'R4J',
    'R4K',
    'R4L',
    'R5A',
    'R5G',
    'R5H',
    'R6M',
    'R6W',
    'R7A',
    'R7B',
    'R7C',
    'R7N',
    'R8A',
    'R8N',
    'R9A',
  ],
  NB: [
    'E1A',
    'E1B',
    'E1C',
    'E1E',
    'E1G',
    'E1J',
    'E1N',
    'E1V',
    'E1W',
    'E1X',
    'E2A',
    'E2G',
    'E2J',
    'E2K',
    'E2L',
    'E2M',
    'E2N',
    'E2P',
    'E2R',
    'E2S',
    'E2V',
    'E3A',
    'E3B',
    'E3E',
    'E3L',
    'E3N',
    'E3V',
    'E3Y',
    'E3Z',
    'E4A',
    'E4B',
    'E4C',
    'E4E',
    'E4G',
    'E4H',
    'E4J',
    'E4K',
    'E4L',
    'E4M',
    'E4N',
    'E4P',
    'E4R',
    'E4S',
    'E4T',
    'E4V',
    'E4W',
    'E4X',
    'E4Y',
    'E4Z',
    'E5A',
    'E5B',
    'E5C',
    'E5E',
    'E5G',
    'E5H',
    'E5J',
    'E5K',
    'E5L',
    'E5M',
    'E5N',
    'E5P',
    'E5R',
    'E5S',
    'E5T',
    'E5V',
    'E6A',
    'E6B',
    'E6C',
    'E6E',
    'E6G',
    'E6H',
    'E6J',
    'E6K',
    'E6L',
    'E7A',
    'E7B',
    'E7C',
    'E7E',
    'E7G',
    'E7H',
    'E7J',
    'E7K',
    'E7L',
    'E7M',
    'E7N',
    'E7P',
    'E8A',
    'E8B',
    'E8C',
    'E8E',
    'E8G',
    'E8J',
    'E8K',
    'E8L',
    'E8M',
    'E8N',
    'E8P',
    'E8R',
    'E8S',
    'E8T',
    'E9A',
    'E9B',
    'E9C',
    'E9E',
    'E9G',
    'E9H',
  ],
  'Shediac Bridge': ['E1H'],
  Quispamsis: ['E2E'],
  Renforth: ['E2H'],
  'New Maryland': ['E3C'],
  NL: [
    'A0A',
    'A0B',
    'A0C',
    'A0E',
    'A0G',
    'A0H',
    'A0J',
    'A0K',
    'A0L',
    'A0M',
    'A0N',
    'A0P',
    'A0R',
    'A1A',
    'A1B',
    'A1C',
    'A1E',
    'A1G',
    'A1H',
    'A1K',
    'A1L',
    'A1M',
    'A1N',
    'A1S',
    'A1V',
    'A1W',
    'A1X',
    'A1Y',
    'A2A',
    'A2B',
    'A2H',
    'A2N',
    'A2V',
    'A5A',
    'A8A',
  ],
  NS: [
    'B0C',
    'B0E',
    'B0H',
    'B0J',
    'B0K',
    'B0L',
    'B0M',
    'B0N',
    'B0P',
    'B0R',
    'B0S',
    'B0T',
    'B0V',
    'B0W',
    'B1A',
    'B1B',
    'B1C',
    'B1E',
    'B1G',
    'B1H',
    'B1J',
    'B1K',
    'B1L',
    'B1M',
    'B1N',
    'B1P',
    'B1R',
    'B1S',
    'B1T',
    'B1V',
    'B1W',
    'B1X',
    'B1Y',
    'B2A',
    'B2C',
    'B2E',
    'B2G',
    'B2H',
    'B2J',
    'B2N',
    'B2R',
    'B2S',
    'B2T',
    'B2V',
    'B2W',
    'B2X',
    'B2Y',
    'B2Z',
    'B3A',
    'B3B',
    'B3E',
    'B3G',
    'B3H',
    'B3J',
    'B3K',
    'B3L',
    'B3M',
    'B3N',
    'B3P',
    'B3R',
    'B3S',
    'B3T',
    'B3V',
    'B3Z',
    'B4A',
    'B4B',
    'B4C',
    'B4E',
    'B4G',
    'B4H',
    'B4N',
    'B4P',
    'B4R',
    'B4V',
    'B5A',
    'B9A',
  ],
  NT: ['X0E', 'X0G', 'X1A'],
  NU: ['X0A', 'X0B', 'X0C'],
  ON: [
    'K0A',
    'K0B',
    'K0E',
    'K0G',
    'K0J',
    'K0L',
    'K0M',
    'K1A',
    'K1B',
    'K1C',
    'K1E',
    'K1G',
    'K1H',
    'K1J',
    'K1K',
    'K1L',
    'K1M',
    'K1N',
    'K1P',
    'K1R',
    'K1S',
    'K1T',
    'K1V',
    'K1W',
    'K1X',
    'K1Y',
    'K1Z',
    'K2A',
    'K2B',
    'K2C',
    'K2E',
    'K2G',
    'K2H',
    'K2J',
    'K2K',
    'K2L',
    'K2M',
    'K2P',
    'K2R',
    'K2S',
    'K2T',
    'K2V',
    'K2W',
    'K4A',
    'K4B',
    'K4C',
    'K4K',
    'K4M',
    'K4P',
    'K4R',
    'K6A',
    'K6H',
    'K6J',
    'K6K',
    'K6T',
    'K6V',
    'K7A',
    'K7C',
    'K7G',
    'K7H',
    'K7K',
    'K7L',
    'K7M',
    'K7N',
    'K7P',
    'K7R',
    'K7S',
    'K7V',
    'K8A',
    'K8B',
    'K8H',
    'K8N',
    'K8P',
    'K8R',
    'K8V',
    'K9A',
    'K9H',
    'K9J',
    'K9K',
    'K9L',
    'K9V',
    'L0A',
    'L0B',
    'L0C',
    'L0E',
    'L0G',
    'L0H',
    'L0J',
    'L0K',
    'L0L',
    'L0M',
    'L0N',
    'L0P',
    'L0R',
    'L0S',
    'L1A',
    'L1B',
    'L1C',
    'L1E',
    'L1G',
    'L1H',
    'L1J',
    'L1K',
    'L1L',
    'L1M',
    'L1N',
    'L1P',
    'L1R',
    'L1S',
    'L1T',
    'L1V',
    'L1W',
    'L1X',
    'L1Y',
    'L1Z',
    'L2A',
    'L2E',
    'L2G',
    'L2H',
    'L2J',
    'L2M',
    'L2N',
    'L2P',
    'L2R',
    'L2S',
    'L2T',
    'L2V',
    'L2W',
    'L3B',
    'L3C',
    'L3K',
    'L3M',
    'L3P',
    'L3R',
    'L3S',
    'L3T',
    'L3V',
    'L3X',
    'L3Y',
    'L3Z',
    'L4A',
    'L4B',
    'L4C',
    'L4E',
    'L4G',
    'L4H',
    'L4J',
    'L4K',
    'L4L',
    'L4M',
    'L4N',
    'L4P',
    'L4R',
    'L4S',
    'L4T',
    'L4V',
    'L4W',
    'L4X',
    'L4Y',
    'L4Z',
    'L5A',
    'L5B',
    'L5C',
    'L5E',
    'L5G',
    'L5H',
    'L5J',
    'L5K',
    'L5L',
    'L5M',
    'L5N',
    'L5P',
    'L5R',
    'L5S',
    'L5T',
    'L5V',
    'L5W',
    'L6A',
    'L6B',
    'L6C',
    'L6E',
    'L6G',
    'L6H',
    'L6J',
    'L6K',
    'L6L',
    'L6M',
    'L6P',
    'L6R',
    'L6S',
    'L6T',
    'L6V',
    'L6W',
    'L6X',
    'L6Y',
    'L6Z',
    'L7A',
    'L7B',
    'L7C',
    'L7E',
    'L7G',
    'L7J',
    'L7L',
    'L7M',
    'L7N',
    'L7P',
    'L7R',
    'L7S',
    'L7T',
    'L8E',
    'L8G',
    'L8H',
    'L8J',
    'L8K',
    'L8L',
    'L8M',
    'L8N',
    'L8P',
    'L8R',
    'L8S',
    'L8T',
    'L8V',
    'L8W',
    'L9A',
    'L9B',
    'L9C',
    'L9G',
    'L9H',
    'L9K',
    'L9L',
    'L9M',
    'L9N',
    'L9P',
    'L9R',
    'L9S',
    'L9T',
    'L9V',
    'L9W',
    'L9Y',
    'L9Z',
    'M1B',
    'M1C',
    'M1E',
    'M1G',
    'M1H',
    'M1J',
    'M1K',
    'M1L',
    'M1M',
    'M1N',
    'M1P',
    'M1R',
    'M1S',
    'M1T',
    'M1V',
    'M1W',
    'M1X',
    'M2H',
    'M2J',
    'M2K',
    'M2L',
    'M2M',
    'M2N',
    'M2P',
    'M2R',
    'M3A',
    'M3B',
    'M3C',
    'M3H',
    'M3J',
    'M3K',
    'M3L',
    'M3M',
    'M3N',
    'M4A',
    'M4B',
    'M4C',
    'M4E',
    'M4G',
    'M4H',
    'M4J',
    'M4K',
    'M4L',
    'M4M',
    'M4N',
    'M4P',
    'M4R',
    'M4S',
    'M4T',
    'M4V',
    'M4W',
    'M4X',
    'M4Y',
    'M5A',
    'M5B',
    'M5C',
    'M5E',
    'M5G',
    'M5H',
    'M5J',
    'M5K',
    'M5L',
    'M5M',
    'M5N',
    'M5P',
    'M5R',
    'M5S',
    'M5T',
    'M5V',
    'M5W',
    'M5X',
    'M6A',
    'M6B',
    'M6C',
    'M6E',
    'M6G',
    'M6H',
    'M6J',
    'M6K',
    'M6L',
    'M6M',
    'M6N',
    'M6P',
    'M6R',
    'M6S',
    'M7A',
    'M7Y',
    'M8V',
    'M8W',
    'M8X',
    'M8Y',
    'M8Z',
    'M9A',
    'M9B',
    'M9C',
    'M9L',
    'M9M',
    'M9N',
    'M9P',
    'M9R',
    'M9V',
    'M9W',
    'N0A',
    'N0B',
    'N0C',
    'N0E',
    'N0G',
    'N0H',
    'N0J',
    'N0K',
    'N0L',
    'N0M',
    'N0N',
    'N0P',
    'N0R',
    'N1A',
    'N1C',
    'N1E',
    'N1G',
    'N1H',
    'N1K',
    'N1L',
    'N1M',
    'N1P',
    'N1R',
    'N1S',
    'N1T',
    'N2A',
    'N2B',
    'N2C',
    'N2E',
    'N2G',
    'N2H',
    'N2J',
    'N2K',
    'N2L',
    'N2M',
    'N2N',
    'N2P',
    'N2R',
    'N2T',
    'N2V',
    'N2Z',
    'N3A',
    'N3B',
    'N3C',
    'N3E',
    'N3H',
    'N3L',
    'N3P',
    'N3R',
    'N3S',
    'N3T',
    'N3V',
    'N3W',
    'N3Y',
    'N4B',
    'N4G',
    'N4K',
    'N4L',
    'N4N',
    'N4S',
    'N4T',
    'N4V',
    'N4W',
    'N4X',
    'N4Z',
    'N5A',
    'N5C',
    'N5H',
    'N5L',
    'N5P',
    'N5R',
    'N5V',
    'N5W',
    'N5X',
    'N5Y',
    'N5Z',
    'N6A',
    'N6B',
    'N6C',
    'N6E',
    'N6G',
    'N6H',
    'N6J',
    'N6K',
    'N6L',
    'N6M',
    'N6N',
    'N6P',
    'N7A',
    'N7G',
    'N7L',
    'N7M',
    'N7S',
    'N7T',
    'N7V',
    'N7W',
    'N7X',
    'N8A',
    'N8H',
    'N8M',
    'N8N',
    'N8P',
    'N8R',
    'N8S',
    'N8T',
    'N8V',
    'N8W',
    'N8X',
    'N8Y',
    'N9A',
    'N9B',
    'N9C',
    'N9E',
    'N9G',
    'N9H',
    'N9J',
    'N9K',
    'N9V',
    'N9Y',
    'P0A',
    'P0B',
    'P0C',
    'P0E',
    'P0G',
    'P0H',
    'P0J',
    'P0K',
    'P0L',
    'P0N',
    'P0P',
    'P0R',
    'P0S',
    'P0T',
    'P0V',
    'P0W',
    'P0X',
    'P0Y',
    'P1A',
    'P1B',
    'P1C',
    'P1H',
    'P1L',
    'P1P',
    'P2A',
    'P2B',
    'P2N',
    'P3A',
    'P3B',
    'P3C',
    'P3E',
    'P3G',
    'P3L',
    'P3N',
    'P3P',
    'P3Y',
    'P4N',
    'P4P',
    'P4R',
    'P5A',
    'P5E',
    'P5N',
    'P6A',
    'P6B',
    'P6C',
    'P7A',
    'P7B',
    'P7C',
    'P7E',
    'P7G',
    'P7J',
    'P7K',
    'P8N',
    'P8T',
    'P9A',
    'P9N',
  ],
  'Dundas and Glengarry United Counties': ['K0C'],
  'Addington County': ['K0H'],
  'East Northumberland County & Prince Edward County': ['K0K'],
  'Sudbury District and Greater Sudbury': ['P0M'],
  PE: ['C0A', 'C0B', 'C1A', 'C1B', 'C1C', 'C1E', 'C1N'],
  QC: [
    'G0A',
    'G0B',
    'G0C',
    'G0E',
    'G0G',
    'G0H',
    'G0J',
    'G0K',
    'G0L',
    'G0M',
    'G0N',
    'G0P',
    'G0R',
    'G0S',
    'G0T',
    'G0V',
    'G0W',
    'G0X',
    'G0Y',
    'G0Z',
    'G1A',
    'G1B',
    'G1C',
    'G1E',
    'G1G',
    'G1H',
    'G1J',
    'G1K',
    'G1L',
    'G1M',
    'G1N',
    'G1P',
    'G1R',
    'G1S',
    'G1T',
    'G1V',
    'G1W',
    'G1X',
    'G1Y',
    'G2A',
    'G2B',
    'G2C',
    'G2E',
    'G2G',
    'G2J',
    'G2K',
    'G2L',
    'G2M',
    'G2N',
    'G3A',
    'G3E',
    'G3G',
    'G3H',
    'G3J',
    'G3K',
    'G3L',
    'G3M',
    'G3Z',
    'G4A',
    'G4R',
    'G4S',
    'G4T',
    'G4V',
    'G4W',
    'G4X',
    'G4Z',
    'G5A',
    'G5B',
    'G5C',
    'G5H',
    'G5J',
    'G5L',
    'G5M',
    'G5N',
    'G5R',
    'G5T',
    'G5V',
    'G5X',
    'G5Y',
    'G5Z',
    'G6A',
    'G6B',
    'G6C',
    'G6E',
    'G6G',
    'G6H',
    'G6J',
    'G6K',
    'G6L',
    'G6P',
    'G6R',
    'G6S',
    'G6T',
    'G6V',
    'G6W',
    'G6X',
    'G6Z',
    'G7A',
    'G7B',
    'G7G',
    'G7H',
    'G7J',
    'G7K',
    'G7N',
    'G7P',
    'G7S',
    'G7T',
    'G7X',
    'G7Y',
    'G7Z',
    'G8A',
    'G8B',
    'G8C',
    'G8E',
    'G8G',
    'G8H',
    'G8J',
    'G8K',
    'G8L',
    'G8M',
    'G8N',
    'G8P',
    'G8T',
    'G8V',
    'G8W',
    'G8Y',
    'G8Z',
    'G9A',
    'G9B',
    'G9C',
    'G9H',
    'G9N',
    'G9P',
    'G9R',
    'G9T',
    'G9X',
    'H0H',
    'H0M',
    'H1A',
    'H1B',
    'H1C',
    'H1E',
    'H1G',
    'H1H',
    'H1J',
    'H1K',
    'H1L',
    'H1M',
    'H1N',
    'H1P',
    'H1R',
    'H1S',
    'H1T',
    'H1V',
    'H1W',
    'H1X',
    'H1Y',
    'H1Z',
    'H2A',
    'H2B',
    'H2C',
    'H2E',
    'H2G',
    'H2H',
    'H2J',
    'H2K',
    'H2L',
    'H2M',
    'H2N',
    'H2P',
    'H2R',
    'H2S',
    'H2T',
    'H2V',
    'H2W',
    'H2X',
    'H2Y',
    'H2Z',
    'H3A',
    'H3B',
    'H3C',
    'H3E',
    'H3G',
    'H3H',
    'H3J',
    'H3K',
    'H3L',
    'H3M',
    'H3N',
    'H3P',
    'H3R',
    'H3S',
    'H3T',
    'H3V',
    'H3W',
    'H3X',
    'H3Y',
    'H3Z',
    'H4A',
    'H4B',
    'H4C',
    'H4E',
    'H4G',
    'H4H',
    'H4J',
    'H4K',
    'H4L',
    'H4M',
    'H4N',
    'H4P',
    'H4R',
    'H4S',
    'H4T',
    'H4V',
    'H4W',
    'H4X',
    'H4Y',
    'H4Z',
    'H5A',
    'H5B',
    'H7A',
    'H7B',
    'H7C',
    'H7E',
    'H7G',
    'H7H',
    'H7J',
    'H7K',
    'H7L',
    'H7M',
    'H7N',
    'H7P',
    'H7R',
    'H7S',
    'H7T',
    'H7V',
    'H7W',
    'H7X',
    'H7Y',
    'H8N',
    'H8P',
    'H8R',
    'H8S',
    'H8T',
    'H8Y',
    'H8Z',
    'H9A',
    'H9B',
    'H9C',
    'H9E',
    'H9G',
    'H9H',
    'H9J',
    'H9K',
    'H9P',
    'H9R',
    'H9S',
    'H9W',
    'H9X',
    'J0A',
    'J0B',
    'J0C',
    'J0E',
    'J0G',
    'J0H',
    'J0J',
    'J0K',
    'J0L',
    'J0M',
    'J0N',
    'J0P',
    'J0R',
    'J0S',
    'J0T',
    'J0V',
    'J0W',
    'J0X',
    'J0Y',
    'J0Z',
    'J1A',
    'J1E',
    'J1G',
    'J1H',
    'J1J',
    'J1K',
    'J1L',
    'J1M',
    'J1N',
    'J1S',
    'J1T',
    'J1X',
    'J1Z',
    'J2A',
    'J2B',
    'J2C',
    'J2E',
    'J2G',
    'J2H',
    'J2J',
    'J2K',
    'J2L',
    'J2N',
    'J2R',
    'J2S',
    'J2T',
    'J2W',
    'J2X',
    'J2Y',
    'J3A',
    'J3B',
    'J3E',
    'J3G',
    'J3H',
    'J3L',
    'J3M',
    'J3N',
    'J3P',
    'J3R',
    'J3T',
    'J3V',
    'J3X',
    'J3Y',
    'J3Z',
    'J4B',
    'J4G',
    'J4H',
    'J4J',
    'J4K',
    'J4L',
    'J4M',
    'J4N',
    'J4P',
    'J4R',
    'J4S',
    'J4T',
    'J4V',
    'J4W',
    'J4X',
    'J4Y',
    'J4Z',
    'J5A',
    'J5B',
    'J5J',
    'J5K',
    'J5L',
    'J5M',
    'J5R',
    'J5T',
    'J5V',
    'J5W',
    'J5X',
    'J5Y',
    'J5Z',
    'J6A',
    'J6E',
    'J6J',
    'J6K',
    'J6N',
    'J6R',
    'J6S',
    'J6T',
    'J6V',
    'J6W',
    'J6X',
    'J6Y',
    'J6Z',
    'J7A',
    'J7B',
    'J7C',
    'J7E',
    'J7G',
    'J7H',
    'J7J',
    'J7K',
    'J7L',
    'J7M',
    'J7N',
    'J7P',
    'J7R',
    'J7T',
    'J7V',
    'J7X',
    'J7Y',
    'J7Z',
    'J8A',
    'J8B',
    'J8C',
    'J8E',
    'J8G',
    'J8H',
    'J8L',
    'J8M',
    'J8N',
    'J8P',
    'J8R',
    'J8T',
    'J8V',
    'J8X',
    'J8Y',
    'J8Z',
    'J9A',
    'J9B',
    'J9E',
    'J9H',
    'J9J',
    'J9L',
    'J9P',
    'J9T',
    'J9V',
    'J9X',
    'J9Y',
    'J9Z',
  ],
  SK: [
    'S0A',
    'S0C',
    'S0E',
    'S0G',
    'S0H',
    'S0J',
    'S0K',
    'S0L',
    'S0M',
    'S0N',
    'S0P',
    'S2V',
    'S3N',
    'S4A',
    'S4H',
    'S4L',
    'S4N',
    'S4P',
    'S4R',
    'S4S',
    'S4T',
    'S4V',
    'S4W',
    'S4X',
    'S4Y',
    'S4Z',
    'S6H',
    'S6J',
    'S6K',
    'S6V',
    'S6W',
    'S6X',
    'S7H',
    'S7J',
    'S7K',
    'S7L',
    'S7M',
    'S7N',
    'S7P',
    'S7R',
    'S7S',
    'S7T',
    'S7V',
    'S9A',
    'S9H',
    'S9V',
    'S9X',
  ],
  YT: ['Y0A', 'Y0B', 'Y1A'],
};

export type CACode = {
  zip: string;
  latitude: string;
  longitude: string;
  city: string;
  state: string;
  country: string;
};

export function lookup_ca_codes(zip: string): CACode {
  return codes[zip];
}
