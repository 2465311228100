import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from '../../core/services/user.service';
import { AppointmentTimeEntry } from '../../core/domain/appointment-time-entry';
import { AppointmentTimeIndex } from '../../core/domain/appointment-time-index';

@Component({
  selector: 'app-material-date-time',
  templateUrl: './material-date-time.component.html',
  styleUrls: ['./material-date-time.component.scss'],
})
export class MaterialDateTimeComponent implements OnInit {
  currentView = 'DAY';

  selectedDate = new Date();
  selectedDateSubject: Subject<Date> = new Subject();
  displayDate = new Date();
  dateSubject: Subject<Date> = new Subject();
  today = new Date();

  // day calendar attributes
  daysOfTheWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  firstDayOfMonth: number;

  month: any[];
  daySubject: Subject<any[]> = new Subject();

  // month calendar attributes
  monthCollection: any[];
  monthSubject: Subject<any[]> = new Subject();

  // year calendar attributes
  minYear: number;
  maxYear: number;
  yearCollection: any[];
  yearSubject: Subject<any[]> = new Subject();

  // appointment time
  appointmentDate: Date;
  appointmentDateTime: Date;
  availableTimes: Date[] = [];
  formGroup: FormGroup;
  timezoneOffset = 0;
  initialLoad = true;
  googleMonthEventData = [];
  monthAppointmentDataObs: Observable<AppointmentTimeIndex[]> =
    new Observable();
  monthAppointmentData: AppointmentTimeIndex[] = [];

  // appointment date subject
  appointmentDateTimeSubject: Subject<any> = new Subject();

  freeMember: string[] = [];
  leadTimeHours: number;
  monthLoader = false;
  timezoneLabel = '';

  private nextActiveDate: boolean = true;

  constructor(
    public bsModalRef: BsModalRef,
    public userService: UserService,
  ) {
    this.daySubject.subscribe((day) => {
      this.month = day;
    });

    // this can get general or specific widget data
    const data = this.userService.getWidget();

    this.leadTimeHours = data.appointmentLeadTime;

    this.yearSubject.subscribe((year) => {
      this.yearCollection = year;
    });

    this.monthSubject.subscribe((month) => {
      this.monthCollection = month;
    });

    this.dateSubject.subscribe((date) => {
      this.displayDate = new Date(date);
    });

    this.selectedDateSubject.subscribe((date) => {
      if (date == null) {
        return;
      }

      const sameMonth = date.getMonth() === this.selectedDate.getMonth();

      this.selectedDate = date;
      this.initializeDayCalendar();

      if (
        sameMonth &&
        this.monthAppointmentData != null &&
        this.monthAppointmentData.length > 0
      ) {
        if (this.nextActiveDate) {
          this.gotoNextActiveDate();
        } else {
          this.initializeDate();
        }
      } else {
        if (this.userService.useTeamAppointmentIndex === true) {
          this.monthAppointmentDataObs =
            this.userService.getTeamTimeSlotsForMonthByDate(date);
        } else {
          this.monthAppointmentDataObs =
            this.userService.getTimeSlotsForMonthByDate(date);
        }

        this.monthAppointmentDataObs.subscribe(async (appData) => {
          this.monthAppointmentData = appData;

          if (this.nextActiveDate) {
            this.gotoNextActiveDate();
          } else {
            this.initializeDate();
          }
        });
      }
    });
  }

  ngOnInit() {
    let inputDate = new Date(
      this.formGroup.controls['appointmentDateTime'].value,
    );

    this.timezoneOffset = this.userService.timezoneOffset;

    if (inputDate.toString() === 'Invalid Date') {
      inputDate = new Date();
    }

    this.selectedDate = inputDate;
    this.displayDate = inputDate;

    this.nextActiveDate = true;
    this.selectedDateSubject.next(inputDate);
  }

  get isToday() {
    return this.selectedDate.toDateString() === this.today.toDateString();
  }

  toggleView(): void {
    if (this.currentView === 'DAY') {
      this.currentView = 'YEAR';
    } else {
      this.currentView = 'DAY';
    }
  }

  selectMonth(date: Date): void {
    this.currentView = 'DAY';
    this.selectedDate.setMonth(date.getMonth());
    this.initializeDayCalendar();
  }

  selectYear(year: number): void {
    this.currentView = 'MONTH';
    this.selectedDate.setFullYear(year);
    this.initializeMonthCalendar(null);
  }

  selectDay(date: number): void {
    this.currentView = 'DAY';

    // if (!this.isActiveDate(date)) { return; }

    const newDate = new Date(this.selectedDate);
    newDate.setDate(date);

    const isValid = this.hasAvailableTimeslots(newDate);

    if (isValid) {
      this.selectedDateSubject.next(newDate);
    }
  }

  previousRange(): void {
    if (this.currentView === 'DAY') {
      this.previousMonth();
    } else if (this.currentView === 'MONTH') {
      this.previousYear();
    } else {
      this.previousYearRange();
    }
  }

  nextRange(): void {
    if (this.currentView === 'DAY') {
      this.nextMonth();
    } else if (this.currentView === 'MONTH') {
      this.nextYear();
    } else {
      this.nextYearRange();
    }
  }

  previousYear(): void {
    this.selectedDate.setFullYear(this.selectedDate.getFullYear() - 1);

    this.initializeMonthCalendar(null);
  }

  previousYearRange(): void {
    this.selectedDate.setFullYear(this.selectedDate.getFullYear() - 24);

    this.initializeYearCalendar(null);
  }

  nextYear(): void {
    this.selectedDate.setFullYear(this.selectedDate.getFullYear() + 1);

    this.initializeMonthCalendar(null);
  }

  nextYearRange(): void {
    this.selectedDate.setFullYear(this.selectedDate.getFullYear() + 24);

    this.initializeYearCalendar(null);
  }

  previousMonth() {
    this.monthAppointmentData = [];
    let newDate: Date;

    if (this.selectedDate.getMonth() === 0) {
      newDate = new Date(this.selectedDate.getFullYear() - 1, 11, 1);
    } else {
      newDate = new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth() - 1,
        1,
      );
    }

    this.nextActiveDate = true;
    this.selectedDateSubject.next(newDate);
  }

  nextMonth() {
    this.monthAppointmentData = [];
    let newDate: Date;

    if (this.selectedDate.getMonth() === 11) {
      newDate = new Date(this.selectedDate.getFullYear() + 1, 0, 1);
    } else {
      newDate = new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth() + 1,
        1,
      );
    }

    this.nextActiveDate = true;
    this.selectedDateSubject.next(newDate);
  }

  private gotoNextActiveDate() {
    if (!this.monthAppointmentData || this.monthAppointmentData.length === 0) {
      return;
    }

    const today = new Date();
    const dayArray = Array.from(Array(32).keys());
    dayArray.shift();
    if (this.selectedDate.getDate() > 1) {
      dayArray.splice(0, this.selectedDate.getDate() - 1);
    }

    for (const day of dayArray) {
      const tempDate = new Date(
        this.selectedDate.getFullYear(),
        this.selectedDate.getMonth(),
        day,
      );

      if (tempDate instanceof Date === false) {
        continue;
      } else if (this.isActiveDate(day) === true) {
        this.nextActiveDate = false;
        this.selectedDate.setDate(day);
        this.selectedDateSubject.next(this.selectedDate);
        break;
      } else {
        tempDate.setDate(tempDate.getDate() + 1);
        if (tempDate.getDate() === 1) {
          this.selectedDateSubject.next(tempDate);
        }
      }
    }
  }

  private initializeMonthCalendar(date: Date | null): void {
    if (date === null) {
      date = this.selectedDate;
    }

    // reset chosen time
    if (!this.initialLoad) {
      this.formGroup.controls['appointmentTime'].setValue(null);
    }

    this.dateSubject.next(date);

    const monthArray: Date[] = Array(12)
      .fill(new Date())
      .map((x, i) => new Date(x.getFullYear(), i, 1));

    // get the rows
    const monthCollection = this.chunk(monthArray, 4);
    this.monthSubject.next(monthCollection);
  }

  private initializeYearCalendar(date: Date | null): void {
    if (date === null) {
      date = this.selectedDate;
    }

    // reset chosen time
    if (!this.initialLoad) {
      this.formGroup.controls['appointmentTime'].setValue(null);
    }

    this.dateSubject.next(date);

    const year: number = date.getFullYear();

    const currentYear: number = date.getFullYear();
    let startingYear: number = currentYear;

    // find nearest 4
    while (startingYear % 4 !== 0) {
      startingYear--;
    }

    this.minYear = startingYear;
    this.maxYear = startingYear + 24;
    const yearArray: number[] = Array(24)
      .fill(0)
      .map((x, i) => i + startingYear);

    // get the rows
    const yearCollection = this.chunk(yearArray, 4);
    this.yearSubject.next(yearCollection);
  }

  private initializeDayCalendar(date?: Date) {
    if (date == null) {
      date = this.selectedDate;
    }

    // reset chosen time
    if (!this.initialLoad) {
      this.formGroup.controls['appointmentTime'].setValue(null);
    }

    this.dateSubject.next(date);

    const year: number = date.getFullYear();
    const month: number = date.getMonth() + 1;

    // get number of days in the month
    const numMonthsDate = new Date(year, month, 0);
    const numDays: number = numMonthsDate.getDate();
    const dayArray: number[] = Array(numDays)
      .fill(0)
      .map((x, i) => i + 1);

    // get the first day of the month
    const firstDayOfMonthDate = new Date(year, month - 1, 1);
    this.firstDayOfMonth = firstDayOfMonthDate.getDay() + 1;

    // zero pad the days of month array according to when the first day is
    for (let i = 0; i < this.firstDayOfMonth - 1; i++) {
      dayArray.unshift(0);
    }

    // get the rows
    const monthCollection = this.chunk(dayArray, 7);
    this.daySubject.next(monthCollection);
  }

  private chunk(array, size): number[] {
    const chunked_arr: any = [];
    for (let i = 0; i < array.length; i++) {
      const last = chunked_arr[chunked_arr.length - 1];
      if (!last || last.length === size) {
        chunked_arr.push([array[i]]);
      } else {
        last.push(array[i]);
      }
    }
    return chunked_arr;
  }

  initializeDate() {
    const dateTime: Date = this.selectedDate;

    this.appointmentDate = dateTime;

    const daySlots = this.monthAppointmentData.filter(
      (item) => item.date === dateTime.getDate(),
    );

    if (!daySlots || daySlots.length < 1) {
      this.availableTimes = [];
      return false;
    }

    this.availableTimes = daySlots[0].times
      .map((time: string) => {
        const timeSplit = time.split(':');
        const timeDate = new Date(dateTime);
        timeDate.setHours(
          parseInt(timeSplit[0], 10),
          parseInt(timeSplit[1], 10),
          0,
          0,
        );

        const leadTimeDate = new Date();
        if (this.leadTimeHours) {
          leadTimeDate.setHours(leadTimeDate.getHours() + this.leadTimeHours);
        }
        // Of this next part is where the timezone craziness begins:
        // If the widget user and the inspector are in different timezones,
        // we will show a label on the calendar that indicates the user (inspector)
        // timezone. We also need to account for DST if needed.
        const timezoneOffset =
          timeDate.getTimezoneOffset() - this.timezoneOffset;
        const dstOffset =
          timeDate.getTimezoneOffset() - new Date().getTimezoneOffset();
        const utcOffset = timezoneOffset - dstOffset;
        if (utcOffset && utcOffset !== 0) {
          const hourOffset = ((this.timezoneOffset + dstOffset) / 60) * -1;
          const tzLabel =
            hourOffset < 0
              ? hourOffset.toString()
              : '+' + hourOffset.toString();
          this.timezoneLabel = ' (UTC' + tzLabel + ')';
          leadTimeDate.setMinutes(leadTimeDate.getMinutes() + utcOffset);
        } else {
          this.timezoneLabel = '';
        }

        // this.selectedDate = timeDate;
        this.appointmentDate = timeDate;

        if (leadTimeDate >= timeDate) {
          timeDate['delete'] = true;
        }
        return timeDate;
      })
      .filter((atime) => !!atime['delete'] === false);

    if (this.availableTimes.length === 0) {
      this.monthAppointmentData = this.monthAppointmentData.filter(
        (item) => item.date !== dateTime.getDate(),
      );
      this.nextActiveDate = true;
      const nextDay = new Date(this.selectedDate);
      nextDay.setDate(nextDay.getDate() + 1);
      this.selectedDateSubject.next(nextDay);
    }

    this.initialLoad = false;

    return;
  }

  private getUTCTime(entry: AppointmentTimeEntry): {
    day: string;
    time: string;
  } {
    const utcDate = new Date();
    const dayOffset = parseInt(entry.day, 10) - utcDate.getDay();
    const hour = parseInt(entry.time.split(':')[0], 10);
    const minute = parseInt(entry.time.split(':')[1], 10);
    utcDate.setHours(hour);
    utcDate.setMinutes(minute);
    utcDate.setMinutes(utcDate.getUTCMinutes() + utcDate.getTimezoneOffset());
    const day = utcDate.getDay() + dayOffset;
    return {
      day: day.toString(),
      time:
        utcDate.getHours().toString().padStart(2, '0') +
        ':' +
        utcDate.getMinutes().toString().padStart(2, '0'),
    };
  }

  isActiveDate(day: number): boolean {
    const tempDate = new Date(
      this.selectedDate.getFullYear(),
      this.selectedDate.getMonth(),
      day,
    );
    return this.hasAvailableTimeslots(tempDate);
  }

  isChosenDay(day: number): boolean {
    const tempDate = new Date(
      this.selectedDate.getFullYear(),
      this.selectedDate.getMonth(),
      day,
    );
    return (
      tempDate.getFullYear() === this.selectedDate.getFullYear() &&
      tempDate.getMonth() === this.selectedDate.getMonth() &&
      tempDate.getDate() === this.selectedDate.getDate()
    );
  }

  hasAvailableTimeslots = (date: Date): boolean => {
    // TODO: add code here for determining from team global data or local data

    const leadTimeDate = new Date();

    if (this.leadTimeHours) {
      leadTimeDate.setHours(leadTimeDate.getHours() + this.leadTimeHours);
    }

    leadTimeDate.setHours(0, 0, 0, 0);

    if (date < leadTimeDate) {
      return false;
    }

    const timeSlots = this.monthAppointmentData.filter((day) => {
      return day.date === date.getDate();
    });

    if (!timeSlots || timeSlots.length < 1) {
      return false;
    }

    return true;
  };

  timeChange(timeChangeEvent: any): void {
    this.appointmentDateTime = timeChangeEvent.value;
    this.appointmentDate = timeChangeEvent.value;
  }

  fieldIsRequired(fieldName: string, mustCheckDirty: boolean = true): boolean {
    if (mustCheckDirty) {
      return (
        this.formGroup.controls[fieldName].dirty &&
        this.formGroup.controls[fieldName].hasError('required')
      );
    } else {
      return this.formGroup.controls[fieldName].hasError('required');
    }
  }

  formatTime(time: string): Date {
    const timeSlot: Date = new Date();
    timeSlot.setHours(
      parseInt(time.split(':')[0], 10),
      parseInt(time.split(':')[1], 10),
    );

    return timeSlot;
  }

  closeModal() {
    this.appointmentDateTimeSubject.next(this.appointmentDate);
    this.bsModalRef.hide();
  }

  getShortDate(time): string {
    if (!time) {
      return '';
    }
    const date: Date = time;
    if (date) {
      const dateNow = new Date();
      if (date.toLocaleDateString() === dateNow.toLocaleDateString()) {
        return date.toLocaleTimeString(navigator.language, {
          hour: '2-digit',
          minute: '2-digit',
        });
      } else if (date.getFullYear() === dateNow.getFullYear()) {
        return date.toLocaleDateString(navigator.language, {
          month: 'short',
          day: 'numeric',
        });
      } else {
        return date.toLocaleDateString(navigator.language, {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
      }
    }
    return '';
  }

  timeStampToISO(time) {
    if (!time) {
      return '';
    }
    const dateTime: Date = time;
    dateTime.setMinutes(
      dateTime.getUTCMinutes() - dateTime.getTimezoneOffset(),
    );
    return dateTime;
  }

  cancelModal() {
    if (!this.formGroup.controls['appointmentDateTime'].valid) {
      this.formGroup.controls['appointmentTime'].setValue(null);
    } else {
      const date = new Date(
        this.formGroup.controls['appointmentDateTime'].value,
      );
      const time =
        date.getHours() + ':' + date.getMinutes().toString().padStart(2, '0');
      this.formGroup.controls['appointmentTime'].setValue(time);
    }

    this.bsModalRef.hide();
  }

  newChangeEvent(freeMember) {
    this.freeMember = freeMember.id;
  }
}
