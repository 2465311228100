import {BehaviorSubject, Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {ItbRequest} from "../domain/itb-request";

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  private triggerEventMsg = new BehaviorSubject(false);
  private clearForm = new BehaviorSubject(false);
  private request: ItbRequest;
  clearForm$: Observable<any>;
  constructor() {
    this.clearForm$ = this.clearForm.asObservable();
  }

  public setClearForm(value){
    this.clearForm.next(value);
  }
  public getRequest(): ItbRequest {
    return this.request;
  }

  public setRequest(request: ItbRequest): void {
    this.request = request;
  }

  triggerEvent(val: boolean) {
    this.triggerEventMsg.next(val);
  }

  getEvent(){
    return this.triggerEventMsg.asObservable();
  }
}
